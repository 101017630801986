<template>
  <div class="navigator p-0 m-0">
    <NavigatorItem :element="this.store.rootElement"/>

    <draggable
        :list="orderedElements"
        item-key="pid"
        @end="dragEnd"
        @drag="drag"
        ghost-class="ghost"
        :disabled="isDraggingDisabled"
    >
      <template #item="{element, index}">
        <NavigatorItem :element="element" />
      </template>
    </draggable>
  </div>
</template>

<script lang="ts">
import draggable from 'vuedraggable';
import NavigatorItem from './NavigatorItem.vue';
import { useCounterStore } from '../stores/counter.js';
import {defineComponent, watch, watchEffect} from 'vue';
import { PageElement, ElementBeneath, VueDraggableEvent, DragDetails} from "../interfaces";
import { updateActiveChildOrder } from '../utils.js';


export default defineComponent({
  name: 'Navigator',
  components: {
    NavigatorItem,
    draggable
  },
  data() {
    return {
      elementBeneath: {
        pid: null,
        order: 0,
        isBecomingParent: false,
        parentPid: null,
        hoverTime: 0
      } as ElementBeneath
    };
  },
  computed: {
    store() {
      return useCounterStore();
    },
    isDraggingDisabled(): boolean {
      return this.store.selectedElement?.component_name === "PageFormState"
    },
    orderedElements(): PageElement[] {
      return this.store.orderedElements;
    },
  },
  mounted() {
    watch(this.store.selectedElementPid, async (newPid, oldPid) => {
      /*
      We scroll to the selected element's navigation item when the selected element changes.
       */
      const newVal = this.store.selectedElementPid;
      const element = document.querySelector(`[data-page-element-pid="${newVal}"]`);
      if (element) {
        element.scrollIntoView({block: 'center'});
      }
    });
  },
  methods: {
    drag(event: DragEvent) {
      /*
      We fire this method whenever the user starts dragging an element.
       */
      this.recordDragHoverState((event as DragEvent).clientY);

      const draggedElement = this.store.selectedElement
      if (draggedElement.hasNest) {
        draggedElement.isNestOpen = false;
      }
    },
    recordDragHoverState(mouseY: number) {
      /*
      We record information about the element that the dragged element hovers over.
      Among other things, this allows us to determine whether to make the dragged element a child of the hovered element.
      */
      const selectedElementIndex = this.getSelectedElementIndex();

      for (let i = 0; i < this.orderedElements.length; i++) {
        if (i === selectedElementIndex) continue; // Ignore the selected element.

        const potentialElementBeneath = this.orderedElements[i];
        const potentialElementBeneathRect = this.getBoundingRect(potentialElementBeneath.pid);

        if (this.isHoveringOverElement(mouseY, potentialElementBeneathRect)) {
          this.setElementBeneath(potentialElementBeneath);
          break;
        }
      }
    },

    getSelectedElementIndex(): number {
      const selectedElement = this.orderedElements.find(el => el.pid === this.store.selectedElementPid);
      return this.orderedElements.indexOf(selectedElement);
    },

    getBoundingRect(elementId: string): DOMRect {
      const domElement = document.querySelector(`[data-page-element-pid=${elementId}`);
      return domElement.getBoundingClientRect();
    },

    isHoveringOverElement(mouseY: number, potentialElementRect: DOMRect): boolean {
      const { top, bottom } = potentialElementRect;
      return mouseY >= top && mouseY <= bottom;
    },

    setElementBeneath(currentElement: PageElement) {
      const { pid, parentPid, component_name, order } = currentElement;
      const isBecomingParent = component_name === 'PageDiv' && this.elementBeneath.hoverTime > 60;

      this.elementBeneath = {
        isBecomingParent,
        order,
        pid,
        parentPid,
        component_name,
        hoverTime: this.getCumulativeHoverTime(currentElement)
      };
    },

    getCumulativeHoverTime(currentElement: PageElement): number {
      const isSameElementBeneath = () => this.elementBeneath.pid === currentElement.pid;
      return isSameElementBeneath() ? this.elementBeneath.hoverTime + 1 : 0;
    },

    dragEnd(event: VueDraggableEvent) {
      /*
      This event is fired after the user releases the drag. At that moment, we reorder the element tree.
       */
      if (this.isDraggingDisabled) return;

      const dragDetails: DragDetails = this.getDragDetails(event);
      const draggedElement = this.findElement(dragDetails.elementId);


      this.getReorderedElements(dragDetails, this.store.activeElements);

      updateActiveChildOrder(this.store, dragDetails.newParentPid);
      updateActiveChildOrder(this.store, dragDetails.oldParentPid);

      this.updateElementDetails(dragDetails, draggedElement);
      this.resetElementBeneath();
    },

    getReorderedElements(dragDetails: DragDetails, storeElements: PageElement[]): PageElement[] {
      const hasSameParent = dragDetails.oldParentPid === dragDetails.newParentPid;

      if (hasSameParent) {
        return this.moveWithinSameParent(storeElements, dragDetails);
      } else {
        return this.moveToDifferentParent(storeElements, dragDetails)
      }
    },

    moveWithinSameParent(elements: PageElement[], dragDetails: DragDetails): PageElement[] {
      const element = elements.find(e => e.pid === dragDetails.elementId);
      const siblings = elements.filter(e => e.parentPid === element.parentPid);

      // Remove the element from its old position
      elements = elements.filter(e => e.pid !== dragDetails.elementId);

      // Decrease order of elements that came after the moved element
      siblings.filter(e => e.order > element.order).forEach(e => e.order--);

      // Increase order of elements that will come after the moved element
      siblings.filter(e => e.order >= dragDetails.newIndex).forEach(e => e.order++);

      // Set new order and insert the moved element back to elements
      element.order = dragDetails.newIndex;
      elements.push(element);
      return elements
    },


    moveToDifferentParent(elements: PageElement[], dragDetails: DragDetails): PageElement[] {
      const element = elements.find(e => e.pid === dragDetails.elementId);
      const oldSiblings = elements.filter(e => e.parentPid === element.parentPid);
      const newSiblings = elements.filter(e => e.parentPid === dragDetails.newParentPid);

      // Remove the element from its old position
      elements = elements.filter(e => e.pid !== dragDetails.elementId);

      // Decrease order of elements that came after the moved element
      oldSiblings.filter(e => e.order > element.order).forEach(e => e.order--);

      // Increase order of elements that will come after the moved element
      newSiblings.filter(e => e.order >= dragDetails.newIndex).forEach(e => e.order++);

      // Set new parent and order and insert the moved element back to elements
      element.parentPid = dragDetails.newParentPid;
      element.order = dragDetails.newIndex;
      elements.push(element);
      return elements;
    },

    resetElementBeneath() {
      this.elementBeneath = {
        pid: null,
        isBecomingParent: false,
        parentPid: null,
        component_name: '',
        hoverTime: 0
      };
    },

    getDragDetails(event: VueDraggableEvent): DragDetails {
      const newParentPid = this.getNewParentPid(this.store.selectedElement, this.elementBeneath);

      // Detect any the new index by the element beneath the dragged element.
      let newIndex = event.oldIndex
      if (this.elementBeneath.pid) {
        if (this.elementBeneath.pid === newParentPid) {
          newIndex = 0;
        } else {
          newIndex = this.elementBeneath.order;
        }
      }

      return {
        elementId: event.item.dataset.pageElementPid,
        oldParentPid: event.item.dataset.parentPid,
        newParentPid: newParentPid,
        oldIndex: event.oldIndex,
        newIndex: newIndex,
      };
    },

    getNewParentPid(selectedElement: PageElement, elementBeneath: ElementBeneath): string | null {
      /*
      We determine the parent of the dragged element.
      This includes considering whether we are nesting the selected element within a new parent.
      */
      const hasElementBeneath = elementBeneath && elementBeneath.pid;

      if (hasElementBeneath) {
        const isNesting = this.shouldBeNestedUnder(elementBeneath);
        return isNesting ? elementBeneath.pid : elementBeneath.parentPid;
      }
      else {
        return selectedElement.parentPid;
      }
    },

    shouldBeNestedUnder(elementBeneath: ElementBeneath): boolean {
      return elementBeneath.component_name === 'PageDiv' && elementBeneath.isBecomingParent;
    },

    findElement(pid: string): PageElement {
      return this.store.activeElements.find(element => element.pid === pid);
    },

    updateElementDetails(data: DragDetails, element: PageElement) {
      element.parentPid = data.newParentPid;
      element.order = data.newIndex;
      element.depth = this.findNewDepth(data);
      this.updateChildrenDepth(element);
    },

    findNewDepth(data: { newParentPid: string }) {
      const parentEl = this.findElement(data.newParentPid);
      return parentEl.depth + 1;
    },

    updateChildrenDepth(parentElement: PageElement) {
      const children = this.store.activeElements.filter(el => el.parentPid === parentElement.pid);
      for (let child of children) {
        child.depth = parentElement.depth + 1;
        this.updateChildrenDepth(child);
      }
    },
  },

});
</script>

<style scoped>
.navigator {
  height: 100%;
  overflow-y: auto;
  scrollbar-width: none;
  padding: 0;
  margin: 0;
}
.ghost {
  visibility: hidden;
}
</style>
