<template>
  <div class="sidebar">

    <div class="flex my-2">
      <div class="w-full text-center">Pages</div>
      <button @click="this.store.setLeftSidebar({ isShowing: false })">
        <span><IconComponent name="close"/></span>
      </button>
    </div>

    <section>
      <div>
        <div @click="showCreatePage = true">
          Create page
        </div>
        <div v-show="showCreatePage">
          <div>
            <div>Name</div>
            <GenericInputText
                name="page_name"
                placeholder="Name"
                value=""
                @update:value="newPageData.name = $event"
            />
          </div>
          <div>
            <div>Path</div>
            <GenericInputText
                name="page_path"
                placeholder="/path/to/page"
                value=""
                @update:value="newPageData.path = $event"
            />
          </div>
          <button @click="createNewPage()">Create</button>
        </div>
      </div>



      <div class="flex flex-col px-0.5">
        <div v-for="page in projectStore.pages" :key="page.id" class="page" @click="goToPageIfNotEditing(page)">
          <span><IconComponent name="page"/></span>

          <div>
            <div v-show="editingPageId !== page.id" class="flex gap-3">
              <div>{{ page.name }}</div>
              <div>{{ page.path }}</div>
            </div>
            <div v-show="editingPageId === page.id" class="">
              <GenericInputText
                  :name="'edit-page-name-for' + page.id"
                  :value="page.name"
                  class="bg-transparent"
                  placeholder="Name"
                  @update:value="editingPageData.name = $event"
              />
              <GenericInputText
                  :name="'edit-page-path-for' + page.id"
                  :value="page.path"
                  class="bg-transparent"
                  placeholder="Path"
                  @update:value="editingPageData.path = $event"
              />
            </div>
          </div>

          <div @click.stop="startEditing(page)">
            <div v-show="!(editingPageData.pageId === page.id)">
              Edit
            </div>
            <div v-show="editingPageData.pageId === page.id"
                 @click="updateExistingPage(page.id)">
              Save
            </div>

          </div>
        </div>


      </div>

    </section>


  </div>

</template>

<script>
import Navigator from "@/components/Navigator.vue";
import LeftSidebarContextEditor from "@/components/LeftSidebarContextEditor.vue";
import {Icon} from "@iconify/vue";
import {useCounterStore} from "@/stores/counter";
import {useRoute, useRouter} from 'vue-router';
import IconComponent from "@/components/IconComponent.vue";
import {useProjectStore} from "@/stores/project";
import GenericInputText from "@/components/GenericInputText.vue";
import {useToast} from "vue-toastification";
import _ from "lodash";


export default {
  components: {GenericInputText, IconComponent, Icon, Navigator, LeftSidebarContextEditor},
  setup() {
    const route = useRoute();
    const router = useRouter()
    const projectId = route.params.projectId
    const pageId = route.params.pageId
    const store = useCounterStore()
    const projectStore = useProjectStore()
    const toast = useToast()

    return {pageId, projectId, projectStore, store, toast, router};
  },
  data() {
    return {
      showCreatePage: false,
      newPageData: {
        project: this.projectId,
      },
      editingPageId: null,
      editingPageData: {
        project: this.projectId,
        pageId: null,
      }
    }
  },
  methods: {
    async createNewPage() {
      await this.projectStore.createPage(this.projectId, this.newPageData)
      this.showCreatePage = false
    },
    async updateExistingPage(pageId) {
      await this.projectStore.updatePage(this.projectId, pageId, this.editingPageData)
      this.editingPageId = null
      this.editingPageData = {project: this.projectId, pageId: null}  // Reset.

      this.toast.success('Updated page')
    },
    startEditing(page) {
      this.editingPageId = page.id
      this.editingPageData = {
        project: this.projectId,
        pageId: page.id,
      }
    },
    goToPageIfNotEditing(page) {
      if (!page) {
        throw new Error("No page provided")
      }
      const notEditing = _.isNull(this.editingPageId)
      if (notEditing) {
        const path = this.$router.resolve(
            {name: 'designer', params: {projectId: this.projectId, pageId: page.id}}
        )
        location.href = path.href
      }
    },
  }
}
</script>

<style scoped>
.sidebar {
  min-width: 200px;
  font-size: smaller;
}

.page {
  user-select: none;
  min-height: 10px;
  text-align: center;
  cursor: pointer;
  color: white;
  width: 100%;
  display: grid;
  grid-template-columns: .1fr 1fr .2fr;
  gap: 5px;
  padding: 5px;
}

.page:hover {
  background-color: #545454;
  color: white;
  transition: background-color 0.3s ease;
}


</style>