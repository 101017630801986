<template>
  <div>
    <span v-show="!hasAutoUnit">{{ computedNum(this.cssProperty).value }}</span>
    <span class="unit">{{ computedUnit(this.cssProperty).value }}</span>
  </div>
</template>

<script>
import GenericInputRange from "@/components/GenericInputRange.vue";
import GenericInputNumUnit from "@/components/GenericInputNumUnit.vue";
import useSelectedElement from "@/composables/useSelectedElement";
import parseCss from "@/utils/parseCss";
import {computed, ref, toValue} from "vue";
import ControlSpacingPopout from "@/components/ControlSpacingPopout.vue";
import {useCounterStore} from "@/stores/counter";


export default {
  components: {
    ControlSpacingPopout,
    GenericInputNumUnit, GenericInputRange
  },
  props: {
    cssProperty: {
      type: String,
      required: true
    }
  },
  setup() {
    const store = useCounterStore();
    const {selectedElementFinalCssBlock} = useSelectedElement();
    const {getCssNumAndUnit} = parseCss()

    const computedNum = (cssProperty, defaultValue = '0px') => computed(() => {
      return getCssNumAndUnit(store.selectedElementFinalCssBlock[cssProperty] || defaultValue).num;
    });
    const computedUnit = (cssProperty, defaultValue = '0px') => computed(() => {
      return getCssNumAndUnit(store.selectedElementFinalCssBlock[cssProperty] || defaultValue).unit;
    });

    return {
      store,

      selectedElementFinalCssBlock,
      getCssNumAndUnit,
      computedNum, computedUnit
    };
  },
  computed: {
    hasAutoUnit() {
      return this.computedUnit(this.cssProperty).value === 'auto';
    }
  }
}

</script>

<style scoped>
.unit {
  font-size: 9px;
  text-transform: uppercase;
}
</style>