<template>
  <div class="w-full text-[11px] base">
    <header class="pl-2 py-1">
      <slot name="label"></slot>
    </header>
    <div class="content pl-2">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<style scoped>
.base {
  background-color: #333333;
}

.content {
  background-color: rgb(94, 89, 89);
  color: white;
}
</style>