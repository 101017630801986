import { useCounterStore } from "@/stores/counter";
import { getOffsetBoundingRect } from "@/utils";
import {useMouseInElement} from "@vueuse/core";

export default function useRecordPageElementHover() {
  const store = useCounterStore();

  const recordStartHover = (elementPid, elRef) => {
    store.userActivity.hover.elementPid = elementPid;
    const rect = getOffsetBoundingRect(elementPid);
    Object.assign(store.userActivity.hover.domRect, rect);
  }

  const recordEndHover = () => {
    resetHover(store);
  }

  const refreshHoverDomRect = () => {
    const rect = getOffsetBoundingRect(store.userActivity.hover.elementPid);
    Object.assign(store.userActivity.hover.domRect, rect);
  }

  const resetHover = (store) => {
    /*
    Reset the hover state.
    We use this to stop highlighting the last hovered element when the user moves the mouse away.
    */
    store.userActivity.hover.elementPid = '';
    Object.assign(store.userActivity.hover.domRect, {
      offsetHeight: 0,
      offsetWidth: 0,
      offsetTop: 0,
      offsetLeft: 0
    });
  }

  return { recordStartHover, recordEndHover, refreshHoverDomRect }
}
