<template>
  <div v-show="projectCreatorPhase !== 'hidden'">
    <div aria-labelledby="modal-title" aria-modal="true" class="relative z-10" role="dialog">

      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

      <div class="fixed inset-0 z-10 w-screen overflow-y-auto">

        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
             @click.self="this.$emit('update:project-creator-phase', 'hidden')"
        >

          <div class="templates-container">

            <section v-if="projectCreatorPhase === 'choose-template'">
              <div class="block text-sm font-medium leading-6 text-gray-900 text-center pb-2" for="project-name">
                Choose your starting template
              </div>
              <div class="templates-grid">
                <div class="template"
                     v-for="project in templateProjects" :key="project.id"
                     @click="selectTemplate(project)"
                >
                  <p class="pt-2">
                    {{ project.name }}
                  </p>
                  <p>
                    {{ project.description }}
                  </p>
                  <img v-if="project.preview_image_url" class="template-thumbnail" :src="project.preview_image_url"
                       :alt="project.name">
                  <img v-if="!project.preview_image_url" class="template-thumbnail" src="https://placehold.co/200x300"
                       :alt="project.name">

                </div>
              </div>
            </section>

            <section v-if="projectCreatorPhase === 'choose-name'">
              <div>
                <label class="block text-sm font-medium leading-6 text-gray-900" for="project-name">
                  Name your new project
                </label>
                <div class="mt-2">
                  <div
                      class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
                    <input id="project-name"
                           v-model="newProjectName"
                           class="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                           name="project-name"
                           placeholder="The start of something glorious"
                           type="text">
                  </div>
                </div>
              </div>
              <div class="mt-5 sm:mt-6 sm:gap-3">
                <button :class="{'bg-indigo-600': newProjectName, 'hover:bg-indigo-600': newProjectName, 'bg-gray-300': !newProjectName}"
                        :disabled="!newProjectName"
                        class="inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                        type="button"
                        @click="makeNewProject">
                  Create and start designing
                </button>
              </div>
            </section>

          </div>

        </div>
      </div>
    </div>

  </div>
</template>


<script>
import {createProject, getTemplateProjects} from "@/utils/apiRequests";


export default {
  props: {
    projectCreatorPhase: {
      type: String,
    }
  },
  data() {
    return {
      templateProjects: [],
      selectedTemplateId: null,
      newProjectName: ''
    };
  },
  emits: ['update:project-creator-phase'],
  async created() {
    this.templateProjects = await getTemplateProjects();
  },
  mounted() {
    this.newProjectName = this.createRandomName();
  },
  methods: {
    async makeNewProject() {
      const payload = {
        name: this.newProjectName,
        template_id: this.selectedTemplateId
      }
      const {data, error} = await createProject(payload);
      if (!error.value) {
        this.goToDesigner(data.value)
      } else {
        console.error(error.value);
      }
    },
    goToDesigner(project) {
      this.$router.push({name: 'designer', params: {projectId: project.id, pageId: project.home_page.id}})
    },
    selectTemplate(project) {
      this.selectedTemplateId = project.id;
      this.$emit('update:project-creator-phase', 'choose-name')
    },
    createRandomName() {
      return `Glorious project 🚀`;
    }


  }
}
</script>

<style>
.templates-container {
  position: relative; /* Equivalent to 'relative' */
  transform: none; /* Implied by 'transform', but may need specific values based on use */
  overflow: hidden; /* Equivalent to 'overflow-hidden' */
  border-radius: 0.5rem; /* Approximate equivalent to 'rounded-lg' */
  background-color: white; /* Equivalent to 'bg-white' */
  padding: 1rem 1.5rem 1rem 1rem; /* Approximate equivalent to 'px-4 pb-4', adjusted for 'py-2' on small screens */
  text-align: left; /* Equivalent to 'text-left' */
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06); /* Approximate equivalent to 'shadow-xl' */
  transition: all; /* Equivalent to 'transition-all', might need timing function and duration */
}

@media (min-width: 640px) {
  .templates-container {
    margin-top: 2rem; /* Approximate equivalent to 'sm:my-8' */
    margin-bottom: 2rem; /* Approximate equivalent to 'sm:my-8' */
    padding: 0.5rem 1.5rem; /* Approximate equivalent to 'sm:px-6 sm:py-2' */
    max-width: 70%;
  }
}

.templates-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
}

.template {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: small;
  cursor: pointer;
  gap: 5px;
  border: 1px solid rgba(220, 220, 220, 0.3);
  border-radius: 8px;
}

.template:hover {
  box-shadow: 0 2px 3px rgba(105, 104, 104, 0.2);
}

.template-thumbnail {
  object-fit: cover;
  border-radius: 8px;
}


</style>
