<template>
  <section class="left-panel-wrapper">

    <div id="action-icons">
      <div class="action-button"
           @click="toggleLeftSidebar('LeftSidebarAddElement')"
      >
        <IconComponent name="add-element"/>
      </div>
      <div class="action-button"
           @click="toggleLeftSidebar('LeftPanelNavigator')">
        <IconComponent name="navigator"/>
      </div>
      <!--      <button class="pane" @click="toggleLeftSidebar('LeftSidebarContextEditor')">-->
      <!--        <IconComponent name="context-editor" style="width: 30px"/>-->
      <!--      </button>-->
      <div class="action-button"
           @click="toggleLeftSidebar('LeftSidebarAssets')">
        <IconComponent name="image"/>
      </div>
      <!--    <button class="pane" @click="toggleLeftSidebar('LeftSidebarPages')">-->
      <!--      <IconComponent name="page" style="width: 30px"/>-->
      <!--      Pages-->
      <!--    </button>-->
    </div>

    <div class="text-gray-300">
      <!-- Todo: Set navigator as default pane -->
      <LeftPanelNavigator
          v-show="store.leftSidebar.selectedPane === ''"
          class="left-sidebar-pane"
      />
      <div v-show="store.leftSidebar.isShowing" class="left-sidebar">
        <LeftSidebarAddElement
            v-show="store.leftSidebar.selectedPane === 'LeftSidebarAddElement'"
            class="left-sidebar-pane"
        />
        <LeftPanelNavigator
            v-show="store.leftSidebar.selectedPane === 'LeftPanelNavigator'"
            class="left-sidebar-pane"
        />
        <LeftSidebarContextEditor
            v-show="store.leftSidebar.selectedPane === 'LeftSidebarContextEditor'"
            class="left-sidebar-pane"
        />
        <LeftSidebarPages
            v-show="store.leftSidebar.selectedPane === 'LeftSidebarPages'"
            class="left-sidebar-pane"
        />
        <LeftSidebarAssets
            v-show="store.leftSidebar.selectedPane === 'LeftSidebarAssets'"
            class="left-sidebar-pane"
        />
      </div>
    </div>

  </section>

</template>

<script>
import Navigator from "@/components/Navigator.vue";
import {Icon} from "@iconify/vue";
import {useCounterStore} from "@/stores/counter";
import IconComponent from "@/components/IconComponent.vue";
import LeftSidebarPages from "@/components/LeftSidebarPages.vue";
import LeftSidebarAddElement from "@/components/LeftSidebarAddElement.vue";
import LeftSidebarContextEditor from "@/components/LeftSidebarContextEditor.vue";
import LeftPanelNavigator from "@/components/LeftPanelNavigator.vue";
import LeftSidebarAssets from "@/components/LeftSidebarAssets.vue";

export default {
  components: {
    LeftSidebarAssets,
    LeftPanelNavigator,
    LeftSidebarContextEditor,
    LeftSidebarAddElement,
    LeftSidebarPages,
    IconComponent, Icon, Navigator
  },
  setup() {
    const store = useCounterStore();
    return {store};
  },
  methods: {
    toggleLeftSidebar(selectedPane) {
      if (this.store.leftSidebar.selectedPane === selectedPane) {
        this.store.setLeftSidebar(
            {
              'selectedPane' : selectedPane,
              'isShowing': true
            }
        )
      } else {
        this.store.setLeftSidebar({ 'selectedPane' : selectedPane, 'isShowing': true})
      }
    }
  }
}
</script>

<style scoped>
.left-panel-wrapper {
  display: grid;
  grid-template-columns: 40px 1fr;
  background-color: #252525;
  font-size: medium;
  height: 100vh; /* This avoids overflow when there are many navigator items */
  overflow-y: auto;
}

#action-icons {
  border-right: .5px solid #5e5e5e;
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  color: lightgray;

}

#action-icons .action-button {
  width: 24px;
}

#action-icons .action-button:hover {
  color: white;
  user-select: none;
  cursor: pointer;
}

#action-icons:first-child {
  padding-top: .5rem;
}


</style>