<template>
  <ControlGenericWrapper>
    <template v-slot:label>
      Form settings
    </template>
    <template v-slot:content>
      <form>
        <div class="pb-1 grid grid-cols-4 items-center">
          <label>Method</label>
          <GenericInputSelect
              :css-value="formElement.form_method"
              :options="[
                    {value: 'get', description: 'GET'},
                    {value: 'post', description: 'POST'},
                    ]"
              class="select w-full input-field col-span-3 bg-[#252525]"
              name="form-method"
              @update:css-value="formElement.form_method = $event"/>
        </div>
        <div class="pb-1 grid grid-cols-4 items-center">
          <label>Endpoint</label>
          <GenericInputText
              :value="formElement.form_endpoint"
              class="input-field col-span-3 bg-[#252525]"
              placeholder="https://..."
              name="form-endpoint"
              @update:value="formElement.form_endpoint = $event"/>
        </div>
      </form>

      <div class="grid grid-cols-4 items-center">
        <label>State</label>
        <GenericInputRadioGroup
            :current-value="formElement.form_showing_state"
            :options="[
                { description: 'Normal', value: 'normal'},
                { description: 'Success', value: 'success' },
                { description: 'Error', value: 'error' },
            ]"
            class="grid col-span-3 grid-cols-3 gap-[1px]"
            name="showing-state"
            @update:value="formElement.form_showing_state = $event; this.store.setSelectedElementPid(this.formElement.pid);"
        />
      </div>

      <hr>
      <form class="flex flex-row">
        <div class="grid grid-cols-4 items-center">
          <label>Include CSRF token</label>
          <GenericInputRadioGroup
              :current-value="formElement.form_include_csrf_token"
              :options="[
                { description: 'Yes', value: true },
                { description: 'No', value: false },
            ]"
              class="grid col-span-3 grid-cols-2 gap-[1px]"
              name="include-csrf-token"
              @update:value="formElement.form_include_csrf_token = $event;"
          />
        </div>

      </form>
      <hr>


    </template>
  </ControlGenericWrapper>
</template>

<script>
import { useCounterStore } from '@/stores/counter';

import Toggle from "@/components/Toggle.vue";
import ControlFormInput from "@/components/ControlFormInput.vue";
import GenericInputRadioGroup from "@/components/GenericInputRadioGroup.vue";
import ControlGenericWrapper from "@/components/ControlGenericWrapper.vue";
import GenericInputSelect from "@/components/GenericInputSelect.vue";
import GenericInputText from "@/components/GenericInputText.vue";

export default {
  name: 'ControlForm',
  components: {
    GenericInputText,
    GenericInputSelect, ControlGenericWrapper, GenericInputRadioGroup, ControlFormInput,
    Toggle
  },
  computed: {
    formElement() {
      /*
      We find the form element, whether it is the selected element or an ancestor.

      We do this because we want to edit the form element even if the user has selected a child
      of the form element.
       */
      let element = this.store.selectedElement;
      while (element?.component_name !== 'PageForm') {
        if (element.component_name === 'PageRoot') {
          throw new Error("PageForm ancestor not found.");
        }
        element = this.store.findElementByPid(element.parentPid);
      }
      return element;
    },
    store() {
      return useCounterStore();
    },
  },
};
</script>
