<script>
import {useFetchJson} from "@/composables/useFetchJson";
import Cookies from "universal-cookie";
import {useToast} from "vue-toastification";
import IconComponent from "@/components/IconComponent.vue";
import {BACKEND_PATHS} from "@/constants";

export default {
  components: {IconComponent},
  data() {
    return {
      email: '',
      password: '',
      isSubmitting: false,
      loginUri: `${import.meta.env.VITE_OX_URL}/${BACKEND_PATHS.AUTH_GOOGLE()}`
    };
  },
  setup() {
    const toast = useToast()
    return {
      toast
    }
  },
  methods: {
    toggleSignUp() {
      this.isSignUp = !this.isSignUp;
    },
    async submitEmailAndPassword() {
      if (this.isSignUp) {
        await this.signUp()
      } else {
        await this.signIn()
      }
    },
    async signIn() {
      this.isSubmitting = true

      const postData = {
        operation: 'sign_in',
        email: this.email,
        password: this.password,
      };

      const options = {method: 'POST', body: JSON.stringify(postData)}
      const url = `${import.meta.env.VITE_OX_URL}/${BACKEND_PATHS.AUTH_EMAIL_AND_PASSWORD()}`
      const {data, error} = await useFetchJson(url, options)

      if (!error.value) {
        window.location.reload()
      } else {
        const firstError = Object.values(data.value.error)[0][0]
        this.toast.error(firstError, {timeout: 8000})
      }
      this.isSubmitting = false
    },
    addAnyConfirmedEmail() {
      /*
      We update the page if the user just confirmed their email.
       */
      const cookies = new Cookies();
      const pd_confirmed_email = cookies.get('pd_just_confirmed_email');
      if (pd_confirmed_email) {
        this.toast.success(`Confirmed your email ${pd_confirmed_email} ✅`, {timeout: 7000});
        this.email = pd_confirmed_email;
        cookies.remove('pd_just_confirmed_email');
      }
    }
  },
  created() {
    const script = document.createElement('script')
    script.src = 'https://accounts.google.com/gsi/client'
    script.async = true
    script.defer = true
    document.body.appendChild(script)
  },
  mounted() {
    this.addAnyConfirmedEmail()
  }
};
</script>

<template>
  <main class="main-style">
    <h1 class="h1-style">
      Sign in to Photon Designer
    </h1>
    <p class="signup-text">
      Don't have an account?
      <router-link :to="{name: 'signUp'}" class="link link-primary">
        Sign up
      </router-link>
    </p>
    <br>

    <div class="div-google-signin">
      <div id="g_id_onload"
           :data-login_uri="loginUri"
           data-client_id="693882164295-jpdsciu4lmvcpfg15ljmftvtsqn22smb.apps.googleusercontent.com"
           data-context="signin"
           data-itp_support="true"
           data-ux_mode="redirect">
      </div>

      <div class="g_id_signin"
           data-logo_alignment="left"
           data-shape="pill"
           data-size="large"
           data-text="signin_with"
           data-theme="outline"
           data-type="standard">
      </div>
    </div>

    <div class="div-or-separator">
      <div class="separator-line"></div>
      <span class="separator-text">OR</span>
      <div class="separator-line"></div>
    </div>

    <div>
      <div class="div-email-input">
        <label class="label-email" for="email">Email</label>
        <input id="email" v-model="email" autocomplete="true" class="input-email" name="email"
               placeholder="alan.turing@example.com" type="email">
      </div>

      <div class="div-password-input">
        <div class="password-label-wrapper">
          <label class="label-password" for="password">Password</label>
          <a class="password-forgot-link">Forgot your password?</a>
        </div>
        <input id="password" v-model="password" autocomplete="true" class="input-password" name="password"
               placeholder="••••••••••••" type="password">
      </div>

      <button class="continue-button btn" @click="signIn()">
        Sign in to my account
        <span v-show="isSubmitting" class="continue-button-icon">
          <IconComponent name="loader"/>
        </span>
      </button>
      <p class="signup-text pt-3">
        Don't have an account?
        <router-link :to="{name: 'signUp'}" class="link link-primary">
          Sign up
        </router-link>
      </p>
    </div>

    <br>
  </main>
</template>



<style>
:root {
  --sign-in-color-1st: #180b0b;
  --sign-in-color-2nd: #5d5d5d;
  --sign-in-color-3rd: #f5f5f5;
}
</style>

<style scoped>

.main-style {
  margin: auto;
  width: 100%;
  max-width: 450px;
}

.h1-style {
  margin-top: 2rem;
  text-align: center;
  font-size: 1.25rem;
  letter-spacing: -0.16px;
  color: var(--sign-in-color-1st);
  font-weight: bold;
}

.div-google-signin {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  justify-content: center;
  min-height: 60px;
}

.div-or-separator {
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.separator-line {
  height: 1px;
  width: 100%;
  background-color: var(--sign-in-color-3rd);
}

.separator-text {
  margin: 0 1rem;
  font-size: 0.75rem;
  color: var(--sign-in-color-1st);
  font-weight: normal;
}

.div-email-input,
.div-password-input {
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.password-label-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.password-forgot-link {
  font-size: 0.875rem;
  color: var(--sign-up-color-3rd);
  text-decoration: none;
  cursor: pointer;
}

.label-email,
.label-password {
  display: block;
  font-size: 0.875rem;
  color: var(--sign-in-color-1st);
  cursor: default;
}

.input-email,
.input-password {
  height: 2.5rem;
  background-color: #f5f5f5;
  color: #180b0b;
  padding: 0.75rem;
  border-radius: 0.375rem;
  border: 1px solid #f5f5f5;
  width: 100%;
  outline: none;
}


.continue-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  padding: 0 1rem;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  font-weight: 500;
  background-color: black;
  color: white;
  border: 1px solid #f5f5f5;
  width: 100%;
  cursor: pointer;
}

.continue-button-icon {
  margin-left: 0.5rem;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}



.terms-text,
.signup-text {
  font-size: 0.75rem;
  color: var(--sign-up-color-2nd);
  font-weight: normal;
  text-align: center;
}

.link-terms-service,
.link-privacy-policy,
.link-sign-up {
  color: blue;
  text-decoration: none;
}


</style>
