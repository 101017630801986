<template>
  <div
      v-if="linkElement"
      :style="tooltipStyle"
      class="tooltip"
      :class="[tooltipType === 'selected' ? 'selected-tooltip' : 'hover-tooltip']"
  >
    <Icon :icon="linkElement.navigatorIcon"/>
    <span>{{ linkElement.component_name }}</span>


    <div style="width: 15px; display: flex; align-items: center">
      <IconComponent v-if="tooltipType === 'selected'" name="settings"
                     @click="store.setElementEditor({selectedTabName: 'settings'})"/>
    </div>

    <div v-if="tooltipType === 'selected' && hasEmptyNest" id="empty-nest-hint">
      Empty
    </div>
  </div>
</template>

<script>
import { useCounterStore } from '@/stores/counter'
import {Icon} from "@iconify/vue";
import useSelectedElementBounding from "@/composables/useSelectedElementBounding";
import useHoveredElementBounding from "@/composables/useHoveredElementBounding";
import IconComponent from "@/components/IconComponent.vue";

export default {
  components: {IconComponent, Icon},
  props: {
    linkElement :{
      type: Object,
      // The element to which the tooltip relates.
    },
    tooltipType: {
      type: String,
      // The type of tooltip. Either 'selected' or 'hovered'.
    },
    aboveAdjustment: {
      type: Number,
      // The amount we adjust the tooltip when it is above the element.
    },
    belowAdjustment: {
      type: Number,
      // The amount we adjust the tooltip when it is below the element.
    },
  },

  setup(props) {
    const selectedBoundingBox = useSelectedElementBounding().boundingBox
    const hoveredBoundingBox = useHoveredElementBounding().boundingBox

    const boundingBox = {
      "selected": selectedBoundingBox, "hovered": hoveredBoundingBox
    }[props.tooltipType]

    return {
      boundingBox
    }
  },
  computed: {
    store(){
      return useCounterStore();
    },
    tooltipStyle() {
      if (this.boundingBox.top.value == null) {return}

      let adjustedTop
      const hasSpaceAbove = this.boundingBox.top.value > 60 // Check if space to show tooltip above.
      if (hasSpaceAbove) {
        adjustedTop = this.boundingBox.top.value - this.aboveAdjustment
      }
      else { // Show below.
        adjustedTop = this.boundingBox.bottom.value + this.belowAdjustment
      }

      return {
        top: `${adjustedTop}px`,
        left: `${this.boundingBox.left.value}px`,
      }
    },
    hasEmptyNest() {
      return this.linkElement.hasNest &&
          this.store.getActiveChildElements(this.linkElement.pid).length === 0
    }
  },
}
</script>
