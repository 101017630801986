<template>
  <div>
    <label for="layout-style">Input control:</label>
    <div>

      <form>
        <label for="name">Name</label>
        <input v-model="name" type="text" placeholder="..."/>
      </form>
      <form>
        <label for="type">Type</label>
        <select v-model="_type">
          <option value="text">Text</option>
          <option value="email">Email</option>
          <option value="password">Password</option>
          <option value="number">Number</option>
          <option value="url">Url</option>
        </select>

      </form>

    </div>
  </div>
</template>

<script>
import { useCounterStore } from '@/stores/counter';

export default {
  name: 'ControlFormInput',
  props: {
    element: {
      description: 'An optional input element. If not provided, the selected element will be used.',
      required: false,
    },
  },
  computed: {
    store() {
      return useCounterStore();
    },
    targetElement() {
      /*
      We use the provided element if it exists,
      otherwise we use the selected element in the store.
       */
      return this.element ? this.element : this.store.selectedElement;
    },
    pid() {
      return this.targetElement?.pid
    },
    name: {
      get() {
        return this.targetElement?.form_input_name || '';
      },
      set(value) {
        this.store.setElementValue(this.pid, ["form_input_name"], value);
      }
    },
    _type: {
      get() {
        return this.targetElement?.form_input_type;
      },
      set(value) {
        this.store.setElementValue(this.pid, ["form_input_type"], value);
      }
    },
  },
};
</script>
