<template>
  <ControlGenericWrapper>
    <template v-slot:label>
      Form input settings
    </template>
    <template v-slot:content>

      <div class="pb-1 grid grid-cols-4 items-center">
        <label>Name</label>
        <GenericInputText
            :value="selectedElement.form_input_name"
            class="input-field col-span-3 bg-[#252525]"
            placeholder="example-name"
            @update:value="selectedElement.form_input_name = $event"/>
      </div>

      <div class="pb-1 grid grid-cols-4 items-center">
        <label>Type</label>
        <GenericInputSelect
            :css-value="selectedElement.form_input_type"
            :options="[
                {value: 'text', description: 'Plain'},
                {value: 'email', description: 'Email'},
                {value: 'number', description: 'Number'},
                {value: 'password', description: 'Password'},
                {value: 'phone', description: 'Phone'}
            ]"
            class="select w-full input-field col-span-3 bg-[#252525]"
            @update:css-value="selectedElement.form_input_type = $event"/>
      </div>

      <div class="pb-1 grid grid-cols-4 items-center">
        <label>Placeholder</label>
        <GenericInputText
            :value="selectedElement.form_input_placeholder"
            class="input-field col-span-3 bg-[#252525]"
            type="text"
            placeholder="E.g., enter the name of your favourite child."
            @update:value="selectedElement.form_input_placeholder = $event"/>
      </div>

      <div class="grid grid-cols-4 items-center">
        <label>Required</label>
        <GenericInputRadioGroup
            name="form-input-required"
            :current-value="selectedElement.form_input_required"
            :options="[
                { description: 'Yes', value: true },
                { description: 'No', value: false },
            ]"
            class="grid col-span-3 grid-cols-2 gap-[1px]"
            @update:value="selectedElement.form_input_required = $event;"
        />
      </div>
    </template>
  </ControlGenericWrapper>
</template>

<script>
import { useCounterStore } from '@/stores/counter';

import Toggle from "@/components/Toggle.vue";
import ControlFormInput from "@/components/ControlFormInput.vue";
import GenericInputRadioGroup from "@/components/GenericInputRadioGroup.vue";
import ControlGenericWrapper from "@/components/ControlGenericWrapper.vue";
import GenericInputSelect from "@/components/GenericInputSelect.vue";
import GenericInputText from "@/components/GenericInputText.vue";

export default {
  components: {
    GenericInputText,
    GenericInputSelect, ControlGenericWrapper, GenericInputRadioGroup, ControlFormInput, Toggle},
  computed: {
    formElement() {
      /*
      We go up the chain of elements to find the form element, whether it is the selected element or an ancestor.

      We do this because we want to edit the form element even if the user has selected a child
      of the form element.
       */
      let element = this.store.selectedElement;
      while (element?.component_name !== 'PageForm') {
        if (element.component_name === 'PageRoot') {
          throw new Error("PageForm ancestor not found.");
        }
        element = this.store.findElementByPid(element.parentPid);
      }
      return element;
    },
    store() {
      return useCounterStore();
    },
    selectedElement() {
      return this.store.selectedElement;
    },

  },
};
</script>
