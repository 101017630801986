<template>
  <PageText :element="this.element"/>

</template>

<script>
import PageText from "@/components/element_components/PageText.vue";


export default {
  components: {PageText},
  props: {
    element: {
      type: Object,
      required: true,
    },
  },
}
</script>