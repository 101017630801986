import {isRef, ref} from 'vue';
import { useCounterStore } from "@/stores/counter";
import _ from "lodash";

export default function useEdit() {
    const isEditing = ref(false);
    const store = useCounterStore();

    const startEditing = () => {
        isEditing.value = true;
    };

    const stopEditing = () => {
        isEditing.value = false;
    };

    const updateInnerText = (pid, e) => {
        store.setElementValue(pid, "text", e.target.innerText);
    };

    const selectOrEdit = (pid) => {
        /*
        We use this function reduce the number of clicks the user must make to
        edit an element.
         */
        if (!_.isString(pid)) {
            throw new Error(
                "selectOrEdit :: pid must be type string. Received type: " + typeof pid
            );
        }

        const isSelected = store.selectedElementPid === pid;
        if (isSelected) {
            startEditing();
        } else {
            store.setSelectedElementPid(pid)
        }
    }


    return {isEditing, startEditing, stopEditing, updateInnerText, selectOrEdit};
}
