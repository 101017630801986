import './assets/all_non_workframe.css'
import {createApp, nextTick} from 'vue'
import {createPinia} from 'pinia'
import router from './router.js'
import App from './App.vue'
import constants from '../../shared_constants.json';
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import * as Sentry from "@sentry/vue";
import SimpleAnalytics from "simple-analytics-vue";
import posthog from "posthog-js";
import {createVfm} from 'vue-final-modal'
import {createHead} from '@unhead/vue'


const app = createApp(App)


if (import.meta.env.PROD) {
  Sentry.init({
    app,
    dsn: "https://b78cf45bf6cd23865cef3a6d95a76ecf@o1426036.ingest.sentry.io/4506780117762048",
    integrations: [
      Sentry.browserTracingIntegration({
        router,
        routeLabel: "path",
      }),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
        maskTextFn: (text, element) => {
          if (element?.dataset['hog-visible'] === 'true') {
            return text
          }
          return '*'.repeat(text.length)
        },
      }),
    ],
    trackComponents: true,
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["https://.*\\.photondesigner.com"],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

  posthog.init(
      'phc_lyCYVq5y3vrd8lw1hjBT4TCy1UyPf2oO7ps5Px1GAUH',
      {
        api_host: 'https://eu.posthog.com',
        capture_pageview: false
      }
  ) // https://posthog.com/docs/libraries/vue-js
  app.provide("posthog", posthog);

}

// Create Pinia instance
const pinia = createPinia()

app.config.globalProperties.$constants = constants; // Load the shared constants. These are available in all components as this.$constants

app.use(pinia)

app.use(router)

const toastOptions = {hideProgressBar: true, transition: "Vue-Toastification__fade", closeButton: false}
app.use(Toast, toastOptions);  // https://vue-toastification.maronato.dev/


app.use(SimpleAnalytics, {domain: "simple.photondesigner.com"}); // https://github.com/simpleanalytics/vue-plugin#readme


// Add Vue final modal (https://vue-final-modal.org/get-started/guide/setup)
const vfm = createVfm()
app.use(vfm)
import 'vue-final-modal/style.css'

// Add unhead (https://unhead.unjs.io/setup/vue/installation#optional-auto-imports)
const head = createHead()
app.use(head)


app.mount('#app')


// https://posthog.com/docs/libraries/vue-js#capturing-pageviews
router.afterEach((to, from, failure) => {
  if (!failure) {
    nextTick(() => {
      posthog.capture('$pageview', {path: to.fullPath});
    }).then()
  }
});
