import { ref } from 'vue';
import { useCounterStore } from "@/stores/counter";
import useUndoRedo from "@/composables/useUndoRedo";
import {updateActiveSiblingOrder} from "@/utils";
import _ from "lodash";
import {ComponentName} from "@/utils/elementCreator";

export default function useDelete() {
    const store = useCounterStore();

    function deleteWithUndo(pid){
        const { doWithUndo } = useUndoRedo();

        const forwardFn = () => deleteElement(pid);
        const reverseFn = () => undeleteElement(pid);

        doWithUndo(forwardFn, reverseFn);
    }

    function deleteElement(pid) {
        const element = store.findElementByPid(pid);
        if (canDeleteElement(element)){
            markDeleted(element);
            updateActiveSiblingOrder(store, element);  // Re-order non-deleted siblings.
            if (element.pid === store.selectedElementPid){
                store.setSelectedElementPid(null)
            }
        }
    }

    function markDeleted(element) {
        element.is_deleted = true;

        const children = getAllChildElements(element.pid);
        if (children.length > 0) {
            children.forEach(markDeleted);
        }

        if (isBlockChildDeletion(element)) {
            deleteWithUndo(element.parentPid);
        }
    }

    function isBlockChildDeletion(element) {
        /*
        If we delete a PageFormState, we delete the whole form block or conditional block.
        */
        const blockChildren = [
            ComponentName.PageFormState,
        ];

        return blockChildren.includes(element.component_name)
    }

    function undeleteElement(pid) {
        const element = store.findElementByPid(pid);
        if (element?.is_deleted) {
            markNotDeleted(element);

            if (store) {
                updateActiveSiblingOrder(store, element);
            }
            store.setSelectedElementPid(element.pid)
        }
        else{
            console.error(`Cannot undelete element with pid: ${pid}`);
        }
    }

    function markNotDeleted(element) {
        element.is_deleted = false;

        const children = getAllChildElements(element.pid);
        if (children.length > 0) {
            children.forEach(markNotDeleted);
        }
    }

    function canDeleteElement(element) {
        const cannotDelete = _.isNil(element) || element.is_deleted || element.component_name === ComponentName.PageRoot;
        return !cannotDelete
    }


    function getAllChildElements(parentPid) {
        /*
        Get all child elements of an element, including deleted elements.
         */
        return store.elements
            .filter(element => element.parentPid === parentPid)
            .sort((a, b) => a.order - b.order)
    }



    return { deleteWithUndo, deleteElement, undeleteElement }
}
