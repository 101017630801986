<template>
  <div v-if="selectedElement" class="w-full text-[11px] base">
    <div class="pl-2 py-1">
      Borders
    </div>

    <div class="py-1 px-2 first-layer">

      <div class="pb-1 grid grid-cols-8 items-center">
        <CssLabel css-property="border-radius" label="Radius"/>
        <GenericInputRange
            class="col-span-4 mr-1"
            :num="computedNum('border-radius').value"
            :unit="computedUnit('border-radius').value"
            :max-value="50"
            @update:num-object="setBorderRadius($event)"
        />

        <GenericInputNumUnit
            class="col-span-2"
            :css-value="computedCssValue('border-radius', '0px').value"
            @update:num-object="setLocalCssProperty('border-radius', $event.css.val)"
            :exclude-options="['auto', 'fr']"
        />
      </div>

      <div class="pb-1 grid grid-cols-4">
        <!--        Select the border that we want to edit.-->
        <label class="whitespace-nowrap">Borders</label>
        <GenericInputRadioGroup
            class="col-span-3 grid grid-cols-3 grid-rows-3 "
            name="selected-border"
            :current-value="selectedBorder.name"
            :options="[
                { description: 'all', value: 'border', class: 'row-start-2 col-start-2'},
                { description: 'top', value: 'border-top' , class: 'row-start-1 col-start-2'},
                { description: 'left', value: 'border-left' , class: 'row-start-2 col-start-1'},
                { description: 'right', value: 'border-right' , class: 'row-start-2 col-start-3'},
                { description: 'bottom', value: 'border-bottom' , class: 'row-start-3 col-start-2'},
            ]"
            @update:value="selectedBorder.name = $event"
        />
      </div>

      <div class="pb-1 grid grid-cols-4">
        <label>Style</label>
        <GenericInputRadioGroup
            class="col-span-3 grid grid-cols-4"
            :name="selectedBorder.name + '-style'"
            :current-value="borderComputedCssValue(selectedBorder.name + '-style', 'none').value"
            :options="[
                {value: 'none', iconComponent: 'border-style-none'},
                {value: 'solid', iconComponent: 'border-style-solid'},
                {value: 'dotted', iconComponent: 'border-style-dotted'},
                {value: 'dashed', iconComponent: 'border-style-dashed'},
            ]"
            @update:value="setBorderProperty('style', $event)"
        />
      </div>

      <div class="pb-1 grid grid-cols-4">
        <label>Width</label>
        <GenericInputNumUnit
            class="col-span-3"
            :css-value="borderComputedCssValue(selectedBorder.name + '-width', '0px').value"
            @update:num-object="this.setBorderProperty('width', $event.css.val); setStyleIfNone()"
            :exclude-options="['auto', 'fr']"
        />
      </div>

      <div class="pb-1 grid grid-cols-4 items-center">
        <label>Border color</label>
        <GenericInputColor
            class="col-span-3"
            :color="borderComputedCssValue(selectedBorder.name + '-color', '#FFFFFFFF').value"
            @update:color="setBorderProperty('color', $event)"
            label="Border color"
        />
      </div>

    </div>
  </div>
</template>


<script>

import useSelectedElement from "@/composables/useSelectedElement";
import GenericInputRadioGroup from "@/components/GenericInputRadioGroup.vue";
import GenericInputNumUnit from "@/components/GenericInputNumUnit.vue";
import GenericInputColor from "@/components/GenericInputColor.vue";
import GenericInputRange from "@/components/GenericInputRange.vue";
import parseCss from "@/utils/parseCss";
import {computed, toValue} from "vue";
import useUndoRedo from "@/composables/useUndoRedo";
import CssLabel from "@/components/CssLabel.vue";

export default {
  components: {
    CssLabel,
    GenericInputRange,
    GenericInputColor,
    GenericInputNumUnit,
    GenericInputRadioGroup,

  },
  setup() {
    const { store,selectedElement, setLocalCssProperty, computedCssValue, removeCssDeclarations  } = useSelectedElement();
    const { doWithUndo } = useUndoRedo()
    const {getCssNumAndUnit} = parseCss()

    const computedNum = (cssProperty, defaultValue='0px') => computed(() => {
      return getCssNumAndUnit(store.selectedElementFinalCssBlock[cssProperty] || defaultValue).num;
    });

    const computedUnit = (cssProperty, defaultValue='0px') => computed(() => {
      return getCssNumAndUnit(store.selectedElementFinalCssBlock[cssProperty] || defaultValue).unit;
    });

    const borderComputedCssValue = (cssProperty, defaultCssValue) => computed(() => {
      /*
      We account for where the user has set a border for all borders, and then wants to edit a
      specific border.

      For example, we want to show the user the value that they have set for border-top-style,
      not the value that they have set for border-style.

      But, if they haven't set a specific border-top-style, we want to show them the value that they have
      set for all borders.
       */
      return computedCssValue(
          cssProperty,
          computedCssValue('border-' + cssProperty, defaultCssValue).value
      ).value
    })

    return {
      selectedElement, setLocalCssProperty, computedCssValue, removeCssDeclarations, getCssNumAndUnit,
      computedNum, computedUnit, store, doWithUndo, borderComputedCssValue
    };
  },
  data() {
    return {
      selectedBorder: {name: 'border'}
    }
  },
  methods: {
    setBorderProperty(cssProperty, cssValue) {
      /*
      This lets the user set a border property for all borders, or for a specific border, e.g., border-top.
       */
      const isEditingAllBorders = this.selectedBorder.name === 'border'
      if (isEditingAllBorders) {
        this.removeCssDeclarations(/^border-(top|left|right|bottom)/)
      }

      this.setLocalCssProperty(this.selectedBorder.name + '-' + cssProperty, cssValue)
    },

    setBorderRadius(inputEvent){
      const breakpointCssProperty = `css_${toValue(this.store.currentBreakpoint)}`;

      const forwardFn = () => {
        this.selectedElement[breakpointCssProperty]['border-radius'] = inputEvent.css.val;

      }
      const reverseFn = () => {
        this.selectedElement[breakpointCssProperty]['border-radius'] = inputEvent.css.previousVal;
      }

      if (inputEvent.isChanging) {
        forwardFn()  // If the user is still changing the value, we don't set an undo step. The user doesn't want to undo by each step, e.g., a pixel.
      }
      else{
        this.doWithUndo(forwardFn, reverseFn);
      }
    },
    setStyleIfNone(){
      /*
      If the user has set a border-width, but not a border-style, we set the border-style to solid.
      Without this, the border will not be visible after the user sets a width. This can be confusing to the user.
       */
      const currentBorderStyleValue = this.borderComputedCssValue(
          this.selectedBorder.name + '-style', 'none'
      ).value
      if (currentBorderStyleValue === 'none'){
        this.setLocalCssProperty('border-style', 'solid')
      }
    }
  }
};
</script>
<style scoped>
.base {
  background-color: #333333;
}

.first-layer {
  background-color: rgb(94, 89, 89);
  color: white;
}
</style>
