<template>
  <div class="editor-area w-full"
       :style="pageEditorStyle"
       @scroll="this.refreshElementPositions()"
  >
    <PageElementHud></PageElementHud>
    <PageRoot :element="store.getRootElement()" />
  </div>
</template>

<script>
import {useCounterStore} from '@/stores/counter'
import PageElementHud from "@/components/PageElementHud.vue";
import useRecordPageElementHover from "@/composables/useRecordPageElementHover.js";
import PageRoot from "@/components/element_components/PageRoot.vue";
import useKeyboardShortcuts from "@/composables/useKeyboardShortcuts";



export default {
  name: "PageEditor",
  components: {PageRoot, PageElementHud},
  computed: {
    store(){
      return useCounterStore()
    },
    pageEditorStyle() {
      const styles = {}
      const userDraggingElement = this.store.userActivity.drag.pid !== null;
      if (userDraggingElement) {
        styles['cursor'] = 'grabbing';
      }
      return styles;
    },
  },
  methods: {
    refreshElementPositions() { // Can we remove this?
      // this.store.updateSelectedElementComputedStyle()
      // this.refreshHoverDomRect()
    },
  },
  setup() {
    const { refreshHoverDomRect } = useRecordPageElementHover();

    const store = useCounterStore()
    useKeyboardShortcuts(store.workFrame?.contentWindow);

    return { refreshHoverDomRect };
  },
  mounted() {
    this.refreshElementPositions()
  },

}
</script>