<template>
  <div
      ref="domElement"
      :id="pid"
      @click.stop="select()"
      style="user-select: none;"
      :style="finalStyle"
      @mouseover.stop="recordStartHover(pid, domElement)"
      @mouseleave.stop="recordEndHover()"
      class="hintable"
      :class="{
        'nest-empty': willDisplayNest,
        'nest-hint': isInsideAndIsGuide && this.store.userActivity.drag.insertIntoGuide,
        'top-half-hint': isInsideAndIsGuide && !this.store.userActivity.drag.insertIntoGuide && inTopHalf,
        'bottom-half-hint': isInsideAndIsGuide && !this.store.userActivity.drag.insertIntoGuide && inBottomHalf,
        'left-half-hint': isInsideAndIsGuide && !this.store.userActivity.drag.insertIntoGuide && inLeftHalf,
        'right-half-hint': isInsideAndIsGuide && !this.store.userActivity.drag.insertIntoGuide && inRightHalf,
        'has-no-width-properties': isChildless && this.hasNoWidthProperties,
        'has-no-height-properties': isChildless && this.hasNoHeightProperties,
      }"
      draggable="false"
  >
    <component
        v-for="element in childElements" :is="element.component"
        :element="element" :key="element.pid"
    />
  </div>
</template>

<script>
import useRecordPageElementHover from "@/composables/useRecordPageElementHover.js";
import useElement from '@/composables/useElement.js'
import {computed, ref} from 'vue'
import useDragAndDrop from "@/composables/useDragAndDrop";
import useRecordMouseover from "@/composables/useRecordMouseover";


export default {
  components: {},
  props: {
    element: {
      type: Object,
      required: true,
    },
    movable: {
      // Whether the user can move the PageDiv.
      type: Boolean,
      default: true,
    },

  },
  setup(props) {
    const domElement = ref(null)

    const { store, finalStyle, pid, select } = useElement(props.element)
    const { recordStartHover, recordEndHover } = useRecordPageElementHover();
    const {
      isOutside, isInside, inBottomHalf, inTopHalf,
      inLeftHalf, inRightHalf,
    } = useRecordMouseover(
        props.element, domElement
    )

    const isDragging = initDraggable(props, domElement)

    const isInsideAndIsGuide = computed(() => {
      return isInside.value && store.userActivity.drag.guideElement?.pid === pid.value;
    })

    return {
      store, finalStyle, pid, select,
      recordStartHover, recordEndHover,
      domElement, isDragging,
      inBottomHalf,
      inTopHalf,
      inLeftHalf,
      inRightHalf,
      isOutside,
      isInside,
      isInsideAndIsGuide,
    };
  },
  computed: {
    willDisplayNest(){
      return (
          this.isChildless &&
          this._hasNoBorderProperties
      );
    },
    isChildless() {
      return this.childElements.length === 0;
    },
    childElements() {
      return this.store.getActiveChildElements(this.element.pid);
    },
    hasNoWidthProperties() {
      /*
      We do this to add default dimensions
      and then remove those default dimensions when the user adds their own.
       */
      const properties = ['min-width', 'width', 'max-width'];
      return !properties.some(property => this.finalStyle.hasOwnProperty(property));
    },
    hasNoHeightProperties() {
      /*
      We do this to add default dimensions
      and then remove those default dimensions when the user adds their own.
       */
      const properties = ['min-height', 'height', 'max-height'];
      return !properties.some(property => this.finalStyle.hasOwnProperty(property));
    },
    _hasNoBorderProperties() {
      /*
      We only show a default border for an empty div if the user hasn't added their own border.
       */
      const properties = ['border-left', 'border-right', 'border-top', 'border-bottom'];
      return !properties.some(property => this.finalStyle.hasOwnProperty(property));
    },
  },
}

function initDraggable(props, domElement) {
  /*
  We allow or disallow the user to drag the component. We use this for elements such as PageRoot.
   */
  if (props.movable){
    return useDragAndDrop(props.element, domElement)
  }
  else {
    return computed(() => false);
  }
}

</script>