import { onMounted, onUnmounted, ref, watch, nextTick } from 'vue';
import { useElementBounding } from "@vueuse/core";
import {useCounterStore} from "@/stores/counter";


export default function useSelectedElementBounding() {
    const el = ref(null);
    const boundingBox = useElementBounding(el, {immediate: true});
    const store = useCounterStore()

    const refreshBoundingBox = () => {
        if (el.value) {
            boundingBox.update();
        }
    };

    const initBoundingBox = async () => {
        await nextTick(); // Ensure the DOM is updated before accessing elements
        watch(() => store.selectedElementPid, () => {
            const domEl = store.workFrame?.contentWindow.document.getElementById(store.selectedElementPid);
            if (domEl) {
                el.value = domEl;
                refreshBoundingBox();
            }
        }, {immediate: true})

        refreshIfCssChanges();

        store.workFrame.contentWindow.addEventListener('scroll', refreshBoundingBox, true);
    };

    const refreshIfCssChanges = () => {
        /*
        Watch for changes in the css of the selected element. This detects changes in padding.
         */
        watch(() => [
                store.selectedElement?.css_desktop, store.selectedElement?.css_tablet,
                store.selectedElement?.css_mobile
            ], () => {
                refreshBoundingBox();
            }, {deep: true, immediate: true}
        )
    }



    onMounted(async () => {
        initBoundingBox()
    });

    return {  boundingBox, ...boundingBox };
}
