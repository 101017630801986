export default function parseCss() {

    interface numAndUnit {
        num: string,
        unit: string
    }

    const getCssNumAndUnit = (cssValueString: string): numAndUnit => {
        if (typeof cssValueString !== 'string') {
            throw new Error(
                `getCssNumAndUnit :: Expected a string, got ${typeof cssValueString} with value: ${cssValueString}`
            );
        }

        const anyEdgeCase = _findEdgeCases(cssValueString);

        if (Object.keys(anyEdgeCase).length > 0) {
            return <numAndUnit>anyEdgeCase;
        } else {
            const num: string = _parseNum(cssValueString)
            const unit: string = _parseUnit(cssValueString)
            return {num, unit}
        }
    }

    const _findEdgeCases = (cssValueString: string): numAndUnit | {} => {

        // Edge case: 'Nan...'
        if (cssValueString.startsWith('NaN')) {
            return {num: 'auto', unit: 'auto'}
        }

        // Edge case: 'auto'
        const unit: string = cssValueString.replace(/[0-9.]/g, '')
        if (unit === 'auto') {
            return {num: 'auto', unit: 'auto'}
        }

        // Edge case: '0'
        const num: string = _parseNum(cssValueString);
        if (num === '0') {
            return {num: '0', unit: 'px'}
        }

        return {}

    }

    const _parseNum = (cssValueString: string): string => {
        const num: string = String(parseFloat(cssValueString));
        if (isNaN(Number(num))) {
            throw new Error(`parseNum :: Could not parse number from ${cssValueString}`);
        }
        return num;
    }

    const _parseUnit = (cssValueString: string): string => {
        const unit: string = cssValueString.replace(/[0-9.]/g, '')
        if (unit === '') {
            throw new Error(`getCssNumAndUnit :: Could not parse unit from ${cssValueString}`);
        }
        return unit
    }

    return {getCssNumAndUnit}

}


