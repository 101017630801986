export const INHERITABLE_CSS_PROPERTIES = [
    /*
    These CSS properties are inheritable. Other CSS properties are not inheritable.
    This follows CSS inheritance rules.
     */
    "azimuth", "border-collapse", "border-spacing", "caption-side", "color",
    "cursor", "direction", "elevation", "empty-cells", "font-family",
    "font-size", "font-style", "font-variant", "font-weight", "font",
    "letter-spacing", "line-height", "list-style-image", "list-style-position",
    "list-style-type", "list-style", "orphans", "pitch-range", "pitch",
    "quotes", "richness", "speak-header", "speak-numeral", "speak-punctuation",
    "speak", "speech-rate", "stress", "text-align", "text-indent",
    "text-transform", "visibility", "voice-family", "volume", "white-space",
    "widows", "word-spacing"
];


export const FONTS = [
    { description: "system-ui", value: "system-ui" },
    { description: "Arial", value: "Arial" },
    { description: "Helvetica", value: "Helvetica" },
    { description: "Times New Roman", value: "Times New Roman" },
    { description: "Times", value: "Times" },
    { description: "Courier New", value: "Courier New" },
    { description: "Courier", value: "Courier" },
    { description: "Verdana", value: "Verdana" },
    { description: "Georgia", value: "Georgia" },
    { description: "Palatino", value: "Palatino" },
    { description: "Garamond", value: "Garamond" },
    { description: "Bookman", value: "Bookman" },
    { description: "Comic Sans MS", value: "Comic Sans MS" },
    { description: "Trebuchet MS", value: "Trebuchet MS" },
    { description: "Arial Black", value: "Arial Black" },
    { description: "Impact", value: "Impact" }
];



export const FONT_WEIGHTS = [
    { description: '100 - Thin', value: '100' },
    { description: '200 - Extra light', value: '200' },
    { description: '300 - Light', value: '300' },
    { description: '400 - Normal', value: '400' },
    { description: '500 - Medium', value: '500' },
    { description: '600 - Semi-bold', value: '600' },
    { description: '700 - Bold', value: '700' },
    { description: '800 - Extra bold', value: '800' },
    { description: '900 - Black', value: '900' }
]

export const WORD_BREAK = [
    { description: 'Normal', value: 'normal' },
    { description: 'Break word', value: 'break-word' },
    { description: 'Keep all', value: 'keep-all' },
    { description: 'Break all', value: 'break-all' }
]

export const OVERFLOW_WRAP = [
    { description: 'Normal', value: 'normal' },
    { description: 'Break word', value: 'break-word' },
    { description: 'Anywhere', value: 'anywhere' }
]



export const BREAKPOINTS = {
    mobile: {
        "min-width": 320,
        "max-width": 479
    },
    tablet: {
        "min-width": 480,
        "max-width": 991
    },
    desktop: {
        "min-width": 992,
        "max-width": 3840
    },
}



export const BACKEND_PATHS = {
    PROJECTS: () => 'projects',
    TEMPLATE_PROJECTS: () => 'template-projects',
    PAGES: (projectId) => `${projectId}/pages`,
    PAGE: {
        CREATE: (projectId) => `${projectId}/page`,
        UPDATE: (projectId, pageId) => `${projectId}/page/${pageId}`,
    },
    SIGN_UPLOAD: (projectId) => `${projectId}/sign-upload`,
    ASSETS: (projectId) => `${projectId}/assets`,
    ELEMENTS: (pageId) => `${pageId}/elements`,
    ELEMENTS_OPERATION: (pageId, operation) => `${pageId}/elements/${operation}`,
    ELEMENTS_GENERATE: (pageId) => `${pageId}/elements/generate`,
    AUTH_EMAIL_AND_PASSWORD: () => 'auth/email-and-password',
    AUTH_GOOGLE: () => 'auth/google',
    PROJECT_EXPORT: (projectId) => `${projectId}/export`,
    COMPONENT_CSS: (projectId) => `component-css/${projectId}`,
    SIGN_OUT: () => 'sign-out',
    COMPLETIONS_PUBLIC: () => 'completions-public',
    COMPLETIONS_PRIVATE: () => 'completions-private',
    COMPLETION_SET: () => 'completion-set',
    PROFILE: () => 'profile',
    CREATE_CHECKOUT_SESSION: () => 'create-checkout-session',
    CUSTOMER_PORTAL: () => 'customer-portal',
    RENDER_DJANGO_TEMPLATE: () => 'render-django-template',
    COMPLETION_SCREENSHOT: () => 'add-completion-screenshot',
};

