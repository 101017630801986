<template>
  <Switch
    :modelValue="modelValue"
    @update:modelValue="$emit('update:modelValue', $event)"
    :class="switchClass"
  >
    <span class="sr-only">Use setting</span>
    <span
      aria-hidden="true"
      :class="spanClass"
    />
  </Switch>
</template>

<script>
import { Switch } from '@headlessui/vue'
import { computed } from 'vue';

export default {
  props: ['modelValue'],
  components: {
    Switch
  },
  emits: ['update:modelValue'],
  setup(props) {
    const switchClass = computed(() => [
      props.modelValue ? 'bg-indigo-600' : 'bg-gray-200',
      'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2'
    ]);
    const spanClass = computed(() => [
      props.modelValue ? 'translate-x-5' : 'translate-x-0',
      'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
    ]);

    return {
      switchClass,
      spanClass
    }
  }
}
</script>
