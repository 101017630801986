<template>
  <div
      :id="pid"
      :style="finalStyle"
      class="button-element hintable"
      :class="{
        'editing': isEditing,
         'top-half-hint': isInsideAndIsGuide && !this.store.userActivity.drag.insertIntoGuide && inTopHalf,
         'bottom-half-hint': isInsideAndIsGuide && !this.store.userActivity.drag.insertIntoGuide && inBottomHalf
      }"
      :data-is-editing="isEditing"
      @click.stop="select()"
      @dblclick.stop="startEditing()"
      :contenteditable="isEditing"
      @input="updateInnerText(pid, $event)"
      @blur="stopEditing"
      @mouseover.stop="recordStartHover(pid)"
      @mouseleave.stop="recordEndHover()"
      ref="domElement"
  >
    {{ text }}
  </div>
</template>

<script>
import useRecordPageElementHover from "@/composables/useRecordPageElementHover.js";
import useEdit from "@/composables/useEdit";
import useElement from "@/composables/useElement";
import useRecordMouseover from "@/composables/useRecordMouseover";
import useDragAndDrop from "@/composables/useDragAndDrop";
import {computed, ref} from "vue";


export default {
  name: 'PageButton',
  props: {
    element: {
      type: Object,
      required: true,
    }
  },
  setup(props) {
    const domElement = ref(null)

    // Start standard page component setup.
    const { store, finalStyle, pid, select } = useElement(props.element)
    const { recordStartHover, recordEndHover } = useRecordPageElementHover();
    const {isOutside, isInside, inBottomHalf, inTopHalf } = useRecordMouseover(
        props.element, domElement
    )
    const { isDragging } = useDragAndDrop(props.element, domElement);

    const isInsideAndIsGuide = computed(() => {
      return isInside.value && store.userActivity.drag.guideElement?.pid === pid.value;
    })
    const showTopHalfHint = computed(() => {
      return isInsideAndIsGuide.value && !store.userActivity.drag.insertIntoGuide && inTopHalf.value
    })
    const showBottomHalfHint = computed(() => {
      return isInsideAndIsGuide.value && !store.userActivity.drag.insertIntoGuide && inBottomHalf.value
    })
    // End standard page component setup.


    const { isEditing, startEditing, stopEditing, updateInnerText } = useEdit();

    return {
      domElement,
      isEditing, startEditing, stopEditing, updateInnerText ,
      isInsideAndIsGuide, store,
      pid, finalStyle, select,
      recordStartHover, recordEndHover,
      isOutside, isInside,
      inBottomHalf, inTopHalf,
      isDragging
    };
  },
  computed: {
    text() {
      return this.element.text;
    },
  },
};
</script>
