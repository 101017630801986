import {defineStore} from 'pinia'
import {computed, ref} from "vue";
import {
    fetchPages, postPage, putPage
} from "@/utils/apiRequests";



export const useProjectStore = defineStore('project', () => {
    const pages = ref([])


    const home_page = computed(
        () => pages.value.find(page => page.is_home_page)
    )


    async function loadPages(projectId) {
        pages.value = await fetchPages(projectId)
    }

    async function createPage(projectId, pageData) {
        if (!projectId) {
            throw new Error('No project id provided');
        }
        if (Object.keys(pageData).length === 0) {
            throw new Error('No page data provided');
        }
        const newPage = await postPage(projectId, pageData);
        pages.value.push(newPage);
    }

    async function updatePage(projectId, pageId, pageData) {
        if (!projectId) {
            throw new Error('No project id provided');
        }
        if (!pageId) {
            throw new Error('No page id provided');
        }
        const updatedPage = await putPage(projectId, pageId, pageData);
        const index = pages.value.findIndex(page => page.id === updatedPage.id);
        pages.value[index] = updatedPage;
    }



    return {
        pages,
        home_page,
        loadPages,
        createPage,
        updatePage,
    }
})
