import {useCounterStore} from "@/stores/counter";

export const scrollToPid = (pid) => {
    const counterStore = useCounterStore()
    const domElement = counterStore.workFrame?.contentWindow.document.querySelector(
        `#${pid}`
    );
    if (domElement) {
        domElement.scrollIntoView({behavior: 'smooth', block: 'center'});
    } else {
        console.error(`scrollToPid :: Could not find element with pid ${pid}`);
    }
}


