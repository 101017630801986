<script>
import {VueFinalModal, useVfm} from 'vue-final-modal'
import IconComponent from "@/components/IconComponent.vue";


export default {
  components: {IconComponent, VueFinalModal},
  props: {
    showSavedPrompt: {
      type: Boolean,
      default: true
    },
  },
  setup() {
  },
  methods: {
    close() {
      this.vfm.close(this.modalId)
    }
  },
  computed: {
    userPrompt() {
      return sessionStorage.getItem("initial_user_prompt")
    }
  }
}
</script>


<template>
  <VueFinalModal
      class="flex justify-center items-center"
      content-class="flex flex-col max-w-xl mx-4 p-4 bg-white border dark:border-gray-700 rounded-lg space-y-2 select-none"
  >
    <h2 class="text-lg font-bold text-center">Sign in to generate</h2>

    <p class="text-center">
      Please sign in to generate UI for free with Photon Designer 💡</p>
    <p class="text-center" v-if="showSavedPrompt">
      (We've saved your <span class="tooltip tooltip-top underline decoration-dotted"
                              :data-tip="userPrompt">prompt</span> for you 🙂)
    </p>

    <router-link :to="{name: 'signIn'}" class="flex justify-center py-3">
      <button class="btn btn-outline btn-primary btn-block">
        <IconComponent name="sign-in" class="" :style="{'width': '20px'}"></IconComponent>

        Sign in
      </button>
    </router-link>

  </VueFinalModal>
</template>

<style>
</style>