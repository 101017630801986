<template>
  <div v-if="showing" ref="target" class="absolute flex items-center justify-center z-10">
    <div class="min-w-[75px]" @click.stop>

      <GenericInputNumUnit
          :css-value="numUnitAsCss"
          class="text-xs rounded-none select-none"
          @update:num-object="emitSpacingUpdate"
      />
      <GenericInputRange
          :max-value="200"
          :num="num"
          :unit="unit"
          class="cursor-pointer select-none"
          @update:num-object="emitSpacingUpdate"
      />
    </div>
  </div>
</template>

<script>
import {ref} from 'vue';
import {onClickOutside} from '@vueuse/core';
import GenericInputRange from "@/components/GenericInputRange.vue";
import GenericInputNumUnit from "@/components/GenericInputNumUnit.vue";

export default {
  components: {GenericInputNumUnit, GenericInputRange},
  emits: ['close-modal', 'update-spacing'],
  props: {
    showing: Boolean,
    num: String,
    unit: String
  },
  setup(props, {emit}) {
    const target = ref(null);
    onClickOutside(target, (event) => emit('close-modal'));

    const emitSpacingUpdate = (event) => {
      emit('update-spacing', event);
    };

    return {target, emitSpacingUpdate};
  },
  computed: {
    numUnitAsCss() {
      if (this.unit === 'auto') {
        return this.unit;
      } else {
        return this.num + this.unit;
      }
    }
  }

}
</script>

<style scoped>

</style>
