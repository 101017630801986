import { ref, watch } from 'vue';
import {useElementSize, useWindowSize} from '@vueuse/core';
import {useCounterStore} from "@/stores/counter";

export default function useDimensions(leftSidebarRef, rightSidebarRef) {
    /*
    We use this composable to set the dimensions of the Designer UI sections.

    Note: We may wish to move this elsewhere. It is used in one vue component.
     */
    const store = useCounterStore();

    store.dims.leftSidebar.width = useElementSize(leftSidebarRef).width;
    store.dims.leftSidebar.height = useElementSize(leftSidebarRef).height;

    store.dims.rightSidebar.width = useElementSize(rightSidebarRef).width;
    store.dims.rightSidebar.height = useElementSize(rightSidebarRef).height;

    // Set the browser dimensions reactively.
    store.dims.browser.width = useWindowSize({includeScrollbar: true}).width;
    store.dims.browser.height = useWindowSize({includeScrollbar: true}).height;

    // Set the workFrame height reactively.
    store.dims.workFrame.height = store.dims.browser.height

    watch(() => [store.dims.browser.height, store.workFrameScalingFactor], () => {
        /*
        We want to keep the workFrame height filling the screen when the user changes the scaling factor.
        To do this, we disapply the scaling factor, which we apply to scale the workFrame and its contents.

        We watch the browser height and the scaling factor. This causes an update whenever any
        resize occurs (affecting the scaling factor) or the browser height changes.
         */
        if (store.dims.navbar.height === 0) {
            throw new Error("The navbar height is 0. We have not incorrectly set the navbar height.")
        }
        const scaledWorkFrameHeight = store.dims.browser.height - store.dims.navbar.height
        store.dims.workFrame.height = scaledWorkFrameHeight / store.workFrameScalingFactor
    });
}
