import {useCounterStore} from "@/stores/counter";
import {useEventListener, useMagicKeys, whenever} from "@vueuse/core";
import {computed, ref, watch, watchEffect} from "vue";
import useDelete from "@/composables/useDelete";
import {duplicateElement} from "@/utils/elementCreator";


export default function useKeyboardShortcuts(contentWindow) {
    /*
    We use this composable to add keyboard shortcuts. The composable listens for
    keyboard shortcuts in the particular window that is passed in.
     */
    const store = useCounterStore()
    const {deleteWithUndo} = useDelete()
    const copiedElementPid = ref(null)

    const {cmd, backspace, shift, KeyZ, KeyY, ctrl, KeyC, KeyV, alt, current} = useMagicKeys({target: contentWindow})
    const {KeyD} = useMagicKeys({target: contentWindow, passive: false, onEventFired: (e) => {
            /*
            We only set passive to false when needed to override browser events
            because it can reduce performance.
             */
            if (e.metaKey && e.key === 'd' || e.ctrlKey && e.key === 'd') {
                e.preventDefault();
            }
        }
    })


    // Undo.
    watchEffect(() => {
        if (cmd.value && KeyZ.value || ctrl.value && KeyZ.value) {
            store.undo()
        }
    })

    // Redo.
    watchEffect(() => {
        if (cmd.value && KeyY.value || ctrl.value && shift.value && KeyZ.value) {
            store.redo()
        }
    })

    // Delete.
    watchEffect(() => {
        if (backspace.value) {
            if (store.selectedElementPid === null) {return}
            if(isUserEditingText(contentWindow, store)){return}

            deleteWithUndo(store.selectedElementPid)
        }
    })

    // Duplicate.
    watchEffect(() => {
        if (cmd.value && KeyD.value || ctrl.value && KeyD.value) {
            duplicateElement(store.selectedElementPid, store)
        }
    })


    // Copy element.
    watchEffect(() => {
        if (cmd.value && KeyC.value || ctrl.value && KeyC.value) {
            if (isUserEditingText(contentWindow, store)) {
                return
            }
            else if (store.selectedElementPid) {
                copiedElementPid.value = store.selectedElementPid
            }
        }

    })
    // Paste element.
    const shouldPaste = computed(() =>
        (cmd.value && KeyV.value || ctrl.value && KeyV.value) &&
        !isUserEditingText(contentWindow, store) &&
        copiedElementPid.value !== null
    );

    // Use watch to trigger the paste operation based on the computed condition.
    watch(shouldPaste, (newVal) => {
        if (newVal) {
            duplicateElement(copiedElementPid.value, store);
        }
    });

    // Detect alt/option key press. We use this to allow the user to edit two css properties at once.
    watch(alt, (newValue, oldValue) => {
        if (newValue && !oldValue) {
            store.userActivity.pressingKeys.alt = true
        } else if (!newValue && oldValue) {
            store.userActivity.pressingKeys.alt = false
        }
    });


    return {
        backspace,
    }
}


function isUserEditingText(contentWindow, store){
    /*
   Check if the user is editing component text.
   We check this to detect if we should not trigger keyboard shortcuts.
     */
    const domElement = contentWindow.document.getElementById(store.selectedElementPid);
    return isComponentEditing(domElement) || isUserTypingInElementEditor(document.activeElement);
}

function isComponentEditing(domElement) {
    return domElement?.dataset?.isEditing === 'true';
}

function isUserTypingInElementEditor(activeElement) {
    return ["INPUT", "TEXTAREA", "SELECT"].includes(activeElement?.tagName);
}

