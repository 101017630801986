<template>
  <VueFinalModal
      :modal-id="modalId"
      class="confirm-modal"
      content-class="confirm-modal-content"
      overlay-transition="vfm-fade"
      content-transition="vfm-fade"
  >
    <section id="container">


      <div id="prompt-container">
        <header class="text-center mb-1">
          Describe your component
        </header>

        <div id="inner-wrapper">
          <div id="prompt-ideas-container">
          <span v-for="idea in promptIdeas" :key="idea.name" class="prompt-idea">
            <button @click="input = idea.prompt">
              {{ idea.name }}
            </button>
          </span>

          </div>
          <textarea
              ref="textarea"
              id="prompt-textarea"
              v-model="input"
              class="resize-none"
              placeholder="Describe your component here..."
          />
        </div>

        <div class="flex justify-center py-1">
          <button v-show="!isGenerating" class="btn" @click="generate()" :disabled="isGenerating">
            <span>Generate</span>
          </button>
          <div v-show="isGenerating" class="flex flex-col items-center">
            <IconComponent name="spinner-animated" :style="{'width': '50px'}"></IconComponent>
            <div>Generating</div>
          </div>
        </div>


        <div v-show="isGenerating" class="flex items-center flex-col">
          <div>
          </div>
        </div>
      </div>

      <div id="preview-container">
        <header class="text-center mb-1">
          <span v-show="!generationsStore.completion.name">Preview</span>
          <span v-show="generationsStore.completion.name">Your {{ generationsStore.completion.name }}</span>

        </header>
        <iframe id="completion-iframe" class="w-full rounded-lg" :srcdoc="iframeContent"></iframe>

        <div class="flex justify-center py-1">
          <div class="btn" v-show="!isInserting" @click="[isInserting ? null : insert()]">
            <span>Add into your page</span>
          </div>
          <div v-show="isInserting" class="flex flex-col items-center">
            <span><IconComponent name="spinner-animated" :style="{'width': '50px'}"></IconComponent></span>
            <div>Adding component</div>
          </div>
        </div>
      </div>
    </section>

  </VueFinalModal>
</template>

<script>
import {VueFinalModal, useVfm} from 'vue-final-modal'
import {useCompletionsStore} from "@/stores/completions";
import {computed, nextTick} from "vue";
import {useCounterStore} from "@/stores/counter";
import {useRoute} from "vue-router";
import {scrollToPid} from "@/utils/workFrameUtils";
import {useTextareaAutosize, useFocus} from '@vueuse/core'
import {useToast} from "vue-toastification";
import {generateUI, insertCompletion} from "@/utils/apiRequests";
import IconComponent from "@/components/IconComponent.vue";


export default {
  components: {IconComponent, VueFinalModal},
  setup() {
    const route = useRoute();
    const projectId = route.params.projectId
    const pageId = route.params.pageId
    const generationsStore = useCompletionsStore();
    const counterStore = useCounterStore();

    const toast = useToast()

    const {textarea, input} = useTextareaAutosize()
    const {focused} = useFocus(textarea, {initialValue: true})

    const vfm = useVfm()
    const modalId = Symbol('modalId')


    const iframeContent = computed(() => generationsStore.completion.html)


    return {
      generationsStore,
      iframeContent,
      vfm, modalId,
      counterStore, pageId, projectId,
      textarea,
      input,
      focused,
      toast,
    }
  },
  data: () => ({
    promptIdeas: [
      {
        'name': 'AirBnB Card',
        'prompt': 'Create a card component drawing inspiration from Airbnb\'s listing cards. This should include a high-quality image area at the top, a title section for the property name, a concise description, and clear pricing information. Include icons for amenities like WiFi or parking availability.'
      },
      {
        'name': 'Pricing section',
        'prompt': 'Create a pricing table that displays different pricing tiers and features. It should include a call-to-action button'
      },
      {
        'name': 'Hero Section',
        'prompt': 'Create a hero section for a landing page. It should include a large heading, a subheading, and a call-to-action button'
      }
    ],
    isGenerating: false,
    isInserting: false,
  }),
  methods: {
    async generate() {
      if (!this.input) {
        this.toast.error('Please describe your component')
        return
      }
      this.isGenerating = true
      const data = await generateUI(this.pageId, this.input)
      this.generationsStore.completion.name = data.name
      this.generationsStore.completion.html = data.html
      this.generationsStore.completion.id = data.completion_id
      this.isGenerating = false
    },
    async insert() {
      /*
      We save the completion elements to the database and then insert
      the elements into the page.
       */
      this.isInserting = true
      const newElementsData = await insertCompletion(
          this.pageId, this.generationsStore.completion.id
      )
      this.counterStore.addPageElementsFromJson(newElementsData)
      await nextTick()


      // Set the top element as the selected element.
      const topElementPid = newElementsData[0].pid
      this.counterStore.setSelectedElementPid(topElementPid)

      // Scroll to the selected element in the workFrame.
      scrollToPid(topElementPid)

      this.close()
      this.isInserting = false
    },
    close() {
      this.vfm.close(this.modalId)
    }
  }
}
</script>


<style>
.confirm-modal {
  display: flex;
  justify-content: center;
  align-items: center;
}

.confirm-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  background: #fff;
  border-radius: 0.5rem;
  width: 80%;
  min-height: 70%;
}

.confirm-modal-content > * + * {
  margin: 0.5rem 0;
}

.confirm-modal-content h1 {
  font-size: 1.375rem;
}

.confirm-modal-content .btn {
  padding: 0 8px;
  border: 1px solid;
  border-radius: 0.5rem;
}

.dark .confirm-modal-content {
  background: #000;
}

#prompt-container #inner-wrapper {
}

#prompt-ideas-container {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 1rem;
  overflow-x: auto;
  width: 100%;
}

.prompt-idea {
  padding: 0.5rem;
  display: flex;
  align-items: center;
  font-size: smaller;
  background: #f3f4f6;
  color: #4b5563;
  border-radius: 0.5rem;
  white-space: nowrap;
}

.prompt-idea:hover {
  background: #e5e7eb;
}


.confirm-modal #container {
  display: grid;
  grid-template-columns: 25% 75%;
  width: 100%;

}


.confirm-modal #completion-iframe {
  width: 100%;
  min-height: 50vh;
  border: 1px dashed #ccc;
}


.confirm-modal #prompt-container {
  padding: 0 .5rem;
  display: flex;
  flex-direction: column;
  gap: .25rem;
}

.confirm-modal #prompt-textarea {
  padding: 0.5rem;
  min-height: 100%;
  width: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.confirm-modal #prompt-textarea::-webkit-scrollbar {
  display: none;
}

.confirm-modal .spin {
  animation: spinning 0.7s linear infinite;
}

@keyframes spinning {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


</style>