<script>
import {defineComponent, nextTick, ref} from 'vue'
import {streamCompletion, handleStream, getCompletionsPublic, renderDjangoTemplate} from "@/utils/apiRequests";
import IconComponent from "@/components/IconComponent.vue";
import {useCompletionsStore} from "@/stores/completions";
import {renderHTML} from "@/utils/completionUtils";
import {useToast} from "vue-toastification";



export default defineComponent({
  components: {IconComponent},
  props: {
    completion: {
      type: Object,
      required: true
    },
    showImage: {
      // Show image instead of iframe.
      type: Boolean,
      default: false
    }
  },
  setup() {
    const iframe = ref(null);

    const completionsStore = useCompletionsStore()

    return {
      iframe,
      toast: useToast(),
      store: completionsStore,
      completions: completionsStore.completions,
      setCompletion: completionsStore.setCompletion,
      selectedCompletion: completionsStore.selectedCompletion,
    }
  },
  data() {
    return {
      isGenerating: false,
      hasOpenTag: false,
      streamedChunks: "",
    }
  },
  computed: {
    completionId() {
      if (!this.completion.id) {
        throw new Error("Completion :: No completion id.")
      }
      if (!this.completion.hasOwnProperty('has_finished')) {
        throw new Error("Completion :: No has_finished property.")
      }

      return this.completion.id
    },
    completionText() {
      return this.completion.data
    },
    hasContent() {
      return this.$refs.iframe?.contentDocument.body.innerHTML !== ""
    },
  },
  async mounted() {
    this.renderIframe()
    await this.getOrStream()
  },
  methods: {
    renderIframe() {
      const iframeDoc = this.iframe?.contentDocument;
      if (!iframeDoc) {
        if (!import.meta.env.PROD) {
          console.info('𝌗 Iframe not loaded yet')
        }
        return
      }
      iframeDoc.open();
      iframeDoc.write('' +
          '<!DOCTYPE html>' +
          '<html lang="en">' +
          '<head>' +
          '<meta charset="UTF-8">' +
          '<meta name="viewport" content="width=device-width, initial-scale=1.0">' +
          '<title>Completion</title>' +
          '<script>' +
          'originalWarn = console.warn;' +
          'console.warn = function(message, ...options) {' +
          'if (message.includes("cdn.tailwindcss.com should not be used in production")) {' +
          'return;' +
          '}' +
          'originalWarn(message, ...options);' +
          '};' +
          '</' +
          'script>' +
          '<script src="https://cdn.tailwindcss.com"><' +
          '/script>' +
          '</head>' +
          `<body>${this.completion.data}</body>` +
          '</html>'
      );
    },
    async getOrStream() {
      /*
      Get the completion if it is complete, otherwise stream it.
       */
      if (this.completion.has_finished) {
        this.renderIframe()
      } else {
        this.store.setIsGenerating(true)
        await this.stream()
        this.store.setIsGenerating(false)
        this.toast.success("Generation complete ✅", {duration: 500, position: "bottom-right"})
        this.scrollToIframeBottom()
      }
    },

    async stream() {
      const payload = {completion_id: this.completionId, operation: "generate"};
      const response = await streamCompletion(payload)
      await handleStream(response, chunk => {
        this.writeOnlyHtml(chunk)
      })
    },
    // Todo: Move this to backend.
    writeOnlyHtml(chunk) {
      if (chunk.includes("<")) {
        this.hasOpenTag = true
        this.streamedChunks += chunk
        this.writeIframeBody(this.streamedChunks)
        this.setCompletion(this.completionId, 'data', this.streamedChunks)

      } else if (this.hasOpenTag) {
        this.streamedChunks += chunk
        this.writeIframeBody(this.streamedChunks)
        this.setCompletion(this.completionId, 'data', this.streamedChunks)
      }
    },
    async insertHTML() {
      if (this.completion.active_template_engine === 'django') {
        const output = await renderHTML(this.completion, "django")
        this.completion.renderingError = output.error
        if (!output.error) {
          this.writeIframeBody(output.text)
        } else {
          this.writeIframeBody(this.completion.data)
        }
      } else {
        this.completion.renderingError = ''
        this.writeIframeBody(this.completion.data)
      }
    },

    writeIframeBody(html) {
      /*
      We use this method to write to the iframe's body once it is loaded.
       */
      const iframeBody = this.$refs.iframe?.contentDocument?.body;
      if (iframeBody) {
        iframeBody.innerHTML = html
      }
    },

    scrollToIframeBottom() {
      const iframe = this.$refs.iframe;
      if (iframe) {
        const iframeWindow = iframe.contentWindow;
        if (iframeWindow) {
          iframeWindow.scrollTo(0, iframeWindow.document.body.scrollHeight);
        }
      }
    }
  },
  watch: {
    completionId: {
      handler() {
        // We must do this manually because the iframe is not reactive.
        this.renderIframe()

      },
      deep: true
    },
    completionText: {
      async handler(newValue, oldValue, keyPath) {
        /*
        We write the completion's data to the iframe's body whenever
        there are changes.
         */
        await this.insertHTML()
      },
      deep: true
    },
  },
})


</script>


<template>
  <iframe ref="iframe" class="flex-1 border-2 border-dashed"></iframe>

  <div>
    <div v-show="!hasContent" class="flex flex-col gap-4 w-52">
      <div class="flex gap-4 items-center">
        <div class="skeleton w-16 h-16 rounded-full shrink-0"></div>
        <div class="flex flex-col gap-4">
          <div class="skeleton h-4 w-20"></div>
          <div class="skeleton h-4 w-28"></div>
        </div>
      </div>
      <div class="skeleton h-32 w-full"></div>
    </div>
  </div>

</template>

<style scoped>

</style>