<template>
  <NavBar style="background: rgb(17 24 39);" class="text-zinc-300"/>
  <div class="isolate overflow-hidden bg-gray-900">
    <div class="mx-auto max-w-7xl px-6 pb-96 text-center sm:pt-2 lg:px-8">
      <div class="mx-auto max-w-4xl">
        <p class="mt-2 text-4xl font-bold tracking-tight text-white sm:text-5xl">
          Create your frontend in seconds
        </p>
      </div>
      <div class="relative mt-2">
        <p class="mx-auto max-w-2xl text-lg leading-8 text-white/60 mt-4">
          Let's make your product vision come true
          <br>
          Choose the plan that fits you
        </p>
        <svg viewBox="0 0 1208 1024"
             class="absolute -top-10 left-1/2 -z-10 h-[64rem] -translate-x-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:-top-12 md:-top-20 lg:-top-12 xl:top-0">
          <ellipse cx="604" cy="512" fill="url(#6d1bd035-0dd1-437e-93fa-59d316231eb0)" rx="604" ry="512"/>
          <defs>
            <radialGradient id="6d1bd035-0dd1-437e-93fa-59d316231eb0">
              <stop stop-color="#7775D6"/>
              <stop offset="1" stop-color="#E935C1"/>
            </radialGradient>
          </defs>
        </svg>
      </div>
    </div>
    <div class="flow-root bg-white pb-10">
      <div class="-mt-80">
        <div class="mx-auto max-w-7xl px-6 lg:px-8">
          <div class="mx-auto grid max-w-md grid-cols-1 gap-8 lg:max-w-4xl lg:grid-cols-2">
            <PricingCard
                title="Beginner" price="Free" interval=""
                :features="['50 generations']"
                :existing-subscription=existingSubscription
            />
            <PricingCard
                title="Professional" price="$19"
                stripePriceLookupKey="pro_monthly"
                :features="[
                    '2000 generations',
                    'Private generations - Only you can see your generated code',
                    'Early access to new features', 'Priority support - we\'ll get back to you faster than fiber internet on a bullet train 🚅', ]"
                @update:buy="postCheckoutSession($event.stripePriceLookupKey)"
                :existing-subscription=existingSubscription
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <footer class="footer footer-center py-2 text-base-content absolute bottom-0">
    <nav class="grid grid-flow-col gap-4">
      <p>Photon Designer ©️2024</p>
    </nav>

  </footer>

</template>


<script>

import IconComponent from "@/components/IconComponent.vue";
import ProjectCreator from "@/components/ProjectCreator.vue";
import {
  signOut, getCompletionsPublic, postCompletion,
  createCheckoutSession,
  getProfile
} from "@/utils/apiRequests";
import {useToast} from "vue-toastification";
import NavBar from "@/components/NavBar.vue";
import {useModal} from "vue-final-modal";
import ModalBeforeCompletion from "@/components/ModalBeforeCompletion.vue";
import {useAuthUtils} from "@/composables/useAuthUtils";
import PricingCard from "@/components/PricingCard.vue";


export default {
  components: {PricingCard, NavBar, IconComponent, ProjectCreator},
  setup() {
    const {isSignedIn} = useAuthUtils()
    return {
      toast: useToast(),
      isSignedIn: isSignedIn
    }
  },
  data() {
    return {
      profile: {},
    }
  },
  async mounted() {
    this.profile = await getProfile()
  },
  methods: {
    async postCheckoutSession(priceLookupKey) {
      /*
      Create a checkout session and redirect the user to the checkout page
      for the selected priceLookupKey.
       */
      if (!priceLookupKey) {
        throw new Error("Price lookup key is required. Got: ", priceLookupKey)
      }
      if (!this.isSignedIn) {
        this.$router.push({name: 'signUp'})
      }
      const {url} = await createCheckoutSession({
        price_lookup_key: priceLookupKey
      })

      if (!url.startsWith('http')) {
        this.toast.error(
            "Failed to create checkout session. Please contact support and we'll help you out asap.",
            {
              timeout: 5000,
            }
        )
      } else {
        window.location.href = url

      }
    }
  },
  computed: {
    existingSubscription() {
      return this.profile.is_subscribed ? 'Professional' : 'Beginner'
    }
  }
}

</script>

<style scoped>


.version-wrapper {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  display: flex;
  flex: 1;
  flex-direction: column-reverse;
  overflow: auto;
}

.version-wrapper::-webkit-scrollbar {
  display: none;
}


.version-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.version-container .content {
  position: relative;
  display: flex;
  height: 100%;
  min-height: 25px;
  width: 100%;
  min-width: 40px;
  cursor: pointer;
  border-radius: 4px;
  outline: none;
}

.version-container .frame-container {
  position: relative;
  display: flex;
  width: 100%;
  aspect-ratio: 16 / 9;
  overflow: hidden;
  border-radius: 4px;
  border: 1px solid #d2d2d2;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

.version-container .frame-container:hover {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
}

.version-container .frame {
  height: 100%;
  width: 100%;
}

.version-container .iframe-container {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.version-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 1150px;
  height: 576px;
  transform-origin: top left;
  transform: scale(0.2);
  background: white;
  opacity: 1;
  pointer-events: none;
}


#headline-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px;
  font-size: 24px;
  font-weight: bold;
  min-width: 60%;
}

#generation-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}

#prompt-textarea {
  min-width: 500px;
  min-height: 100px;
  margin: 20px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #575757;
  border-radius: 5px;
  resize: none;
  outline: none;
}

#generate-button {
  padding: 10px 20px;
  max-width: fit-content;
  font-size: 16px;
  background-color: #001e00;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

#generate-button:hover {
  background-color: #1f3600;
  transition: background-color 0.2s ease-out;
}


.main-content {
  display: flex;
  padding: 20px;
}

.sidebar {
  width: 200px;
  background-color: #fff;
  padding: 20px;
  margin-right: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.sidebar-link {
  text-decoration: none;
  color: #333;
  display: block;
  padding: 10px 0;
}


.site-card-image {
  cursor: pointer;
  scale: 0.5;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: #e9e9e9;;
  font-weight: bold;

  transition: hover 0.5s ease-in-out;

}


.creator-details {
  display: flex;
  gap: .5rem;
  justify-content: left;
  align-items: center;
  font-size: 14px;
  margin: 0;
  color: #666;
}

.site-view-project {
  cursor: pointer;
  text-decoration: underline;
}

.site-view-project:hover {
  color: #6c6c6c;
}


</style>