<template>
  <div class="wrapper">
    <div v-for="(option, index) in options" :key="option.value"
         :class="option.class"
    >
      <input
          class="hidden"
          type="radio" :id="name + '-' + option.value"
          :value="option.value"
          :checked="currentValue === option.value"
          v-on:click="$emit('update:value', option.value)"
      >
      <label
          :for="name + '-' + option.value"
          :class="{ 'selected': currentValue === option.value }"
      >

        <Icon v-if="option.icon" :icon="option.icon"></Icon>
        <span v-if="option.description" class="text-xs whitespace-nowrap">{{ option.description }}</span>
        <span><IconComponent v-if="option.iconComponent" :name="option.iconComponent" :style="option?.iconComponentStyle" width="16"/></span>

      </label>
    </div>
  </div>
</template>

<script>
import {Icon} from "@iconify/vue"; // An external icon.
import IconComponent from "@/components/IconComponent.vue"; // A local icon.

export default {
  name: 'GenericInputRadioGroup',
  emits: ['update:value'],
  components: {IconComponent, Icon},
  props: {
    name: {
      type: String,
      required: true,
    }, // We use this to group the radio buttons.
    options: {
      type: Array,
      required: true,
    },
    currentValue: {
      type: [String, Boolean],
    },
  },
  watch: {
    currentValue(val) {
      if (val && !this.options.map(o => o.value).includes(val)) {
        console.warn(`With options ${this.options}, and name ${this.name},  the prop "${val}" doesn't exist in the options array`);
      }
    },
  }
};
</script>


<style scoped>
.wrapper {
  border: 1px solid transparent;
  border-radius: 4px;
  user-select: none;
}
input[type="radio"].hidden {
  position: absolute;
  visibility: hidden;
}

label {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 18px;
  padding: 2px 0;
  color: #ffffff;
  background-color: #6b6a6a;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

label:hover {
  background-color: #818181;
}

label.selected {
  background-color: #b4b4b4;
  border-color: #232323;
  color: #fff;
}

</style>
