import _ from 'lodash';
import {generateUUID} from '@/uuid.js';
import PageRoot from "@/components/element_components/PageRoot.vue";
import PageButton from "@/components/element_components/PageButton.vue";
import PageImage from "@/components/element_components/PageImage.vue";
import PageDiv from "../components/element_components/PageDiv.vue";
import PageText from "@/components/element_components/PageText.vue";
import PageForm from "@/components/element_components/PageForm.vue";
import PageFormInput from "@/components/element_components/PageFormInput.vue";
import PageFormLabel from "@/components/element_components/PageFormLabel.vue";
import PageFormState from "@/components/element_components/PageFormState.vue";
import PageFormSubmitButton from "@/components/element_components/PageFormSubmitButton.vue";

import useUndoRedo from "@/composables/useUndoRedo";
import useDelete from "@/composables/useDelete";
import PageLinkBlock from "@/components/element_components/PageLinkBlock.vue";
import sharedConstants from "../../../shared_constants.json";
import PageNavBar from "@/components/element_components/PageNavBar.vue";
import {markRaw} from "vue";
import PageInputContainer from "@/components/element_components/PageInputContainer.vue";
import {Store} from "@/interfaces";
import PageInput from "@/components/element_components/PageInput.vue";


export enum ComponentName {
    PageRoot = 'PageRoot',
    PageButton = 'PageButton',
    PageImage = 'PageImage',
    PageDiv = 'PageDiv',
    PageText = 'PageText',
    PageForm = 'PageForm',
    PageFormState = 'PageFormState',
    PageFormSubmitButton = 'PageFormSubmitButton',
    PageFormInput = 'PageFormInput',
    PageInputContainer = 'PageInputContainer',
    PageInput = 'PageInput',
    PageFormLabel = 'PageFormLabel',
    PageLinkBlock = 'PageLinkBlock',
    PageNavBar = 'PageNavBar',
}

const vueComponentMap = {
    /*
    We use this mapping to connect the element's name to its vue component.
     */
    "PageRoot": markRaw(PageRoot),
    "PageButton": markRaw(PageButton),
    "PageImage": markRaw(PageImage),
    "PageDiv": markRaw(PageDiv),
    "PageText": markRaw(PageText),
    "PageForm": markRaw(PageForm),
    "PageFormInput": markRaw(PageFormInput),
    "PageInputContainer": markRaw(PageInputContainer),
    "PageInput": markRaw(PageInput),
    "PageFormLabel": markRaw(PageFormLabel),
    "PageFormState": markRaw(PageFormState),
    "PageFormSubmitButton": markRaw(PageFormSubmitButton),
    "PageLinkBlock": markRaw(PageLinkBlock),
    "PageNavBar": markRaw(PageNavBar),
};

export function createPageComponentsFromBackend(backendElements: any[]): ComponentName[] {
    /*
    We use this to create the components from the backend elements.
    We must do this to translate the backend elements to frontend elements, e.g., vue components.
     */
    return _.map(backendElements, backendEl => {

        if (!sharedConstants.COMPONENTS_DATA[backendEl.component_name]) {
            throw new Error(
                `No component data found for ${backendEl.component_name} in shared_constants`
            );
        }

        return {
            ...backendEl,
            component: vueComponentMap[backendEl.component_name],
            navigatorIcon: sharedConstants.COMPONENTS_DATA[backendEl.component_name].navigatorIcon
        };
    });
}


export function addPageComponent(
    componentName: string,
    pageId: number,
    store: Store,
    otherProperties: Record<string, any> = {}
): void {
    /*
    We use this as an entry point for creating any new component.

    This also allows us to create composite components (components containing other components).
     */
    const properties = {
        component_name: componentName,
        parentPid: store.rootElementPid,
        page_id: Number(pageId),
        ...otherProperties
    }
    if (componentName === ComponentName.PageForm) {
        addPageForm(properties, store)
    }
    if (componentName === ComponentName.PageInputContainer) {
        addInputContainer(properties, store)
    } else {
        createElement(properties, store)
    }
}

export function createElement(properties: Record<string, any>, store: Store): Record<string, any> {
    /*
    Creates and connects any new element to the store.
    We use properties to set the initial values of the element.
     */
    const {doWithUndo} = useUndoRedo()
    const {deleteElement, undeleteElement} = useDelete()

    const {component_name, parentPid, page_id} = properties

    if (!component_name || !parentPid || !page_id) {
        throw new Error(`properties ${JSON.stringify(properties)} must have at least a component_name, parentPid, and page_id`)
    }
    if (typeof page_id !== "number") {
        throw new Error(`projectId ${page_id} must be a number`)
    }

    const newPageElement = initPageElement(properties, store)

    const forward = () => {
        /*
        We add the element if it doesn't exist. If it does exist, we undelete it.
        We do this, rather than just removing the element, so that the user can undo and
        get the element back with its state.
         */
        if (!store.hasElement(newPageElement.pid)) {
            store.addElement(newPageElement, parentPid)
        } else {
            undeleteElement(newPageElement.pid)
        }
        store.setSelectedElementPid(newPageElement.pid!)
    }

    const reverse = () => {
        deleteElement(newPageElement.pid)
    }

    doWithUndo(forward, reverse)

    return newPageElement
}

export function duplicateElement(pid: string, store: Store): Record<string, any> {
    /*
    Duplicates an element. We copy all properties except those that are unique to each element.
     */
    if (typeof pid !== "string") {
        throw new Error(`pid must be a string. Currently it is an ${typeof (pid)}`)
    }

    const originalElement = store.findElementByPid(pid);
    if (!originalElement) {
        throw new Error(`No element found with pid: ${pid}`);
    }

    // Start duplication from the target element.
    return _duplicateRecursive(originalElement, originalElement.parentPid, store);
}

const _duplicateRecursive = (
    originalElement: Record<string, any>, newParentPid: string, store: Store
): Record<string, any> => {
    /*
    We duplicate the original element and duplicate all its children.
     */
    if (typeof newParentPid !== "string") {
        throw new Error(`newParentPid must be a string. Currently it is an ${typeof (newParentPid)}`)
    }
    const propertiesNotToCopy = ['pid', 'db_id', 'component', 'order', 'is_deleted'];
    const targetProperties = _.omit(originalElement, propertiesNotToCopy);
    targetProperties["parentPid"] = newParentPid; // Set new parent for the duplicated element.

    const duplicatedElement = createElement(targetProperties, store);

    // Duplicate all child elements and set the new parent.
    const originalChildren = store.getActiveChildElements(originalElement.pid);
    originalChildren.forEach(
        child => _duplicateRecursive(child, duplicatedElement.pid, store)
    )

    return duplicatedElement;
};


function initPageElement(properties: Record<any, string>={}, store: Store): Record<string, any>{
    const {component_name, parentPid, page_id} = properties
    if (!component_name || !parentPid || !page_id) {
        throw new Error(`properties ${properties} must have component_name, parentPid, and page_id`)
    }

    const defaults = {
        pid: createPid(component_name),
        db_id: null, // Not yet saved to the database.
        depth: 0,
        order: store.activeElements.filter(element => element.parentPid === parentPid).length,
        component_name: component_name,
        parentPid: parentPid,
        hasNest: false,
        is_deleted: false,
        isNestOpen: false,
        css_desktop: {},
        css_mobile: {},
        css_tablet: {},
        page_id: page_id,
        component: vueComponentMap[component_name],
        navigatorIcon: sharedConstants.COMPONENTS_DATA[component_name].navigatorIcon,
    }
    const updatedDefaults = addComponentSpecificProperties(defaults)

    return {...updatedDefaults, ...properties}
}

function createPid(name: string): string{
    return `${_.kebabCase(name)}-${generateUUID()}`
}

function addComponentSpecificProperties(element: Record<any, any>): object {
    /*
    Adds component-specific properties to an element.
     */
    const specificInitProperties = sharedConstants.COMPONENTS_DATA[element.component_name].init_properties
    if (!specificInitProperties) {
        throw new Error(`No init_properties defined for ${element.component_name}`)
    }
    return {...element, ...specificInitProperties}
}


// ----------- Composite components --------------- //
// Composite component == a component that contains other components



export function addPageForm(properties: Record<string, any>, store: Store): Record<string, any> {
    /*
    When creating a form, we add the states of normal, success, and error.
     */
    const form = createElement({...properties, form_showing_state: "normal"}, store)

    // Create normal state.
    const normal = createElement(
        {
            component_name: ComponentName.PageFormState, parentPid: form.pid, page_id: properties.page_id,
            form_state: "normal"
        },
        store
    )
    const field_one_label = createElement(
        {
            component_name: ComponentName.PageFormLabel, parentPid: normal.pid, page_id: properties.page_id,
            text: "Your name"
        },
        store
    )
    const field_one_input = createElement(
        {
            component_name: ComponentName.PageFormInput, parentPid: normal.pid, page_id: properties.page_id,
            form_input_placeholder: "Jimmy Donaldson", form_input_name: "name", form_input_type: "text"

        },
        store
    )
    const field_two_label = createElement(
        {
            component_name: ComponentName.PageFormLabel, parentPid: normal.pid, page_id: properties.page_id,
            text: "Your email"
        },
        store
    )
    const field_two_input = createElement(
        {
            component_name: ComponentName.PageFormInput, parentPid: normal.pid, page_id: properties.page_id,
            form_input_placeholder: "jimmy@beast.com", form_input_name: "email", form_input_type: "email"

        },
        store
    )

    const submitButton = createElement(
        {
            component_name: ComponentName.PageFormSubmitButton, parentPid: normal.pid, page_id: properties.page_id,
            text: "Submit"
        },
        store
    )

    // Create success state.
    const success = createElement(
        {
            component_name: ComponentName.PageFormState, parentPid: form.pid, page_id: properties.page_id,
            form_state: "success"
        },
        store
    )
    const successText = createElement(
        {
            component_name: ComponentName.PageText, parentPid: success.pid, page_id: properties.page_id,
            text: "Successfully submitted form ✅"
        },
        store
    )

    // Create error state.
    const error = createElement(
        {
            component_name: ComponentName.PageFormState, parentPid: form.pid, page_id: properties.page_id,
            form_state: "error"
        },
        store
    )
    const errorText = createElement(
        {
            component_name: ComponentName.PageText, parentPid: error.pid, page_id: properties.page_id,
            text: "Error submitting your form ❌"
        },
        store
    )

    return form
}

export function addInputContainer(properties: Record<string, any>, store: Store): Record<string, any> {
    /*
    We create an input container with a label and input field.
     */
    // Todo: Check properties for form_input_type

    const input_container = createElement(
        {...properties, component_name: ComponentName.PageInputContainer}, store
    )

    if (!input_container.form_input_type) {
        throw new Error("properties must have a form_input_type")
    }

    const label = createElement(
        {
            component_name: ComponentName.PageFormLabel,
            parentPid: input_container.pid,
            page_id: input_container.page_id,
            text: _.capitalize(input_container.form_input_type),
        },
        store
    )
    const input = createElement(
        {
            component_name: ComponentName.PageInput,
            parentPid: input_container.pid,
            page_id: input_container.page_id,
            form_input_placeholder: input_container.form_input_placeholder,
            form_input_name: input_container.form_input_name,
            form_input_type: input_container.form_input_type
        },
        store
    )

    return input_container
}

