import {defineStore} from 'pinia'
import {computed, ref} from "vue";

export const useAssetsStore = defineStore('assets', () => {
    const assets = ref([])

    const assetsForDisplay = computed(() => {
        const withoutPlaceholders =  assets.value.filter(asset => asset.is_placeholder === false)
        const inAscendingAge = withoutPlaceholders.reverse()
        return inAscendingAge
    })

    const getAsset = (key) => {
        return assets.value.find(asset => asset.key === key)
    }

    return {
        assets,
        getAsset,
        assetsByAscendingAge: assetsForDisplay,
    }
})
