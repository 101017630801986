<template>
  <div
      :id="pid"
      class="hintable"
      :class="{
          'editing': isEditing,
          'top-half-hint': isInsideAndIsGuide && !this.store.userActivity.drag.insertIntoGuide && inTopHalf,
          'bottom-half-hint': isInsideAndIsGuide && !this.store.userActivity.drag.insertIntoGuide && inBottomHalf
        }"
      style="user-select: none;"
      :style="finalStyle"
      @click.stop="select()"
      @dblclick.stop="smoothSelectOrEdit()"
      ref="domElement"
      :data-is-editing="isEditing"
      @blur.self="stopEditing()"
      :contenteditable="isEditing"
      @input="updateInnerText(pid, $event)"
      @mouseover.stop="recordStartHover(pid)"
      @mouseleave.stop="recordEndHover()"
  >
    {{ text }}
  </div>
</template>

<script>


import useRecordPageElementHover from "@/composables/useRecordPageElementHover.js";
import useEdit from "@/composables/useEdit";
import useElement from "@/composables/useElement.js";
import {computed, nextTick, ref} from "vue";
import useRecordMouseover from "@/composables/useRecordMouseover";
import useDragAndDrop from "@/composables/useDragAndDrop";


export default {
  name: 'PageText',
  props: {
    element: {
      validator: function (value) {
        return (
            value.hasOwnProperty('text')
        );
      },
    },
  },
  setup(props) {
    const domElement = ref(null)

    // Start standard page component setup.
    const { store, finalStyle, pid, select } = useElement(props.element)
    const { recordStartHover, recordEndHover } = useRecordPageElementHover();
    const {isOutside, isInside, inBottomHalf, inTopHalf } = useRecordMouseover(
        props.element, domElement
    )
    const { isDragging } = useDragAndDrop(props.element, domElement);

    const isInsideAndIsGuide = computed(() => {
      return isInside.value && store.userActivity.drag.guideElement?.pid === pid.value;
    })
    const showTopHalfHint = computed(() => {
      return isInsideAndIsGuide.value && !store.userActivity.drag.insertIntoGuide && inTopHalf.value
    })
    const showBottomHalfHint = computed(() => {
      return isInsideAndIsGuide.value && !store.userActivity.drag.insertIntoGuide && inBottomHalf.value
    })
    // End standard page component setup.


    const {isEditing, startEditing, stopEditing, updateInnerText, selectOrEdit} = useEdit();


    return {
      domElement,
      recordStartHover, recordEndHover,
      isEditing, startEditing, stopEditing, updateInnerText, selectOrEdit,
      showTopHalfHint,
      showBottomHalfHint,
      isInsideAndIsGuide,
      isInside,
      isOutside,
      inBottomHalf,
      inTopHalf,
      isDragging,
      pid,
      select,
      finalStyle,
      store,
    };
  },

  methods: {
    async smoothSelectOrEdit() {
      /*
      We use this method to allow the user to click on the text and start editing it without
      clicking three times.
       */
      this.selectOrEdit(this.pid);
      await nextTick(); // Ensure Vue updates the DOM
      this.domElement.focus(); // Now focus once after Vue has updated
    },
  },

  computed: {
    text() {
      return this.element.text;
    },
  },
};
</script>
