<template>
  <div class="space-y-4">
    <div>
      <p>Current context:</p>
    <div v-if="parsedJson">
      <div v-for="(value, key) in parsedJson" :key="key">
        <p><strong>{{ key }}</strong>: {{ value }}</p>
      </div>
    </div>
    <div v-if="!parsedJson">
      <p>No context set</p>
    </div>
    </div>


    <p>Add context:</p>
    <textarea
        v-model="jsonInput"
        @input="parseJson"
        name="json-input"
        placeholder="Add JSON here"
        class="w-full px-4 py-2 border border-gray-300 rounded-md"
    ></textarea>

    <div v-if="error" class="text-red-500">
      {{ error }}
    </div>

    <button
        @click="storeJsonData"
        class="px-4 py-2 text-white bg-green-500 rounded-md"
    >
      Store Data
    </button>
  </div>
</template>

<script>
import { useCounterStore } from '@/stores/counter';
import {mapStores} from "pinia";

export default {
  data() {
    return {
      jsonInput: '',
      parsedJson: null,
      error: null
    };
  },
  computed: {
    ...mapStores(useCounterStore)
  },
  methods: {
    parseJson() {
      try {
        this.parsedJson = JSON.parse(this.jsonInput);
        this.error = null;
      } catch (err) {
        this.error = err.toString();
      }
    },
    storeJsonData() {
      if (this.parsedJson) {
        this.counterStore.context = this.parsedJson;
      }
    },
  },
};
</script>
