<template>
  <div class="sidebar-assets">
    <div class="flex py-2 justify-between">
      <p>Assets</p>
      <button @click="this.store.setLeftSidebarToDefault()">
        <span><IconComponent name="close"></IconComponent></span>
      </button>
    </div>

    <div class="flex flex-col">
      <input ref="fileInput" accept="image/*" hidden type="file" @change="handleUpload" />
      <button v-if="!uploader.isUploading" class="select-file-button"
              @mouseup="$refs.fileInput.click();">
        Choose file to upload
      </button>
      <div v-if="uploader.isUploading" class="select-file-button">
        Uploading
        <div class="h-6 loading">
          <IconComponent  name="loading"></IconComponent>
        </div>
      </div>
    </div>

    <div>
      <ul class="assets-container grid-cols-3">
        <li v-for="asset in assetsStore.assetsByAscendingAge" :key="asset.id">
          <div @click="addAsset(asset)">
            <div>
              <img :alt="asset.key" :src="asset.url" class="asset-img">
            </div>
            <div>{{ shortName(asset.name) }}</div>
            <div>{{ toMegaBytes(asset.size) }} MB</div>
          </div>
        </li>
      </ul>

    </div>
  </div>
</template>

<script>
import {useFetchJson} from "@/composables/useFetchJson";
import IconComponent from "@/components/IconComponent.vue";
import {useRoute} from "vue-router";
import {useToast} from "vue-toastification";
import {useCounterStore} from "@/stores/counter";
import {useAssetsStore} from "@/stores/assets";
import {addPageComponent} from "@/utils/elementCreator";
import {BACKEND_PATHS} from "@/constants";

export default {
  components: {IconComponent},
  data() {
    return {
      uploader: {
        presignedData: {},
        file: null,
        isUploading: false,
        isUploaded: false,
      }
    };
  },
  setup(){
    const route = useRoute();
    const projectId = route.params.projectId
    const store = useCounterStore()
    const assetsStore = useAssetsStore()
    const toast = useToast()

    return {
      projectId, store, assetsStore, toast, addPageComponent
    }
  },
  computed: {
    selectedElement() {
      return this.store.selectedElement;
    }
  },
  created() {
    this.fetchAssets();
  },
  methods: {
    shortName(name) {
      /*
      Show a short version of the name.
       */
      if (name.length <= 13) {
        return name
      }
      return name.slice(0, 5) + '...' + name.slice(-5)
    },
    toMegaBytes(size) {
      /*
      Convert bytes to megabytes.
       */
      return (size / 1000000).toFixed(1)
    },
    async fetchAssets(){
      /*
      Fetch assets from the database.
       */
      const url = `${import.meta.env.VITE_OX_URL}/${BACKEND_PATHS.ASSETS(this.projectId)}`
      const options = { method: 'GET', headers: { 'Content-Type': 'application/json' } }
      const { data, error, response} = await useFetchJson(url, options)

      if (!error.value) {
        this.assetsStore.assets = data.value
      } else {
        console.error(`Error: ${response.value}`)
      }
    },
    async handleUpload(event){
      /*
      Handle uploading a file.
       */
      try {
        this.isUploading = true;
        await this.getPresignedData(event)
        await this.upload()
        if (this.uploader.isUploaded) {
          await this.saveUploadedFileKey()
        }
      } catch (error) {
        this.toast.error(
            'Error uploading.\n' +
            'Please try again or email support@photondesigner.com\n' +
            'We\'ll help you out quickly.'
        )
        console.error('Error during upload:', error);
      }
      this.isUploading = false;
      this.clearFileInput()
    },
    clearFileInput() {
      /*
      Clear the file input.
       */
      this.$refs.fileInput.value = null;
    },
    async getPresignedData(event) {
      /*
      Create a presigned URL for uploading a file to S3.
      */
      const files = event.target.files
      this.uploader.file = files[0]

      const body = JSON.stringify(
          {
            file: {
              name: this.uploader.file.name,
              content_type: this.uploader.file.type,
              size: this.uploader.file.size
            }
          }
      )

      const url = `${import.meta.env.VITE_OX_URL}/${BACKEND_PATHS.SIGN_UPLOAD(this.projectId)}`
      const options = { method: 'POST', body: body, headers: { 'Content-Type': 'application/json' } }
      const { data, error, response} = await useFetchJson(url, options)

      if (!error.value) {
        this.uploader.presignedData = data.value
      } else {
        const errorMessage = data.value.error

        this.toast.error(
            'Error uploading.\n' +
            'Please try again or email support@photondesigner.com\n' +
            'We\'ll help you out quickly.'
        )
      }
    },
    async upload() {
      /*
      Upload a file to object storage using the presigned URL.
       */
      const formData = new FormData();
      for (const [key, value] of Object.entries(this.uploader.presignedData.fields)) {
        formData.append(key, value);
      }
      formData.append('file', this.uploader.file);
      try {
        await fetch(this.uploader.presignedData.url, { method: 'POST', body: formData }); // Upload to S3.
        this.uploader.isUploaded = true
      } catch (error) {
        console.error('Error during upload:', error);
        this.toast.error(
            'Error uploading.\n' +
            'Please try again or email support@photondesigner.com\n' +
            'We\'ll help you out quickly.'
        )
        this.uploader.isUploaded = false
      }
    },
    async saveUploadedFileKey() {
      /*
      Save the file key to the database.
       */
      const body = JSON.stringify(
          { key: this.uploader.presignedData.fields.key, size: this.uploader.file.size }
      )
      const url = `${import.meta.env.VITE_OX_URL}/${BACKEND_PATHS.ASSETS(this.projectId)}`
      const options = { method: 'POST', body: body, headers: { 'Content-Type': 'application/json' } }
      const { data, error, response} = await useFetchJson(url, options)

      if (!error.value) {
        const newAsset = data.value[0]
        this.assetsStore.assets.push(newAsset)
      } else {
        console.error(`Error: ${error.value}`)
      }
    },
    addAsset(asset) {
      /*
      We either add the asset to the selected element, or we create a new element with the asset.
       */
      if (this.selectedElement?.component_name === 'PageImage') {
        this.selectedElement.asset_key = asset.key
      }
      else {
        const page_id = this.$route.params.pageId
        this.addPageComponent('PageImage', page_id, this.store, {asset_key: asset.key})
      }
    }
  }
};
</script>

<style scoped>
.sidebar-assets{
  padding: 0 .5rem;
}
.select-file-button {
  border: 1px solid #dcdcdc;
  border-radius: 4px;
  padding: 4px;
  cursor: pointer;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.select-file-button:hover {
  border: 1px solid #f6f6f6;
}

.assets-container{
  margin-top: .5rem;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-gap: .5rem;
  font-size: 10px;
  max-height: 40rem;
}

.asset-img {
  background: #2f2f2f;
  object-fit: contain;
  width: 80px;
  height: 80px;
  cursor: pointer;
  opacity: 0.8;
}

.asset-img:hover {
  opacity: 1;
}

.loading {
  animation: spin 1s infinite linear;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

</style>
