<template>
  <div id="container">
    <div class="flex w-full">

      <div class="color-swatch-container">
        <div class="color-swatch"
             :style="{ 'background-color': color }" @click="showColorPicker = true">
        </div>
      </div>

      <GenericInputText
          :value="convertToHex(color)"
          id="input-field"
          @update:value="changeColor($event)"
      />
    </div>
    <div
        class="color-picker-container"
        ref="colorPickerContainer"
        v-show="showColorPicker" @blur="showColorPicker = false">

      <div v-show="label" id="editing-label">
        Editing {{ label }}
        <button class="align-right" @click="showColorPicker = false">x</button>
      </div>
      <Chrome
          :model-value="color"
          @update:modelValue="changeColor($event.hex8)"
      />
    </div>
  </div>
</template>

<script>
import GenericInputText from "@/components/GenericInputText.vue";
import validateColor from "validate-color";
import Color from "colorjs.io";
import {Chrome} from '@ckpack/vue-color'; // https://github.com/ckpack/vue-color
import {ref} from 'vue';
import {onClickOutside} from '@vueuse/core'


export default {
  name: 'GenericInputColor',
  components: {
    GenericInputText, Chrome

  },
  props: {
    color: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: false,
      default: ''
    }
  },
  setup() {
    const showColorPicker = ref(false);

    const colorPickerContainer = ref(null);
    onClickOutside(colorPickerContainer, (event) => {
      showColorPicker.value = false;
    })

    return {
      colorPickerContainer, showColorPicker
    }
  },
  emits: ['update:color'],
  methods: {
    convertToHex(color) {
      const colObj = new Color(color);
      return colObj.toString({format: 'hex'})
    },
    changeColor(color) {
      if (validateColor(color)) {
        this.$emit('update:color', this.convertToHex(color))
      }
    },
  },
};
</script>

<style scoped>
#container {
  position: relative;
  width: max-content;
}

.color-picker-container {
  position: absolute;
  z-index: 10;
  top: 30px;
  right: 0px;
}

.color-swatch-container {
  background: repeating-conic-gradient(#808080 0% 25%, transparent 0% 50%) 50% / 20px 20px
}

.color-swatch {
  height: 30px;
  width: 30px;
}

#editing-label {
  user-select: none;
  color: #252525;
  background-color: white;
  text-align: center;
  padding: 1px 0;

}

#input-field {
  background-color: #333333;
  border: 1px solid gray;
}

</style>
