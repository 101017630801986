<template>
  <div class="">
    <div class="flex justify-between ">
      <div class="text-sm p-2">Navigator</div>
    </div>

    <Navigator/>

  </div>

</template>

<script>
import Navigator from "@/components/Navigator.vue";
import {Icon} from "@iconify/vue";
import {useCounterStore} from "@/stores/counter";
import IconComponent from "@/components/IconComponent.vue";


export default {
  components: {IconComponent, Icon, Navigator},
  computed: {
    store() {
      return useCounterStore();
    },
  },
}
</script>

<style scoped>


</style>