<template>
  <div class="flex input-field no-innerspin">

    <!-- Num input -->
    <input
        type="text"
        :value="amount"
        @blur="updateCssValue('amount', $event.target.value);"
        @keydown.enter="updateCssValue('amount', $event.target.value);"
        @pointerdown="updateByHorizontalScroll"
        class="input-field"
        :style="inputStyle"
        v-show="unit !== 'auto'"
    >

    <!-- Unit input -->
    <select class="select-field"
            v-if="showOptions"
            :class="selectClass"
            :value="unit"
            @change="updateCssValue('unit', $event.target.value)"
    >
      <option v-for="option in filteredOptions"
              :value="option.value">
        {{option.label}}
      </option>
    </select>


  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import {numObjectPayload} from "@/interfaces";
import {usePointer} from "@vueuse/core";
import {useCounterStore} from "@/stores/counter";


interface Option {
  label: string;
  value: string;
}


export default defineComponent({
  name: 'GenericInputNumUnit',
  description: 'A generic input, which handles numbers as well as css values.',
  emits: ["update:num-object"],
  props: {
    cssValue: {
      type: String as PropType<string>,
      required: true,
    },
    selectClass: {
      type: Object as PropType<object>,
      default: {},
    },
    inputStyle: {
      default: {},
    },
    excludeOptions: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    showOptions: {
      type: Boolean as PropType<boolean>,
      default: true,
    },


  },
  setup() {
    const store = useCounterStore();
    const {x, pressure, isInside} = usePointer( {target: window})
    return {
      x, pressure, isInside, store
    };
  },
  data(props: any) {
    const defaultOptions: Option[] = [
      {label: 'Auto', value: 'auto'},
      { label: 'PX', value: 'px' },
      {label: '%', value: '%'},
      { label: 'EM', value: 'em' },
      { label: 'REM', value: 'rem' },
      { label: 'VH', value: 'vh' },
      { label: 'VW', value: 'vw' },
      {label: 'FR', value: 'fr'},
    ];
    const initialX = null;
    const amountBeforePointerMove = null;

    return { defaultOptions, initialX, amountBeforePointerMove};
  },
  computed: {
    amount(): string {
      if (typeof this.cssValue !== 'string') {
        throw new Error(`Expected a string, got ${typeof this.cssValue} with value: ${this.cssValue}`);
      }
      return this.cssValue === 'auto' ? 'auto' : String(parseFloat(this.cssValue));
    },
    unit(): string {
      if (typeof this.cssValue !== 'string') {
        throw new Error(`Expected a string, got ${typeof this.cssValue} with value: ${this.cssValue}`);
      }
      return this.cssValue === 'auto' ? 'auto' : this.cssValue.replace(/[0-9.]/g, '');
    },
    filteredOptions(): Option[] {
      return this.defaultOptions.filter(option => !this.excludeOptions.includes(option.value));
    },
  },
  methods: {
    updateCssValue(type: string, term: string, isChanging=false): void {
      /*
      We emit the new css value to the parent component.
      We also emit whether the user is currently changing the value to determine whether
      to add an undo step.
      */
      if (!term) return;

      let cssValue: string;
      if (term === 'auto') {
        cssValue = term;
      }
      else if (type === 'amount') {
        cssValue = `${term}${this.unit}`;
      }
      else if (type === 'unit') {
        cssValue = this.amount === 'auto' ? `10${term}` : `${this.amount}${term}`;
      } else {
        console.error(`updateCssValue :: type must be "amount" or "unit". Got: ${type}`);
      }

      const payload: numObjectPayload = {
        css: {
          val: cssValue
        },
        isChanging: isChanging,
      };

      this.$emit('update:num-object', payload);
    },


    // Tdo: Move the below to a composable that returns the updated amount.
    updateByHorizontalScroll(event: MouseEvent): void {
      this.amountBeforePointerMove = this.amount;
      this.initialX = event.clientX;

      this.attachPointerMovementListeners();
    },
    attachPointerMovementListeners(): void {
      /*
      We attach the listeners to the window and the iframe.
      We do this so that the user can move the mouse horizontally to change values,
      including between the window and the iframe.
       */
      window.addEventListener('pointermove', this.pointerMoveHandler);
      window.addEventListener('pointerup', this.pointerUpHandler);

      this.store.workFrame.contentWindow.addEventListener('pointermove', this.pointerMoveHandler);
      this.store.workFrame.contentWindow.addEventListener('pointerup', this.pointerUpHandler);
    },

    pointerMoveHandler(event: MouseEvent): void {
      const deltaX = this.initialX - event.clientX;
      const updatedAmount = Math.floor(parseFloat(this.amountBeforePointerMove) - deltaX)

      if (this.amount === String(updatedAmount)) return; // We ignore the event if the amount after rounding hasn't changed.

      if (updatedAmount >= 0){
        this.isChanging = true;
        this.updateCssValue('amount', String(updatedAmount), true);
      }
    },

    pointerUpHandler(event: MouseEvent): void {
      this.updateCssValue('amount', this.amount, false);

      this.removePointerListeners();
    },

    removePointerListeners(): void {
      window.removeEventListener('pointermove', this.pointerMoveHandler);
      window.removeEventListener('pointerup', this.pointerUpHandler);
      this.store.workFrame.contentWindow.removeEventListener('pointermove', this.pointerMoveHandler);
      this.store.workFrame.contentWindow.removeEventListener('pointerup', this.pointerUpHandler);
    },
  },
});
</script>

<style scoped>
.input-field {
  background-color: #333333;
  color: white;
  width: 100%;
  border-radius: 4px;
}

.select-field {
  text-align: center;
  width: 100%;
  background-color: #333333;
  color: #a5a5a5;
  border-radius: 4px;
  cursor: pointer;
  -webkit-appearance: none; /* Remove default dropdown arrow in Chrome/Safari */
  -moz-appearance: none; /* Remove default dropdown arrow in Firefox */
  appearance: none; /* Generally remove default dropdown arrow */

}


.select-field:focus {
  outline: none
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

input {
  padding: 0 5px;
}

input:focus {
  outline: 1px solid lightblue;
  outline-offset: -1px;
}


</style>
