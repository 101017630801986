<template>
  <ControlGenericWrapper v-if="selectedElement">
    <template v-slot:label>
      Layout
    </template>
    <template v-slot:content>
      <div class="grid grid-cols-4 items-center">
        <CssLabel css-property="display" label="Display"/>
        <p class="col-span-3 capitalize select-none"
           v-text="computedCssValue('display', '').value"></p>
      </div>
      <div class="grid grid-cols-4 items-center ">
        <div></div>
        <GenericInputRadioGroup class="col-span-3 grid grid-cols-6 gap-[1px]"
                                name="display"
                                :current-value="computedCssValue('display', '').value"
                                :options="[
                                    { value: 'block', iconComponent: 'display-block'},
                                    { value: 'flex', iconComponent: 'display-flex'},
                                    { value: 'grid', iconComponent: 'display-grid'},
                                    { value: 'inline-block', iconComponent: 'display-inline-block'},
                                    { value: 'inline', iconComponent: 'display-inline'},
                                    { value: 'none', iconComponent: 'display-none'},
                                ]"
                                @update:value="setLocalCssProperty('display', $event)"
        />
      </div>

      <div v-if="computedCssValue('display', '').value === 'flex'">
        <div class="pb-1 items-center grid grid-cols-4">
          <div class="">Direction</div>
          <GenericInputRadioGroup
              :options="[
                  {value: 'row', description: 'Row'},
                  {value: 'column', description: 'Column'},
                  {value: 'column-reverse', description: 'Col Rev'},
                  {value: 'row-reverse', description: 'Row Rev'},
              ]"
              name="flex-direction"
              :current-value="computedCssValue('flex-direction', '').value"
              class="col-span-3 grid grid-cols-4 gap-[1px]"
              @update:value="setLocalCssProperty('flex-direction', $event)"
          />
        </div>

        <!-- Flex options -->
        <div class="pb-1 items-center grid grid-cols-4">
           <CssLabel css-property="justify-content" label="Justify"/>
          <GenericInputRadioGroup
              :current-value="computedCssValue('justify-content', '').value"
              :options="[
                  {value: 'flex-start', iconComponent: 'justify-content-start'},
                  {value: 'center', iconComponent: 'justify-content-center'},
                  {value: 'flex-end', iconComponent: 'justify-content-end'},
                  {value: 'space-around', iconComponent: 'justify-content-space-around'},
                  {value: 'space-between', iconComponent: 'justify-content-space-between'},
                  {value: 'space-evenly', iconComponent: 'justify-content-space-evenly'}
              ]"
              class="col-span-3 grid grid-cols-6 gap-[1px]"
              name="justify-content"
              @update:value="setLocalCssProperty('justify-content', $event)"
          />
        </div>

        <div class="pb-1 items-center grid grid-cols-4">
           <CssLabel css-property="align-items" label="Align"/>
          <GenericInputRadioGroup
              class="col-span-3 grid grid-cols-5  gap-[1px]"
              name="align-items"
              :current-value="computedCssValue('align-items', '').value"
              :options="[
                  {value: 'start', icon: 'bi:align-start'},
                  {value: 'center', icon: 'bi:align-center'},
                  {value: 'end', icon: 'bi:align-end'},
                  {value: 'stretch', iconComponent: 'align-items-stretch'},
                  {value: 'baseline', iconComponent: 'align-items-baseline'}
              ]"
              @update:value="setLocalCssProperty('align-items', $event)"
          />
        </div>

        <div class="pb-1 grid grid-cols-4">
          <CssLabel class="pl-2 whitespace-nowrap" css-property="row-gap" label="Row gap"/>
          <GenericInputNumUnit
              :css-value="computedCssValue('row-gap', '0px').value"
              @update:num-object="setLocalCssProperty('row-gap', $event.css.val)"
          />
          <CssLabel class="pl-2 whitespace-nowrap" css-property="col-gap" label="Col gap"/>
          <GenericInputNumUnit
              :css-value="computedCssValue('column-gap', '0px').value"
              @update:num-object="setLocalCssProperty('column-gap', $event.css.val)"
          />
        </div>

        <div class="pb-1 items-center grid grid-cols-4">
          <CssLabel css-property="flex-wrap" label="Flex wrap"/>
          <GenericInputRadioGroup
              class="col-span-3 grid grid-cols-2 gap-[1px]"
              name="flex-wrap"
              :current-value="computedCssValue('flexWrap', '').value"
              :options="[
                  {value: 'wrap', description: 'Wrap'},
                  {value: 'nowrap', description: 'No wrap'}
              ]"
              @update:value="setLocalCssProperty('flex-wrap', $event)"
          />
        </div>

      </div>

      <div v-if="computedCssValue('display', '').value === 'grid'">
        <ControlLayoutGrid
            :computed-css-value="computedCssValue"
            :set-local-css-property="setLocalCssProperty"
        />
      </div>

    </template>
  </ControlGenericWrapper>
</template>

<script>
import {useCounterStore} from '@/stores/counter';
import GenericInputRadioGroup from "@/components/GenericInputRadioGroup.vue";
import GenericInputNumUnit from "@/components/GenericInputNumUnit.vue";
import useSelectedElement from "@/composables/useSelectedElement";
import ControlGenericWrapper from "@/components/ControlGenericWrapper.vue";
import ControlLayoutGrid from "@/components/ControlLayoutGrid.vue";
import CssLabel from "@/components/CssLabel.vue";

export default {
  components: {CssLabel, ControlLayoutGrid, ControlGenericWrapper, GenericInputRadioGroup, GenericInputNumUnit},
  setup() {
    const { selectedElement, setLocalCssProperty, computedCssValue  } = useSelectedElement();

    return {
      selectedElement, computedCssValue, setLocalCssProperty
    };
  },
  computed: {
    store() {
      return useCounterStore();
    },
  },
};
</script>

<style scoped>

</style>
