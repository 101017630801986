<script>
import IconComponent from "@/components/IconComponent.vue";
import ProjectCreator from "@/components/ProjectCreator.vue";
import {
  renderDjangoTemplate,
  postCompletionSet,
  postCompletion,
  getCompletionsPublic,
  postCompletionScreenshot
} from "@/utils/apiRequests";
import {useClipboard} from '@vueuse/core';
import {useToast} from "vue-toastification";
import NavBar from "@/components/NavBar.vue";
import {useAuthUtils} from "@/composables/useAuthUtils";
import {useModal} from "vue-final-modal";
import ModalBeforeCompletion from "@/components/ModalBeforeCompletion.vue";
import {Codemirror} from "vue-codemirror";
import {html} from '@codemirror/lang-html'
import {json} from '@codemirror/lang-json'
import {nextTick, ref, shallowRef} from 'vue'
import CompletionDjangoModeSetting from "@/components/CompletionDjangoModeSetting.vue";
import CompletionColors from "@/components/CompletionColors.vue";
import CompletionStream from "@/components/CompletionStream.vue";
import {useCompletionsStore} from "@/stores/completions";
import CompletionsHistory from "@/components/CompletionsHistory.vue";
import ImageLoader from "@/components/ImageLoader.vue";
import {getProfile} from "@/utils/apiRequests";
import GradientImage from "@/components/GradientImage.vue";


export default {
  components: {
    GradientImage,
    ImageLoader,
    CompletionsHistory,
    CompletionStream,
    CompletionColors, CompletionDjangoModeSetting, Codemirror, NavBar, IconComponent, ProjectCreator
  },
  setup() {
    const {isSignedIn} = useAuthUtils()
    const {open} = useModal({component: ModalBeforeCompletion, attrs: {showSavedPrompt: false}})

    const code = ref(`<div>This is a div<p>Hallo!</p></div>`)
    const extensions = [html(), json()]

    const completionsHistory = ref(null)

    // Codemirror EditorView instance ref
    const view = shallowRef()
    const handleReady = (payload) => {
      view.value = payload.view
    }

    return {
      isSignedIn,
      completionsHistory,
      toast: useToast(),
      openLoginModal: open,
      code,
      store: useCompletionsStore(),
      extensions,
      handleReady,
      log: console.log,
    }
  },

  data() {
    return {
      phase: 'index', // index | create
      userPrompt: '',
      userTemplatePrompt: '',
      cssFramework: 'tailwind',
      colorScheme: {
        primary: '#FCF7F8',
        secondary: '#208AAE',
        accent: '#0D2149',
      },
      generationQuality: 'good',

      showCode: false,
      isStreaming: false,
      renderedDjangoTemplate: '',
      renderedDjangoError: '',
      codeSection: {
        selectedTab: 'code',
      },
      profile: {},
    };
  },
  computed: {
    selectedCompletion() {
      return this.store.selectedCompletion
    },
    isProUser() {
      return this.profile?.is_subscribed
    },
  },
  async mounted() {
    if (this.$route.query['set']) {
      await this.setPhase('create', this.$route.query['set'])
    } else {
      await this.setPhase('index')
    }

    if (this.isSignedIn) {
      const initial_user_prompt = sessionStorage.getItem("initial_user_prompt")
      if (initial_user_prompt) {
        this.userPrompt = initial_user_prompt
        sessionStorage.removeItem("initial_user_prompt")
        this.handleGenerateClick()
      }
    }

    this.profile = await getProfile()
  },
  created() {
    window.onbeforeunload = () => {
      if (this.isStreaming) {
        return '';
      }
    }
  },
  methods: {
    async setPhase(phase, setId) {
      /*
      Sets the phase of the page.
       */
      if (phase === 'index') {
        this.store.completions = []
        this.phase = 'index'
        this.store.completions = await getCompletionsPublic({"latest": true})
      }

      if (phase === 'create') {
        this.store.completions = []
        if (!setId) {
          throw 'Invalid set id'
        }
        this.$router.push({query: {set: setId}})
        this.phase = 'create'
        this.store.completions = await getCompletionsPublic({"set-id": setId})
      }
    },
    showDayMonth(dateString) {
      const date = new Date(dateString);
      const day = date.getDate();
      const month = date.toLocaleString('default', {month: 'long'});
      return `${day} ${month}`;
    },
    selectCompletion(id) {
      if (this.store.isGenerating) {
        this.toast.info("Currently generating. Please wait for the current generation to finish", {timeout: 3000});
      } else {
        this.store.setSelectedCompletionId(id)
      }
    },
    handleGenerateClick() {
      if (this.isSignedIn) {
        this.showCode = true
        this.generateCompletion()
      } else {
        sessionStorage.setItem("initial_user_prompt", this.userPrompt) // Store the user's prompt in session storage
        this.openLoginModal()
      }
    },
    copyCode() {
      const {copy} = useClipboard({legacy: true});
      copy(this.selectedCompletion.data);
      this.toast.success('Copied code to your clipboard 📋', {closeButton: "button"});
    },
    copyPrompt() {
      const {copy} = useClipboard({legacy: true});
      copy(this.selectedCompletion.user_prompt);
      this.toast.success('Copied prompt to your clipboard 📋', {timeout: 3000});
    },
    async initCompletion() {
      /*
      For a neater flow, we first create a completion on the database and load it.
      We then insert the streaming data into this completion.
      This is simpler than trying to stream data to a completion that doesn't exist yet.
       */
      const payload = {
        operation: "init_completion",
        set_id: this.$route.query['set'],
        user_prompt: this.userPrompt,
        user_template_prompt: this.userTemplatePrompt,
        css_framework: this.cssFramework || this.selectedCompletion.css_framework,
        color_scheme: {
          primary: this.colorScheme.primary,
          secondary: this.colorScheme.secondary,
          accent: this.colorScheme.accent,
        },
        generation_quality: this.generationQuality,
      }

      if (!this.userPrompt || this.userPrompt === '' || typeof this.userPrompt !== 'string') {
        console.error('Invalid user prompt: ', userPrompt);
        throw new Error('Invalid user prompt');
      }

      const {data} = await postCompletion(payload)
      if (!data) {
        throw (`Got no completion_data. Received from the server: ${data}`)
      }
      return data
    },
    async addImagesToCompletion(completionId) {
      const payload = {
        operation: "add_images",
        completion_id: completionId,
      }
      const {data} = await postCompletion(payload)
      if (!data) {
        throw (`Got no completion_data. Received from the server: ${data}`)
      }
      return data
    },
    async takeScreenshot() {
      const iframeBody = document.getElementById('iframe').contentDocument.body

      try {
        const canvas = await html2canvas(iframeBody, {allowTaint: false});
        const imgData = canvas.toDataURL('image/png');

        if (imgData) {
          const payload = {
            completion_id: this.selectedCompletion.id,
            image_base64: imgData
          };
          await postCompletionScreenshot(payload);
        } else {
          console.error("No image data found");
        }
      } catch (error) {
        console.error("Error capturing screenshot:", error);
      }
    },
    async generateCompletion() {
      if (!this.userPrompt) {
        this.toast.error("Please describe what you want to build.", {timeout: 3000});
        return;
      }
      const completionData = await this.createBlankCompletion()
      if (!completionData.id) {
        throw (`No completion_id found. Got from the server: ${completionData}`)
      }

      await this.setPhase('create', completionData.set)
      this.completionsHistory.goToLastPage()

      this.isStreaming = false
      this.userPrompt = ''
    },
    async createBlankCompletion() {
      /*
      Create a new completion object on the server without data.
      We do this to get object for us to insert the streaming data into.
       */
      const completion = await this.initCompletion()
      if (!completion.id) {
        throw (`No completion_id found. Got from the server: ${completion}`)
      }
      this.store.completions.push(completion)

      this.selectCompletion(completion.id)
      return completion
    },
    async scrollToCompletion(completionId) {
      await this.$nextTick()
      const versionContainer = document.querySelector(`[data-completion-id="${completionId}"]`)
      if (versionContainer) {
        versionContainer.scrollIntoView({behavior: "smooth", block: "center", inline: "center"})
      }
    },
    async refreshDjangoTemplate() {
      /*
      We call this to re-render the Django template using the selected completion.
       */
      const payload = {
        template_code: this.selectedCompletion?.data,
        template_context: this.selectedCompletion?.context,
      }

      if (typeof this.selectedCompletion.context !== 'string') {
        console.error("this.selectedCompletion.context = ", this.selectedCompletion.context)
      }

      const {response, data, error} = await renderDjangoTemplate(payload)
      if (error === null) {
        this.renderedDjangoError = ''
        this.renderedDjangoTemplate = data
      } else {
        this.store.render = error
      }
    },
    setTemplateEngine(value) {
      this.store.setCompletion(this.selectedCompletion.id, 'active_template_engine', value);
    }
  },


  watch: {
    $route(to, from) {
      if (to.query['set']) {
        this.setPhase('create', to.query['set'])
      } else {
        this.setPhase('index')
      }
    }
  },

}
</script>

<template>
  <div class="max-h-screen h-screen flex flex-col">
    <NavBar>
      <button v-if="phase === 'create'" class="border px-2 btn btn-neutral btn-outline btn-sm font-normal"
              @click="this.setPhase('index')">
        Generate something new
      </button>
    </NavBar>

    <div v-if="phase === 'index'">
      <div class="pt-2">
        <div>
          <div class="flex flex-col items-center w-full">

            <!--      Prompt container-->
            <div class="flex flex-col gap-2 py-2 w-11/12 md:w-2/5">

              <div class="form-control">
                <label class="label">
                  <span
                      class="label-text text-lg text-center w-full">Describe the user interface you want to build</span>
                </label>
                <textarea class="textarea textarea-bordered resize-none"
                          autofocus v-model="userPrompt"
                          placeholder="An elegant AirBnB dashboard showing locations."
                />
              </div>

              <Transition>
                <div v-show="selectedCompletion?.active_template_engine === 'django'"
                     class="form-control ">
                  <label class="label">
                    <span class="label-text">Add your starting Django template (Optional)</span>
                  </label>
                  <textarea class="textarea textarea-bordered resize-none"
                            v-model="userTemplatePrompt"
                            placeholder="<div>Hallo! I'm a {{ species}}</div>"></textarea>
                </div>
              </Transition>
            </div>

            <!--      Generation settings. -->


            <div class="hidden sm:flex text-sm justify-center items-center gap-2">


              <select class="select select-bordered w-full max-w-xs" v-model="cssFramework">
                <option value="tailwind">Tailwind CSS</option>
                <option value="vanilla_css">Vanilla CSS</option>
              </select>

              <CompletionColors
                  :primary="colorScheme.primary"
                  :secondary="colorScheme.secondary"
                  :accent="colorScheme.accent"
                  @update:colorScheme="colorScheme = $event"
              />

              <CompletionDjangoModeSetting
                  :isDjangoMode="selectedCompletion?.active_template_engine === 'django'"
                  @update:isDjangoMode="setTemplateEngine($event ? 'django' : '')"
              />
            </div>

            <div class="flex flex-col pt-3">

              <button class="btn btn-primary btn-outline text-white" @click="handleGenerateClick()"
                      :disabled="this.store.isGenerating">
              <span class=""
                    :class="{'inline-flex loading loading-spinner text-black': this.store.isGenerating}"></span>
                <span v-show="!this.store.isGenerating">
              Generate
            </span>
              </button>

              <div class="text-sm flex select-none pt-2 gap-1 items-center">
                <span>Quality:</span>
                <select class="select select-bordered select-sm w-full max-w-xs text-xs text-center">
                  <option :selected="!isProUser" @click="generationQuality = 'good'">
                    Good
                  </option>
                  <option :disabled="!isProUser" :selected="isProUser" @click="generationQuality = 'best'">
                    Best (⭐️ Pro users only)
                  </option>
                </select>
              </div>


            </div>
          </div>
        </div>
      </div>
      <br>

      <div class="flex flex-col w-full">
        <h2 class="divider text-xl" style="font-weight: 600">Recent generations</h2>
      </div>

      <div class="w-full grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-5 place-content-center px-5">
        <div
            v-for="completion in store.completions"
            class="mr-5 mb-5 shadow-md hover:shadow-lg overflow-hidden cursor-pointer w-full flex flex-col p-0 rounded-xl"
            @click="this.setPhase('create', completion.set)"
        >
          <GradientImage :image-url="completion.screenshot_url" :width="300" :height="120"/>

          <div class="py-2 flex flex-col items-center  gap-1 px-2">
            <!--            <ImageLoader :imageUrl="completion.user.avatar" :w="30" :h="30" :extra-style="{'border-radius': '100%'}"/>-->
            <p class="text-xs truncate ... w-11/12 ">{{ completion.user_prompt }}</p>
            <p class="badge badge-sm badge-ghost mx-2 self-start">{{ showDayMonth(completion.created_at) }}</p>
          </div>
        </div>

      </div>
    </div>


    <!--    Edit ending-->

    <main v-if="phase === 'create'"
          id="page" class="flex flex-col md:flex-row gap-4 flex-1 items-start">

      <section class="h-full flex flex-col bg-zinc-100 overflow-y-auto border-b border-1 w-full">
        <div class="px-1 flex-auto relative">

          <!--      The top bar. -->
          <div class="flex sm:items-center justify-between mb-1 sticky top-0 z-10 bg-white border-b-2">


            <div class="justify-start flex flex-row gap-1 py-1">

              <div @click="copyPrompt()"
                   class="cursor-pointer hover:text-zinc-900 flex text-sm items-center overflow-y-auto text-ellipsis">
                {{ selectedCompletion?.user_prompt }}
              </div>
            </div>

            <!--        Action buttons. -->
            <div class="flex gap-0.5 flex-col sm:flex-row">
              <!--                Error section-->
              <div
                  v-show="selectedCompletion?.active_template_engine === 'django' && selectedCompletion?.renderingError"
                  class="w-full content-center gap-1 rounded-md border-rose-100 px-2.5 py-1 text-xs font-medium text-rose-700 shadow-sm"
              >
                Django error: {{ selectedCompletion?.renderingError }}
              </div>
              <CompletionDjangoModeSetting
                  :isDjangoMode="selectedCompletion?.active_template_engine === 'django'"
                  @update:isDjangoMode="setTemplateEngine($event ? 'django' : ''); this.$refs['completion-stream'].insertHTML();"
              />
              <button class="btn" @click="copyCode">
                Copy code
                <IconComponent class="hover:text-blue-900 hover:bg-green-100" name="copy-to-clipboard"
                               :style="{'width':'20px'}"></IconComponent>
              </button>
              <button
                  @click="showCode = !showCode"
                  class="btn bg-green-950 text-zinc-100 hover:bg-green-900 p-1 px-3 rounded-lg flex gap-1 items-center text-nowrap">
                <span v-show="!showCode">Show code</span>
                <span v-show="showCode">Hide code</span>
                <IconComponent name="code" style="{'width':'30px'}"></IconComponent>
              </button>
            </div>
          </div>

          <div class="flex w-full h-full flex-col sm:flex-row">

            <CompletionStream v-if="selectedCompletion" :completion="selectedCompletion"
                              ref="completion-stream"
            />

            <Transition>
              <div class="flex-1 bg-gray-100 px-4 overflow-y-scroll" v-if="showCode">

                <div class="flex items-center bg-gray-100 z-20">

                  <div role="tablist" class="tabs tabs-lifted w-3/5">
                    <a role="tab" class="tab flex gap-1"
                       :class="{'tab-active': codeSection.selectedTab === 'code'}"
                       @click="codeSection.selectedTab = 'code'">
                      Code
                      <div class="rounded-lg" @click="copyCode">
                        <IconComponent class="hover:text-blue-900 hover:bg-green-100" name="copy-to-clipboard"
                                       :style="{'width':'20px'}"></IconComponent>
                      </div>
                    </a>
                    <a role="tab" class="tab"
                       :class="{'tab-active': codeSection.selectedTab === 'context'}"
                       @click="codeSection.selectedTab = 'context'">Context
                    </a>
                  </div>


                </div>

                <!--                Code section -->
                <section v-show="codeSection.selectedTab === 'code'" class="w-full max-w-full flex flex-col">

                  <div class="max-h-96">
                    <codemirror
                        v-if="selectedCompletion"
                        key="code"
                        class="flex-1 z-0"
                        placeholder="Code goes here..."
                        :style="{ 'max-width': '1000px' }"
                        :autofocus="true"
                        :indent-with-tab="true"
                        :tab-size="2"
                        :extensions="extensions"
                        @ready="handleReady"
                        :modelValue="selectedCompletion.data"
                        @update:modelValue="this.store.setCompletion(this.selectedCompletion.id, 'data', $event);"
                    />
                    <details class="collapse collapse-arrow border border-base-300 bg-base-200 text-md p-0">
                      <summary class="collapse-title text-md">
                        Q. How do I use this code? (Tailwind CSS)
                      </summary>
                      <div class="collapse-content ">
                        <p>
                          By default, we generate code that uses Tailwind CSS.
                        </p>
                        <p>
                          Adding Tailwind CSS to your
                          HTML using a CDN takes one line. See the <a class="link"
                                                                      href="https://tailwindcss.com/docs/installation/play-cdn"
                                                                      target="_blank">instructions here</a>.
                        </p>
                        <br>
                        <p>
                          If you're using a frontend framework, check out the <a class="link"
                                                                                 href="https://tailwindcss.com/docs/installation"
                                                                                 target="_blank">full instructions for
                          adding Tailwind here</a> (it's also very easy).
                        </p>
                      </div>
                    </details>
                  </div>

                </section>


                <section v-show="codeSection.selectedTab === 'context'">
                  Enter your Django template context

                  <div class="max-h-96">
                    <codemirror
                        v-if="selectedCompletion"
                        key="context"
                        class="flex-1"
                        placeholder='{"user": {"name": "John", "age": 30}}'
                        :style="{ 'max-width': '1000px' }"
                        :autofocus="true"
                        :indent-with-tab="true"
                        :tab-size="2"
                        :extensions="extensions"
                        @ready="handleReady"
                        :modelValue="selectedCompletion.context"
                        @update:modelValue="this.store.setCompletion(this.selectedCompletion.id, 'context', $event);"
                    />
                  </div>
                </section>


              </div>
            </Transition>
          </div>
        </div>
      </section>


    </main>

    <aside v-if="phase === 'create'" class="flex items-center w-full justify-around p-2 z-10 gap-1">

      <CompletionsHistory class="w-1/5 h-full"
                          ref="completionsHistory"
                          @select:completion-id="this.selectCompletion($event)"/>

      <div
          class="h-auto flex flex-col sm:flex-row items-center bg-white rounded-2xl shadow-lg overflow-hidden w-full md:w-3/5 xl:w-2/5 border hover:shadow-gray-300 hover:border-gray-300">

        <div class="flex-1 w-full">
          <textarea
              ref="textarea"
              v-model="userPrompt"
              autofocus=""
              class="pd-hide-scrollbar w-full px-3 py-2 text-gray-800 bg-transparent outline-none resize-none placeholder:text-gray-400 transition-colors duration-300 focus:placeholder:text-gray-300"
              placeholder="Make the top heading text larger or change the button color to blue"></textarea>
        </div>

        <div
            class="flex flex-col items-center justify-center px-6 py-4 bg-white rounded-r-2xl select-none outline-none">
          <div class="flex items-center my-2">
            <div class="text-sm">Quality:</div>
            <select class="select select-xs max-w-xs text-sm bg-transparent" v-model="generationQuality">
              <option value="good" :selected="!isProUser">Good</option>
              <option value="best" :disabled="!isProUser" :selected="isProUser">
                Best (⭐️ Pro users only)
              </option>
            </select>
          </div>

          <button
              class="btn btn-primary flex items-center justify-center w-44 px-4 py-2 text-lg font-semibold text-white rounded-lg transition-colors duration-300"
              :class="{'hover:bg-gray-700': !this.store.isGenerating}"
              :disabled="this.store.isGenerating" @click="handleGenerateClick()">
            <span class="" :class="{'inline-flex loading loading-spinner text-black': this.store.isGenerating}"></span>
            <span v-show="!this.store.isGenerating">
              Generate
            </span>
          </button>

        </div>
      </div>

      <!--      Third column. -->
      <div class="flex-none text-center">
        <div v-if="this.profile.email && !isProUser" class="hidden sm:block w-full shadow-xl p-3 rounded-xl">
          <p>Do you want much better results? </p>
          <p>Try our <a href="/pricing" class="text-blue-500">Pro plan</a> to get best quality ⭐</p>
        </div>
        <div>
        </div>
      </div>


    </aside>
  </div>

</template>



<style scoped>



#headline-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px;
  font-size: 24px;
  font-weight: bold;
  min-width: 60%;
}


#prompt-textarea {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #575757;
  border-radius: 5px;
  resize: none;
  outline: none;
}


#previous-versions-section .screenshot {
  cursor: pointer;
  border: 1px solid #e8e8e8;
  border-radius: 10px;
}

#previous-versions-section .screenshot:hover {
  border: 1px solid lightgray;
}

.pd-hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.pd-hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.pd-thin-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #dadada transparent;
}

.header {
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main-content {
  display: flex;
  padding: 20px;
}

.sidebar {
  width: 200px;
  background-color: #fff;
  padding: 20px;
  margin-right: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.sidebar-link {
  text-decoration: none;
  color: #333;
  display: block;
  padding: 10px 0;
}


.site-view-project {
  cursor: pointer;
  text-decoration: underline;
}

.site-view-project:hover {
  color: #6c6c6c;
}

.version-wrapper {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  display: flex;
  flex: 1;
  flex-direction: column-reverse;
  overflow: auto;
}

.version-wrapper::-webkit-scrollbar {
  display: none;
}





.version-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 1024px;
  height: 576px;
  transform-origin: top left;
  transform: scale(0.13);
  background: white;
  opacity: 1;
  pointer-events: none;
}

.version-container .index-badge {
  position: absolute;
  bottom: 4px;
  left: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 32px;
  border-radius: 2px;
  border: 1px solid #bcc2bc;
  background-color: rgba(248, 248, 248, 0.67);
  color: #2e3a2e;
  font-family: monospace;
  font-size: 10px;
  text-align: center;
}


</style>
