<template>
  <PageButton :element="this.element"/>
</template>

<script>


import PageButton from "@/components/element_components/PageButton.vue";

export default {
  name: 'PageFormButton',
  components: {PageButton},
  props: {
    element: {
      type: Object,
      required: true,
    },
  },
}
</script>