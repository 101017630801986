<template>
  <div class="element-editor text-gray-200" >
    <section class="header">
      <GenericInputRadioGroup
          :current-value="store.elementEditor.selectedTabName"
          :options="[
            {description: 'Style', value: 'style'},
            {description: 'Settings', value: 'settings'},
        ]"
          class="grid grid-cols-3 w-full"
          name="selected-tab-name"
          @update:value="store.elementEditor.selectedTabName = $event"
      />

      <div class="text-gray-200 flex items-center w-full">
        <Icon v-show="selectedElement" :icon="selectedElement?.navigatorIcon" />
        <span><IconComponent v-show="!selectedElement" name="no-element-selected" /></span>
        {{ this.uniqueName }}
      </div>
    </section>

    <section v-show="selectedElement && store.elementEditor.selectedTabName === 'style'">
      <ControlLayout />

      <ControlSpacing />

      <ControlSize />

      <ControlTypography />

      <ControlBackground />

      <ControlBorders v-if="selectedElement?.component_name !== 'PageRoot'"/>

    </section>

    <section v-show="selectedElement && store.elementEditor.selectedTabName === 'settings'">

      <ControlLinkBlock v-if="selectedElement?.component_name === 'PageLinkBlock'"/>

      <ControlForm v-if="inForm"/>

      <ControlFormField v-if="selectedElement?.component_name === 'PageFormInput'"/>

      <ControlImage v-if="selectedElement?.component_name === 'PageImage'"/>

    </section>
  </div>
</template>

<script>
import {useCounterStore} from '@/stores/counter';
import ControlImage from "@/components/ControlImage.vue";
import ControlLayout from "@/components/ControlLayout.vue";
import ControlSize from "@/components/ControlSize.vue";
import ControlSpacing from "@/components/ControlSpacing.vue";
import ElementEditorTabs from "@/components/ElementEditorTabs.vue";
import ControlForm from "@/components/ControlForm.vue";
import {ComponentName} from "@/utils/elementCreator.ts"
import ControlFormInput from "@/components/ControlFormInput.vue";
import ControlBackground from "@/components/ControlBackground.vue";
import {Icon} from "@iconify/vue";
import ControlTypography from "@/components/ControlTypography.vue";
import ControlBorders from "@/components/ControlBorders.vue";
import GenericInputRadioGroup from "@/components/GenericInputRadioGroup.vue";
import IconComponent from "@/components/IconComponent.vue";
import ControlFormField from "@/components/ControlFormField.vue";
import ControlLinkBlock from "@/components/ControlLinkBlock.vue";
import {ref} from "vue";


export default {
  name: 'ElementEditor',
  components: {
    ControlFormField,
    IconComponent,
    GenericInputRadioGroup,
    ControlBorders,
    ControlTypography,
    Icon,
    ControlBackground,
    ControlFormInput,
    ControlForm,
    ElementEditorTabs,
    ControlSize,
    ControlImage,
    ControlLayout,
    ControlSpacing,
    ControlLinkBlock,
  },
  setup() {
    const store = useCounterStore()
    const navbarHeight = ref(store.dims.navbar.height)
    return {
      store,
      navbarHeight,
    }
  },
  computed: {
    ComponentName() {
      return ComponentName
    },
    elementEditorHeight() {
      return `calc(100vh - ${this.navbarHeight}px)`
    },
    uniqueName() {
      if (!this.selectedElement){
        return 'No element selected'
      }
      else{
        const shortComponentName = this.selectedElement?.component_name?.replace('Page', '')
        const digitsSubstring = this.selectedElement?.pid?.match(/\d+/g)?.[0].slice(-5)
        return `${shortComponentName} ${digitsSubstring}`
      }
    },

    selectedElement() {
      return this.store.selectedElement
    },
    inForm(){
      const formComponents = [
        ComponentName.PageForm,
        ComponentName.PageFormState,
        ComponentName.PageFormInput,
        ComponentName.PageFormSubmitButton
      ]
      return formComponents.includes(this.selectedElement?.component_name)
    }
  },
};
</script>

<style scoped>
.element-editor {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  background-color: #888888;
  border-left: 1px solid #252525;
  height: v-bind(elementEditorHeight);
  width: 250px; /* Fixed width */
  flex-shrink: 0; /* Prevents shrinking */

}
.element-editor::-webkit-scrollbar {
  display: none;
}

.element-editor::-webkit-scrollbar {
  display: none;
}


.header {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #333333;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  border-bottom: 1px solid #494949;
}
</style>
