<template>
  <ControlGenericWrapper>
    <template v-slot:label>
      Image settings
    </template>
    <template v-slot:content>
      <div class="flex items-center">
        <div v-if="linkedAsset">
          <img :src="linkedAsset.url" alt="" class="image">
        </div>
        <p>
          {{ linkedAsset.name }}
        </p>
        <button class="select-asset-button"
                @click="this.store.setLeftSidebar({ selectedPane: 'LeftSidebarAssets', isShowing: true })">
          Replace image
        </button>
      </div>
    </template>
  </ControlGenericWrapper>
</template>

<script>
import {useCounterStore} from '@/stores/counter';
import {useAssetsStore} from '@/stores/assets';
import ContextSelector from "@/components/ContextSelector.vue";
import ControlGenericWrapper from "@/components/ControlGenericWrapper.vue";
import GenericInputText from "@/components/GenericInputText.vue";

export default {
  components: {GenericInputText, ControlGenericWrapper, ContextSelector},
  setup() {
    const store = useCounterStore();
    const assetsStore = useAssetsStore();

    return {store, assetsStore};
  },
  computed: {
    store(){
      return useCounterStore()
    },
    selectedElement() {
      return this.store.selectedElement;
    },
    linkedAsset(){
      /*
      Get the asset, e.g., an image, linked to the selected element.
       */
      return this.assetsStore.getAsset(this.selectedElement.asset_key)
    },
  },
};
</script>

<style scoped>
.select-asset-button {
  background-color: #252525;
  color: #dcdcdc;
  border: 1px solid #494949;
  border-radius: 4px;
  padding: 4px;
  margin: 4px;
}

.select-asset-button:hover {
  background-color: #494949;
  color: #dcdcdc;
}

.image {
  object-fit: contain;
  max-height: 150px;
}
</style>
