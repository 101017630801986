<template>
  <!--  Selected-->
  <div v-show="!selectedRoot && selectedElement && showHud">
    <PageElementHudTooltip
        :aboveAdjustment="18"
        :belowAdjustment="2"
        :linkElement="selectedElement"
        tooltipType="selected"
        @click="selectElement(selectedElement); toggleShowingAncestors()"
    />

  </div>
  <PageElementHudTooltip
      v-show="!selectedRoot && parentElement && showAncestors"
      :aboveAdjustment="38"
      :belowAdjustment="22"
      :linkElement="parentElement"
      tooltipType="selected"
      @click="selectElement(parentElement); toggleShowingAncestors()"
  />
  <PageElementHudTooltip
      v-show="!selectedRoot && grandparentElement && showAncestors"
      :aboveAdjustment="58"
      :belowAdjustment="42"
      :linkElement="grandparentElement"
      tooltipType="selected"
      @click="selectElement(grandparentElement); toggleShowingAncestors()"
  />
  <div class="element-outline" :style="selectedOutlineStyle"></div>

  <!-- Hover -->
  <PageElementHudTooltip
      v-show="!selectedRoot && hoveredElement && showHud && hoveredElement !== selectedElement"
      tooltipType="hovered"
      :aboveAdjustment="17"
      :belowAdjustment="2"
      :linkElement="hoveredElement"
  />
  <div :style="hoveredOutlineStyle" class="element-outline"></div>
  <!-- End Hover -->


</template>

<script>
import { useCounterStore } from '@/stores/counter'
import { Icon } from "@iconify/vue";
import PageElementHudTooltip from "@/components/PageElementHudTooltip.vue";
import useSelectedElementBounding from "@/composables/useSelectedElementBounding";
import useHoveredElementBounding from "@/composables/useHoveredElementBounding";
import IconComponent from "@/components/IconComponent.vue";


export default {
  name: 'PageElementHud',
  components: {
    IconComponent,
    PageElementHudTooltip,
    Icon
  },
  data() {
    return {
      showAncestors: false
    }
  },
  setup() {
    const selectedBoundingBox = useSelectedElementBounding().boundingBox
    const hoveredBoundingBox = useHoveredElementBounding().boundingBox

    return {
      selectedBoundingBox, hoveredBoundingBox
    }
  },
  computed: {
    store(){
      return useCounterStore();
    },
    hoveredElement() {
      const pid = this.store?.userActivity?.hover.elementPid;
      if (pid){
        return this.store.findElementByPid(pid);
      }
    },
    showHud(){
      return !this.store?.isUserChangingMargins && !this.store.isUserDraggingElement
    },
    selectedRoot() {
      return this.store?.selectedElementPid === this.store?.rootElementPid
    },
    selectedElement() {
      return this.store?.selectedElement;
    },
    parentElement() {
      const parentPid = this.selectedElement?.parentPid
      if (parentPid){
        return this.store?.findElementByPid(parentPid);
      }
    },
    grandparentElement() {
      const grandparentPid = this.parentElement?.parentPid
      if (grandparentPid){
        return this.store?.findElementByPid(grandparentPid);
      }
    },
    selectedOutlineStyle(){
      if (this.store?.selectedElement?.pid && this.store.selectedElement.component_name !== 'PageRoot'){
        const { x, y, top, right, bottom, left, width, height } = this.selectedBoundingBox
        return {
          top: `${top.value}px`,
          left: `${left.value}px`,
          height: `${height.value}px`,
          width: `${width.value}px`,
        }
      }
      return {};

    },
    hoveredOutlineStyle(){
      if (this.store?.userActivity?.hover.elementPid){
        const { x, y, top, right, bottom, left, width, height } = this.hoveredBoundingBox
        return {
          top: `${top.value}px`,
          left: `${left.value}px`,
          height: `${height.value}px`,
          width: `${width.value}px`,
        }
      }
      return {};
    },

  },
  methods: {
    selectElement(element){
      this.store.setSelectedElementPid(element.pid)
    },
    toggleShowingAncestors(){
      this.showAncestors = !this.showAncestors
    },
  },
}
</script>
