<template>
  <div
      :data-page-element-pid="element.pid"
      :data-parent-pid="element.parentPid"
      :key="element.pid"
      class="list-group-item m-0 p-0 text-xs"
      :class="[
        {'bg-blue-500 text-gray-50': isSelected},
        {'bg-gray-200 text-gray-800': isHovering && !isSelected},
      ]"
      @mousedown="select()">

    <div :style="{'padding-left': element.depth + 'em'}" class="flex items-center mb-2">
      <div class="w-[12px]" >
        <button
            v-if="element.hasNest && hasChildren"
            @click.stop="toggleNestOpen">
          <Icon
              :icon="element.isNestOpen ? 'radix-icons:chevron-down' : 'radix-icons:chevron-right'"
              class="mr-1">
          </Icon>
        </button>
      </div>
      <Icon :icon="element.navigatorIcon" class="mr-1"/>
      {{ display_name }}
      <Icon v-show="isDisplayNone" icon="radix-icons:eye-none" class="ml-2"/>
    </div>

  </div>
</template>

<script>
import { Icon } from '@iconify/vue';
import { useCounterStore } from '@/stores/counter';
import _ from 'lodash';
import useElement from "@/composables/useElement";
import {scrollToPid} from "@/utils/workFrameUtils";


export default {
  components: {
    Icon,
  },
  props: {
    element: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const {finalStyle} = useElement(props.element)

    return {
      finalStyle
    }
  },
  computed: {
    isSelected() {
      return this.element.pid === this.store.selectedElementPid
    },
    isHovering() {
      return this.element.pid === this.store.userActivity.hover.elementPid
    },
    store() {
      return useCounterStore();
    },
    isDisplayNone() {
      const display = this.finalStyle?.['display']
      return display === 'none';
    },
    display_name() {
      if (this.element.component_name === 'PageFormState') {
        return `${_.capitalize(this.element.form_state)} state`
      }
      else{
        return this.element.component_name.replace("Page", "")
      }
    },
    hasChildren() {
      return this.store.getActiveChildElements(this.element.pid).length > 0;
    },
  },
  methods: {
    select() {
      this.store.setSelectedElementPid(this.element.pid);
      scrollToPid(this.store.selectedElementPid)
    },
    toggleNestOpen() {
      this.element.isNestOpen = !this.element.isNestOpen;
    },
  },
};
</script>

<style scoped>
.list-group-item {
  cursor: move;
}
</style>
