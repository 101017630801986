import {computed, toValue} from 'vue';
import {useCounterStore} from '@/stores/counter';
import _ from "lodash";
import {computeConnectedCssBlocks, computeFinalCssBlock} from './styleUtils'; // Assuming styleUtils.js is in the same directory
import useUndoRedo from '@/composables/useUndoRedo';

export default function useSelectedElement() {
  const store = useCounterStore();
  const { doWithUndo } = useUndoRedo();

  const selectedElement = computed(() => store.selectedElement);
  const selectedElementFinalCssBlock = computed(() => computeFinalCssBlock(selectedElement.value, store));
  const selectedElementConnectedCssBlocks = computed(() =>  computeConnectedCssBlocks(selectedElement.value, store))


  const removeCssDeclarations = (pattern) => {
    /*
    We remove all local css matching the regex pattern from the selected element at the current breakpoint.
    */
    const breakpointCssProperty = `css_${store.currentBreakpoint}`;
    const prevStyles = { ...selectedElement.value[breakpointCssProperty] };

    const newStyles = _.omitBy(prevStyles, (value, key) => pattern.test(key));
    const forwardFn = () => {
      selectedElement.value[breakpointCssProperty] = newStyles;
    };

    const reverseFn = () => {
      selectedElement.value[breakpointCssProperty] = prevStyles;
    };

    doWithUndo(forwardFn, reverseFn);
  };

  const setLocalCssProperty = (cssProperty, cssValue, options={isChanging: false}) => {
    /*
    We set the given cssProperty to the given cssValue for the selected element.
    */
    const breakpointCssProperty = `css_${store.currentBreakpoint}`;
    const previousCssValue = selectedElement.value[breakpointCssProperty]?.[cssProperty] || '';

    // Create forward and reverse functions.
    const forwardFn = () => {
      selectedElement.value[breakpointCssProperty][cssProperty] = cssValue;
    };
    const reverseFn = () => {
      selectedElement.value[breakpointCssProperty][cssProperty] = previousCssValue;
    };


    // Do forward and reverse functions.
    if (options.isChanging) {
      forwardFn()  // If the user is still changing the value, we don't set an undo step. The user doesn't want to undo by each increment, e.g., a pixel.
    }
    else{
      doWithUndo(forwardFn, reverseFn);
    }
  };

  const unsetLocalCssProperty = (cssProperty) => {
    /*
    Remove the specified cssProperty from the selected element at the current breakpoint.
    */
    const breakpointCssProperty = `css_${store.currentBreakpoint}`;
    const previousCssValue = selectedElement.value[breakpointCssProperty]?.[cssProperty] || '';

    const forwardFn = () => {
      delete selectedElement.value[breakpointCssProperty][cssProperty];
    };
    const reverseFn = () => {
      selectedElement.value[breakpointCssProperty][cssProperty] = previousCssValue;
    };

    doWithUndo(forwardFn, reverseFn);
  };



  const computedCssValue = (cssProperty, defaultValue='0px') => computed(() => {
    return toValue(selectedElementFinalCssBlock)[cssProperty] || defaultValue;
  });

  return {
    selectedElement,
    selectedElementConnectedCssBlocks,
    selectedElementFinalCssBlock,
    computedCssValue,
    setLocalCssProperty,
    unsetLocalCssProperty,
    removeCssDeclarations,
    store,
  };
}
