<script lang="ts">
import {defineComponent, ref, onMounted, PropType} from 'vue'

export default defineComponent({
  props: {
    imageUrl: {
      type: String as PropType<string>,
      required: true
    },
    width: {
      type: Number as PropType<number>,
      default: 200
    },
    height: {
      type: Number as PropType<number>,
      default: 50
    }
  },
  setup() {
    const gradientStyle = ref('')

    const pastelColors = [
      '#FFB3BA', // Pastel Pink
      '#FFDAB9', // Peach
      '#BAE1FF', // Pastel Blue
      '#E6E6FA', // Lavender
      '#D8BFD8', // Thistle
      '#DDA0DD', // Plum
      '#E0FFFF', // Light Cyan
      '#F0EAD6', // Eggshell
      '#DCD0C0', // Pale Gold
      '#C0B283', // Soft Bronze
    ]

    function getRandomPastelColor() {
      return pastelColors[Math.floor(Math.random() * pastelColors.length)]
    }

    function createPastelGradient() {
      const color1 = getRandomPastelColor()
      const color2 = getRandomPastelColor()
      const color3 = getRandomPastelColor()
      const color4 = getRandomPastelColor()
      return `linear-gradient(135deg, ${color1}, ${color2}, ${color3}, ${color4})`
    }

    onMounted(() => {
      gradientStyle.value = createPastelGradient()
    })

    return {gradientStyle}
  }
})
</script>

<template>
  <div
      class="gradient-container"
      :style="{
      backgroundImage: gradientStyle,
      width: `${width}px`,
      height: `${height}px`
    }"
  >
    <img v-if="imageUrl !== ''" :src="imageUrl" :alt="'Gradient Image'" :width="width" :height="height">
  </div>
</template>

<style scoped>
.gradient-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: 400% 400%;
  animation: gradientShift 15s ease infinite;
}

@keyframes gradientShift {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
</style>