<template>
  <input
      ref="domElement"
      :id="pid"
      readonly
      :style="finalStyle"
      class="hintable"
      :class="{
          'top-half-hint': isInsideAndIsGuide && !this.store.userActivity.drag.insertIntoGuide && inTopHalf,
          'bottom-half-hint': isInsideAndIsGuide && !this.store.userActivity.drag.insertIntoGuide && inBottomHalf
        }"
      style="user-select: none; cursor: pointer;"
      @click.stop="select()"
      @mouseover.stop="recordStartHover(pid)"
      @mouseleave.stop="recordEndHover()"
      :placeholder="element.form_input_placeholder"
      :type="element.form_input_type"
  />
</template>

<script>
import useRecordPageElementHover from "@/composables/useRecordPageElementHover.js";
import {computed, ref} from "vue";
import useElement from "@/composables/useElement";
import useRecordMouseover from "@/composables/useRecordMouseover";
import useDragAndDrop from "@/composables/useDragAndDrop";

export default {
  name: 'PageFormInput',
  props: {
    element: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const domElement = ref(null)

    // Start standard page component setup.
    const { store, finalStyle, pid, select } = useElement(props.element)
    const { recordStartHover, recordEndHover } = useRecordPageElementHover();
    const {isOutside, isInside, inBottomHalf, inTopHalf } = useRecordMouseover(
        props.element, domElement
    )

    const isInsideAndIsGuide = computed(() => {
      return isInside.value && store.userActivity.drag.guideElement?.pid === pid.value;
    })
    const showTopHalfHint = computed(() => {
      return isInsideAndIsGuide.value && !store.userActivity.drag.insertIntoGuide && inTopHalf.value
    })
    const showBottomHalfHint = computed(() => {
      return isInsideAndIsGuide.value && !store.userActivity.drag.insertIntoGuide && inBottomHalf.value
    })
    // End standard page component setup.

    const { isDragging } = useDragAndDrop(props.element, domElement);

    return {
      domElement,
      isInsideAndIsGuide, store,
      pid, finalStyle, select,
      recordStartHover, recordEndHover,
      isOutside, isInside,
      inBottomHalf, inTopHalf,
      isDragging,
    }

  },

};
</script>
