<template>
  <Menu v-bind="popperOptions" class="self-center css-label-popper">
    <span class="rounded-sm" :class="labelClass">
      {{ label }}
    </span>

    <template #popper="{ hide }">
      <div class="text-sm p-2">
        {{ label }}

        <div v-if="activeCssOrigin === 'localCssBlock'">
          <div>
            Value set <span class="border has-local-css">locally</span>
          </div>
          <button @click='unsetLocalCssProperty(props.cssProperty)'
                  class="cursor-pointer border px-1 rounded hover:background-blue-300">
            Reset
          </button>
        </div>

        <div v-if="activeCssOrigin === 'inheritedCssBlock'">
          <div>
            Value inherited from ancestor element
            <span class="border hover:cursor-pointer hover:underline has-inherited-style"
                  @click="hide; selectAncestor(this.ancestorPid)">
            {{ this.ancestorPid }}
          </span>

          </div>
        </div>

        <div v-if="activeCssOrigin === 'componentCssBlock'">
          <div>
            Value inherited from <span class="border has-component-css">component default</span>
          </div>
        </div>

      </div>
    </template>
  </Menu>
</template>

<script>
import {computed, reactive, watch} from "vue";
import {useCounterStore} from "@/stores/counter";
import useElement from "@/composables/useElement";
import _ from "lodash";
import {Menu} from 'floating-vue';
import 'floating-vue/dist/style.css';
import useSelectedElement from "@/composables/useSelectedElement";
import {useToast} from "vue-toastification";

export default {
  components: { Menu },
  props: {
    cssProperty: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const {
      selectedElement,
      localCssBlock,
      selectedElementConnectedCssBlocks,
      select,
      store,
    } = useSelectedElement();

    const toast = useToast()

    const { unsetLocalCssProperty } = useSelectedElement();

    return {
      selectedElement,
      unsetLocalCssProperty,
      localCssBlock,
      selectedElementConnectedCssBlocks,
      select,
      props,
      store,
      toast
    };
  },
  computed: {
    activeCssOrigin() {
      /*
      Returns the origin of the active css property.
      */
      const cssBlockKeys = [
        'localCssBlock', 'componentCssBlock', 'inheritedCssBlock'
      ]
      return cssBlockKeys.find(key => this.selectedElementConnectedCssBlocks[key]?.[this.cssProperty]) || '';
    },
    labelClass() {
      /*
      Maps activeCssOrigin to a css class.
       */
      return {
        'localCssBlock': 'has-local-css',
        'inheritedCssBlock': 'has-inherited-style',
        'componentCssBlock': 'has-component-css'
      }[this.activeCssOrigin] || '';
    },
    ancestorPid() {
      /*
      Returns the pid of the element that is the source of the inherited style.
       */
      const inheritedStyles = this.store.getInheritedStyles(this.store.selectedElement);
      return _.get(inheritedStyles, [this.props.cssProperty, 'origin'], '')
    },
    popperOptions() {
      return {
        placement: 'top-start',
        instantMove: true,
        popperClass: 'label-popper',
      };
    },
  },
  methods: {
    selectAncestor(ancestorPid) {
      const ancestor = this.store.findElementByPid(ancestorPid);
      this.toast.info(
          `Selected ancestor ${ancestor.component_name} component`,
          {'position': 'bottom-right', timeout: 5000}
      );
      this.store.setSelectedElementPid(this.ancestorPid);
    }
  }
};
</script>>

<style scoped>
.has-inherited-style {
  background-color: rgba(20, 86, 115, 0.12);
  color: rgb(88, 155, 255);

}

.has-local-css {
  background-color: #dcedff;
  color: rgba(58, 115, 239, 0.8);
}

.has-component-css {
  background-color: rgba(255, 120, 79, 0.12);
  color: #ffab6a;
}
</style>

<style>
.label-popper{
  max-width: 150px;
}
</style>
