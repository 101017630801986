import {ref, watch} from 'vue';
import {useDebounceFn} from '@vueuse/core';
import {useCounterStore} from "@/stores/counter";

export default function useAutoSave(pageId) {
  const store = useCounterStore();
  const saveStatus = ref(null);
  const lastSaved = ref(null);
  const unsavedChanges = ref(false);

  const handleSave = async () => {
    const response = await store.savePageElementsToBackend(pageId);
    if (response instanceof Error) {
      saveStatus.value = { success: false, response: response };
      unsavedChanges.value = true;
    } else {
      saveStatus.value = { success: true, response: response };
      lastSaved.value = new Date();
      unsavedChanges.value = false;
    }
  };

  const debouncedSave = useDebounceFn(handleSave, 2000);

  watch(() => store.selectedElement, async (newSelectedElement, oldSelectedElement) => {
    /*
    After the elements have been loaded from the backend, we start auto-saving.
     */
    if (!oldSelectedElement?.pid) { // We don't want to save when we first load the page.
      return
    }
    if(oldSelectedElement?.pid === newSelectedElement?.pid) {
      unsavedChanges.value = true;
      await debouncedSave();
    }
  }, { deep: true });

  return { saveStatus, lastSaved, unsavedChanges };
}

