import _ from 'lodash';


export function updateActiveSiblingOrder(store, element, excludedElement = null) {
  /* 
  This function recalculates the order of the siblings of a given element.
  */
  if (_.isEmpty(element.parentPid) && element.component_name !== 'PageRoot'){
    throw new Error(`updateActiveSiblingsOrder :: Element ${JSON.stringify(element)} has no parentPid`)
  }
  return updateActiveChildOrder(store, element.parentPid, excludedElement = null)
}

export function updateActiveChildOrder(store, parentPid, excludedElement = null) {
  /*
    This function recalculates the order of the children of a given element.
    We call this, for example, when we move or delete an element.

    We have the ability to exclude an element from sequencing. We use this, for example,
    when moving the element from its siblings.
   */
  // store = store || useCounterStore();
  const siblings = store.activeElements
      .filter(el => el.parentPid === parentPid && el.pid !== excludedElement?.pid);

  return sequenceElements(siblings); // We update the elements in the store in place here with Vue3's reactivity.
}

export function sequenceElements(elements) {
  /*
  We use this function to ensure that all of a parent's elements
  are ordered in a continuous sequence.
  */
  elements.sort((a, b) => a.order - b.order);

  // Reset their order values.
  for (let i = 0; i < elements.length; i++) {
    elements[i].order = i;
  }

  return elements
}


export function getOffsetBoundingRect(elementPid) {
  const iframe = document.querySelector('iframe');
  if (!iframe || !iframe.contentDocument) {
    return null;
  }

  const domElement = iframe.contentDocument.getElementById(elementPid);

  if (domElement ) {
    const rect = domElement.getBoundingClientRect();
    return {
      offsetHeight: domElement.offsetHeight,
      offsetWidth: domElement.offsetWidth,
      offsetTop: rect.top,
      offsetLeft: rect.left
    }
  }
}
