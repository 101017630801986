<script lang="ts">
import {computed, defineComponent, ref} from 'vue';
import {useCompletionsStore} from '@/stores/completions';
import IconComponent from '@/components/IconComponent.vue';
import {Completion} from '@/utils/completionUtils';
import GradientImage from "@/components/GradientImage.vue";

export default defineComponent({
  components: {GradientImage, IconComponent},
  setup() {
    const store = useCompletionsStore();
    const scrollContainer = ref<HTMLElement | null>(null);
    const flashIndex = ref<number | null>(null);
    const pageStartIndex = ref(0);
    const pageSize = ref(3);

    const pageCompletions = computed(() => {
      const start = pageStartIndex.value
      const end = start + pageSize.value;
      return store.completions.slice(start, end);
    });

    const changePage = (increment: number) => {
      const nextPageIndex = pageStartIndex.value + increment;
      const hasNextPage = nextPageIndex + pageSize.value <= store.completions.length

      if (nextPageIndex < 0) {
        pageStartIndex.value = 0;
      } else if (!hasNextPage) {
        return
      } else {
        pageStartIndex.value = nextPageIndex;
      }
    };

    const goToLastPage = () => {
      const lastPageStartIndex = store.completions.length - pageSize.value;
      pageStartIndex.value = lastPageStartIndex;
    };

    return {
      store,
      scrollContainer,
      changePage,
      pageCompletions,
      flashIndex,
      pageStartIndex,
      pageSize,
      goToLastPage,
    };
  },
  methods: {
    emitCompletionId(completion: Completion, index: number) {
      if (!completion.id) {
        throw new Error('Completion :: No completion id.');
      }
      this.flashIndex = index;
      setTimeout(() => {
        this.flashIndex = null;
      }, 500);
      this.$emit('select:completion-id', completion.id);
    },
    imageClass(index: number, completion: Completion) {
      return {
        'opacity-50 transition duration-500 ease-in-out': this.flashIndex === index,
      }
    },
    scrollToFinal() {
      const completion = this.store.completions[this.store.completions.length - 1];
      const completionElement = this.$refs.scrollContainer?.querySelector(`[data-completion-id="${completion.id}"]`) as HTMLElement;
      if (completionElement) {
        completionElement.scrollIntoView({behavior: 'smooth', block: 'center'});
      }
    }
  },

});
</script>

<template>
  <div class="flex items-center select-none">
    <button class="btn btn-ghost btn-circle btn-xs p-1 text-gray-500" @click="changePage(-1)"
            :class="{ 'opacity-50 btn-disabled': pageStartIndex === 0 }"
    >
      <IconComponent name="before"/>
    </button>


    <div ref="scrollContainer" class="flex overflow-x-auto items-center h-full justify-center">
      <div
          v-for="(completion, index) in pageCompletions"
          :key="index"
          @click="emitCompletionId(completion, index)"
          :data-completion-id="completion.id"
          class="indicator flex-none w-full md:w-1/3 px-2 cursor-pointer flex-col sm:flex-row"

      >
        <span class="indicator-item indicator-center indicator-bottom badge badge-ghost text-xs p-1 opacity-60"
              :class="{ 'opacity-100 bg-indigo-400 text-white': store.selectedCompletionId === completion.id}"
        >
          {{ pageStartIndex + index }}
        </span>

        <GradientImage :image-url="completion.screenshot_url"
                       class="w-full h-auto rounded-lg object-cover hover:border transition-all"
        />
      </div>
    </div>

    <button class="btn btn-ghost btn-circle btn-xs p-1 text-gray-500" @click="changePage(1)"
            :class="{ 'opacity-50 btn-disabled': pageStartIndex + pageSize >= store.completions.length }"
    >
      <IconComponent name="next"/>
    </button>
  </div>
</template>

<style scoped>
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
</style>