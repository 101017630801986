import {computed, nextTick, reactive, ref, watch} from 'vue';
import {defineStore} from 'pinia'
import _ from 'lodash';
import {useFetchJson} from "@/composables/useFetchJson";
import {ComponentName, createPageComponentsFromBackend} from "@/utils/elementCreator";
import {updateComputedStyle} from "@/stores/store_utils";
import {CommandInvoker, SetElementValueCommand} from "@/stores/undo";
import {BACKEND_PATHS, BREAKPOINTS, INHERITABLE_CSS_PROPERTIES} from "@/constants";
import sharedConstants from "../../../shared_constants.json";
import useLoadingCheck from "@/composables/useLoadingCheck";
import {signOut} from "@/utils/apiRequests";

const initialState = {
  selectedElementPid: null,
  rootElementPid: null,
  elements: [],
  areElementsLoaded: false,
  componentCssStylesheet: {},
  context: {},
  userActivity: {
    drag: {
      element: null,
      insertAfterGuide: false,
      insertBeforeGuide: false,
      insertIntoGuide: false,
      oldDepth: null,
      oldIndex: null,
      oldParentPid: null,
      pid: null,
    },
    mouseover: {
      pid: null, element: null,
    },
    margins: {
      'margin-top': {isChanging: false}, 'margin-bottom': {isChanging: false},
      'margin-left': {isChanging: false}, 'margin-right': {isChanging: false}
    },
    hover: {
      elementPid: '',
      domRect: {offsetHeight: 0, offsetWidth: 0, offsetTop: 0, offsetLeft: 0}
    },
    pressingKeys: {
      alt: false
    }
  },
  commandInvoker: new CommandInvoker(),
  leftSidebar: {
    isShowing: false, selectedPane: "",
  },
  elementEditor: {
    selectedTabName: 'style',
  },
  workFrame: null,
  dims: {
    browser: {width: 0, height: 0},
    navbar: {height: 30},
    workEasel: {width: 0},
    workFrame: {width: 992, height: 0},
    leftSidebar: {width: 0, height: 0,},
    rightSidebar: {width: 0, height: 0,},
  }
}


export const useCounterStore = defineStore('counter', () => {
  const selectedElementPid = ref(initialState.selectedElementPid)
  const rootElementPid = ref(initialState.rootElementPid)

  const elements = ref(initialState.elements)
  const areElementsLoaded = ref(initialState.areElementsLoaded) // Whether we have loaded elements from the backend.

  const componentCssStylesheet = ref(initialState.componentCssStylesheet)

  const context = ref(initialState.context);
  const userActivity = ref(initialState.userActivity);
  const commandInvoker = reactive(initialState.commandInvoker);

  const leftSidebar = reactive(initialState.leftSidebar)
  const elementEditor = reactive(initialState.elementEditor)
  const workFrame = ref(initialState.workFrame) // The iframe that contains the page that the user is editing.
  const dims = ref(initialState.dims)



/// Getter functions. --------------------------------------------------------
  const workFrameScalingFactor = computed(() => {
    /*
    We scale the page that the user is working on when the workFrame is too small
    to fit in the remaining space at 100% size.
    */
    const permittedCentralPanelWidth = (
        dims.value.browser.width // This varies.
        - sharedConstants.PANEL_DIMENSIONS.left_sidebar.width // This is fixed.
        - sharedConstants.PANEL_DIMENSIONS.right_sidebar.width // This is fixed.
        - 40 // Extra space for padding.
    )
    const scalingFactor =  permittedCentralPanelWidth / dims.value.workFrame.width

    return scalingFactor < 1 ? scalingFactor : 1;
  })

  const isUserDraggingElement = computed(() => {
    return userActivity.value.drag.pid !== null;
  })

  const isUserChangingMargins = computed(() => {
    return Object.values(userActivity.value.margins).some(margin => margin.isChanging);
  })


  const canUndo = () => commandInvoker.canUndo()

  const canRedo = () => commandInvoker.canRedo()

  const currentBreakpoint = computed(() => {
    if (dims.value.workFrame.width <= BREAKPOINTS.mobile['max-width']) {
      return 'mobile'
    }
    if (dims.value.workFrame.width <= BREAKPOINTS.tablet['max-width']) {
      return 'tablet'
    }
    return 'desktop'
  })
  const currentBreakpointMinWidth = computed(() => {
    return BREAKPOINTS[currentBreakpoint.value]['min-width']
  })
  const currentBreakpointMaxWidth = computed(() => {
    return BREAKPOINTS[currentBreakpoint.value]['max-width']
  })

  const computedWithLoadingCheck = useLoadingCheck(areElementsLoaded)

  const rootElement = computedWithLoadingCheck(() => {
    return findElementByPid(rootElementPid.value)
  })

  const selectedElement = computedWithLoadingCheck(() => {
        return selectedElementPid.value ?
            findElementByPid(selectedElementPid.value) : null
      }
  )

  const activeElements = computedWithLoadingCheck(() => {
    /*
    Returns all elements that are not deleted.
     */
    return elements.value.filter(element => !element.is_deleted)
  })

  const elementsWithoutComponents = computed(() => {
    /*
    We return the element data that we want to save to the backend.
     */
    return elements.value.map(element => {
      const { component, ...data } = element;  // Exclude the 'component' key from the data
      return data;
    });
  })

  const orderedElements = computedWithLoadingCheck(() => {
    const orderedElementsList = [];

    function traverse(parentPid) {
      const children = activeElements.value
          .filter(el => el.parentPid === parentPid)
          .sort((a, b) => a.order - b.order);

      const parent = activeElements.value.find(el => el.pid === parentPid);


      if (parent?.isNestOpen) {
        for (const child of children) {
          orderedElementsList.push(child);
          traverse(child.pid);
        }
      }
    }

    traverse(rootElementPid.value);
    if (orderedElementsList.length !== activeElements.value.length - 1) { // -1 because the root element is not included.
      console.warn(
          `orderedElements :: ` +
          `The length of the orderedElementsList (${orderedElementsList.length})` +
          `should equal the activeElements.value.length (${activeElements.value.length}). ` +
          `There may be child elements in a parent element with a nest that is not open.`
      )
    }
    return orderedElementsList;
  });

  function getActiveChildElements(pid) {
    /*
    Get all the child elements of an element that are not deleted.
     */
    return activeElements.value
        .filter(
            element =>
                element.parentPid === pid &&
                element.component_name !== ComponentName.PageRoot
        )
        .sort((a, b) => a.order - b.order)
  }

  const getActiveDescendants = (pid) => {
    /*
    Returns a flat array of all active descendants of an element.
     */
    return _.flatMap(
        getActiveChildElements(pid), child => {
          return [child, ...getActiveDescendants(child.pid)];
        }
    );
  }

  function findElementByPid(pid) {
    if (!pid) {
      throw new Error(`findElementByPid :: pid is null`)
    }

    // Wait until the elements are loaded.
    if (!areElementsLoaded.value) {
      console.warn(
          "findElementByPid :: you are calling this function before the elements are loaded. " +
          "Update our code to wait for the elements to load."
      )
    }

    const foundEl = elements.value.find(element => element.pid === pid);
    if (!foundEl) {
      console.warn(`findElementByPid :: element not found for pid: '${pid}'`);
    }

    return foundEl
  }

  function hasElement(pid) {
    return elements.value.some(element => element.pid === pid)
  }

  function getRootElement() {
    if (rootElementPid.value) {
      return findElementByPid(rootElementPid.value)
    }
    return null;
  }

  function findClosestNonDeletedParent(element){
    if (element.parentPid){
      const parent = findElementByPid(element.parentPid);
      if (parent.is_deleted){
        return findClosestNonDeletedParent(parent);
      }
      return parent;
    }
    return null;
  }



  const getInheritedStyles = (startElement) => {
    /*
    Returns an object showing which styles are inherited from which parent.
    We only include inheritable css properties: https://www.w3.org/TR/CSS21/propidx.html

    Elements inherit styles:
      a) from their ancestors (both component_css and local_css), and
      b) at the present breakpoint.

    @returns: inheritedStyles = {
        property_name: {
            origin: pid,
            value: value
        }
    }
    */
    const inheritedStyles = {};
    let currentElement = startElement;
    const currentBreakpointCss = `css_${currentBreakpoint.value}`


    while (currentElement?.parentPid && areElementsLoaded.value) {
      const parentElement = findElementByPid(currentElement.parentPid);

      const parentLocalCss = parentElement[currentBreakpointCss] || null;
      const parentComponentCss = componentCssStylesheet.value[parentElement.component_name] || null;

      const parentCss = {...parentComponentCss, ...parentLocalCss};

      if (parentCss) {
        for (const property in parentCss) {
          const isOrigin = !inheritedStyles[property];
          const isInheritable = INHERITABLE_CSS_PROPERTIES.includes(property);

          if (isOrigin && isInheritable) {
            inheritedStyles[property] = {
              origin: parentElement.pid,
              value: parentCss[property]
            }
          }
        }
      }

      currentElement = parentElement;
    }

    return inheritedStyles;
  }

  const selectedElementInheritedCssBlocks = computedWithLoadingCheck(() => {
    return getInheritedStyles(selectedElement.value);
  })

  const selectedElementComponentCssBlock = computedWithLoadingCheck(() =>
      _.get(componentCssStylesheet.value, [selectedElement.value?.component_name], {})
  );

  const selectedElementInheritedCssBlock = computedWithLoadingCheck(() => {
    return _.mapValues(getInheritedStyles(selectedElement).value, 'value')
  })

  const selectedElementLocalCssBlock = computedWithLoadingCheck(() => {
    /*
    We return the localCssBlock for the current breakpoint.
     */
    if (_.isNil(selectedElement.value)){return {}}

    if (currentBreakpoint.value === 'desktop') {
      return selectedElement.value.css_desktop
    }
    else if (currentBreakpoint.value === 'tablet') {
      return { ...selectedElement.value.css_desktop, ...selectedElement.value.css_tablet};
    }
    else if (currentBreakpoint.value === 'mobile') {
      return { ...selectedElement.value.css_desktop, ...selectedElement.value.css_tablet, ...selectedElement.value.css_mobile };
    }
  })

  const selectedElementFinalCssBlock = computedWithLoadingCheck(() => {
    return {
      ...selectedElementComponentCssBlock.value,
      ...selectedElementInheritedCssBlock.value,
      ...selectedElementLocalCssBlock.value,
    }
  })



// Setter functions  ---------------------------------------------------------
  function resetState() {
    /*
    Reset the state to the initial state.
     */
    for (const key in initialState){
      if (!initialState.hasOwnProperty(key)){
        throw new Error(`resetState :: key '${key}' not found in initialState`)
      }
      this[key] = initialState[key];
    }
  }

  function setLeftSidebar(params) {
    if ('isShowing' in params) {
      leftSidebar.isShowing = params.isShowing;
    }
    if ('selectedPane' in params) {
      leftSidebar.selectedPane = params.selectedPane;
    }
  }

  function setLeftSidebarToDefault() {
    setLeftSidebar({isShowing: true, selectedPane: 'LeftPanelNavigator'})
  }

  function setElementEditor(params) {
    if ('selectedTabName' in params) {
      elementEditor.selectedTabName = params.selectedTabName;
    }
  }


  function addElement(newElement, parentPid) {
    /*
    Add a new element to the store elements array.
     */
    const parentElement = findElementByPid(parentPid);
    if(parentElement){
      newElement.depth = parentElement.depth + 1;
      newElement.parentPid = parentPid;
    }
    else{
      newElement.depth = 0;
      newElement.parentPid = null;
    }
    elements.value.push(newElement);
  }


  function setSelectedElementPid(pid) {
    selectedElementPid.value = pid
  }

  function selectRootElement() {
    setSelectedElementPid(rootElementPid.value)
  }

  function setUserActivity(keys, val) {
    return _.set(userActivity.value, keys, val)
  }

  function setElementValue(pid, keys, value) {
    /*
    Set any value on any element.
    */
    const command = new SetElementValueCommand(this, pid, keys, value);
    commandInvoker.do(command);
  }



// Backend interactions.-------------------------------------------------------
  async function loadPageElements(projectId, pageId) {
    /*
    We create all page elements using on the data from the backend.
     */
    const url = `${import.meta.env.VITE_OX_URL}/${BACKEND_PATHS.ELEMENTS(pageId)}`
    const { data, error } = await useFetchJson(url)

    if (!error.value) {
      const rootElement = data.value.find(element => element.component_name === ComponentName.PageRoot);
      if (rootElement){
        rootElementPid.value = rootElement.pid;
        elements.value = createPageComponentsFromBackend(data.value);
        areElementsLoaded.value = true;
        import.meta.env.DEV ? console.info(`🌳 Loaded elements for page ${pageId}.`) : null;
      }
      else{
        console.error("No root element found. Are you connected to the server?")
      }
    } else {
      if (error.value.status === 401){
        window.location.href = `${import.meta.env.VITE_OX_URL}/${BACKEND_PATHS.SIGN_IN()}`
      }
      console.error('Failed to load page elements:', error?.value);
    }
  }

  function addPageElementsFromJson(data) {
    /*
    We create all page elements using on the data from the backend.
     */
    const newElements = createPageComponentsFromBackend(data);
    elements.value.push(...newElements);
    return newElements;
  }


  async function loadComponentCssStylesheet(projectId) {
    /*
    We load all component css from the backend.
     */
    const url = `${import.meta.env.VITE_OX_URL}/${BACKEND_PATHS.COMPONENT_CSS(projectId)}`
    const { data, error } = await useFetchJson(url)

    if (!error.value) {
      componentCssStylesheet.value = data.value.component_css_blocks;
      import.meta.env.DEV ? console.info(`⛪️ Loaded component css for project ${projectId}.`) : null;
      return data;
    } else {
      console.error('Failed to load base style:', error);
    }
  }


  async function savePageElementsToBackend(pageId) {
    /*
    Save or update all elements to the backend.
     */
    const elementsToSend = getElementsToSendToBackend()
    if (elementsToSend.toCreate.length > 0){
      await createElementsInBackend(pageId, elementsToSend.toCreate)
    }
    if (elementsToSend.toUpdate.length > 0){
      await updateElementsInBackend(pageId, elementsToSend.toUpdate)
    }
  }

  async function createElementsInBackend(pageId, elementsToCreate) {
    /*
    Send the elements to the backend and update the elements with the database ids.
     */
    const newlySavedElements = await postElementsToBackend(pageId, elementsToCreate, 'create')

    if (newlySavedElements){
      newlySavedElements.forEach(newlySavedElement => {
        const element = findElementByPid(newlySavedElement.pid)
        if (!element.hasOwnProperty('db_id')){
          throw new Error(
              "Element does not have a 'db_id' property. " +
              "All elements must have a 'db_id' property.")
        }
        element.db_id = newlySavedElement.db_id
      })
    }
  }

  async function updateElementsInBackend(projectId, elementsToUpdate) {
    await postElementsToBackend(projectId, elementsToUpdate, 'update')
  }

  async function postElementsToBackend(pageId, elements, operation) {
    const url = `${import.meta.env.VITE_OX_URL}/${BACKEND_PATHS.ELEMENTS_OPERATION(pageId, operation)}`
    const options = {method: 'POST', body: JSON.stringify(elements)}
    const { data, error } = await useFetchJson(url, options)

    if (!error.value) {
      return data.value
    } else {
      console.error('Error saving elements:', error.value);
      return error.value
    }
  }

  function getElementsToSendToBackend() {
    /*
    Returns all elements that are already in the database or not deleted.
     */
    const elementsToSave = {
      toUpdate: [],
      toCreate: [],
    }
    const isUnsavedElement = (element) => {
      if (!element.hasOwnProperty('db_id')){
        throw new Error("Element does not have a 'db_id' property. All elements must have a 'db_id' property.")
      }
      return _.isNil(element.db_id)
    }

    for (const element of elements.value){
      if (isUnsavedElement(element)){
        elementsToSave.toCreate.push(element);
      }
      else{
        elementsToSave.toUpdate.push(element);
      }
    }

    return elementsToSave
  }


  // Watchers. -----------------------------------------------------------------

  function updateSelectedElementComputedStyle() {
    /*
    Update the selected element's computed style.
    We do this because the computed style is not reactive.
     */
    updateComputedStyle(selectedElement);
  }

  function watchSelectedElementForStyleAndPosition() {
    /*
    Watch the selected element and update its computed style.
    */

    const orderChange = () => selectedElement.value?.order
    const parentChange = () => selectedElement.value?.parentPid
    const isDeletedChange = () => selectedElement.value?.is_deleted


    watch(
        [orderChange, parentChange, isDeletedChange, selectedElementFinalCssBlock],
        async () => {
          if (selectedElement.value){
            await nextTick(); // Wait for the DOM to update.
            updateSelectedElementComputedStyle();
          }
        },
        { deep: true, immediate: true }  // deep watch and immediate invoke.
    );
  }

  function undo() {
    commandInvoker.undo();
  }

  function redo() {
    commandInvoker.redo();
  }

  watchSelectedElementForStyleAndPosition();


// Public API. ---------------------------------------------------------------


  return {
    resetState,

    rootElementPid,
    rootElement,
    getRootElement,
    findElementByPid,
    hasElement,
    selectedElementPid,
    selectedElement,
    setSelectedElementPid,
    selectRootElement,
    areElementsLoaded,
    elements,

    activeElements,
    orderedElements,
    elementsWithoutComponents,

    addElement,
    setElementValue,
    getActiveChildElements,
    getActiveDescendants,
    componentCssStylesheet,
    addPageElementsFromJson,


    context,


    userActivity,
    setUserActivity,
    isUserChangingMargins,
    isUserDraggingElement,

    loadPageElements,
    loadComponentCssStylesheet,
    savePageElementsToBackend,
    findClosestNonDeletedParent,
    updateSelectedElementComputedStyle,
    undo,
    canUndo,
    redo,
    canRedo,
    commandInvoker,
    setLeftSidebar,
    setLeftSidebarToDefault,
    elementEditor,
    setElementEditor,
    leftSidebar,

    selectedElementInheritedCssBlocks,
    getInheritedStyles,
    selectedElementFinalCssBlock,
    selectedElementComponentCssBlock,
    selectedElementLocalCssBlock,
    selectedElementInheritedCssBlock,

    workFrame,
    workFrameScalingFactor,
    dims,
    currentBreakpoint,
    currentBreakpointMaxWidth,
    currentBreakpointMinWidth,

    createElementsInBackend,
    postElementsToBackend
  }
})
