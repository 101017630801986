<template>
  <ControlGenericWrapper v-if="selectedElement" >
    <template v-slot:label>
      Spacing
    </template>

    <template v-slot:content>
      <div class="spacing-grid border relative select-none">
        <svg style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; pointer-events: none;">
          <text alignment-baseline="middle" fill="lightgray" font-family="Arial" font-size="8" text-anchor="start" x="1%" y="5%">
            MARGIN
          </text>
          <rect fill="none" height="65%" rx="0.5%" ry="0.5%" stroke="white" stroke-width="1" width="60%" x="20%" y="18%"></rect>
          <text alignment-baseline="middle" fill="lightgray" font-family="Arial" font-size="8" text-anchor="start" x="21%" y="23%">
            PADDING
          </text>
        </svg>

        <div class="cursor-pointer relative row-start-1 col-start-5 text-center"
             @click="showingSpacingModal = true; selectedSpacingProperty = 'margin-top';"
        >

          <div class="num-unit-display">
            <ControlSpacingNumUnitDisplay css-property="margin-top"/>
          </div>

          <ControlSpacingPopout
              class="inset-x-0 select-none"
              :showing="showingSpacingModal && selectedSpacingProperty === 'margin-top'"
              :num="computedNum('margin-top').value"
              :unit="computedUnit('margin-top').value"
              @close-modal="hideSpacingModal"
              @update-spacing="setSpacingProperty($event)"
          />
        </div>

        <div class="cursor-pointer relative row-start-5 col-start-9 text-center"
             @click="showingSpacingModal = true; selectedSpacingProperty = 'margin-right';">
          <div class="num-unit-display horizontal-display">
            <ControlSpacingNumUnitDisplay css-property="margin-right"/>
          </div>
          <ControlSpacingPopout
              class="right-0 inset-y-0 select-none"
              :showing="showingSpacingModal && selectedSpacingProperty === 'margin-right'"
              :num="computedNum('margin-right').value"
              :unit="computedUnit('margin-right').value"
              @close-modal="hideSpacingModal"
              @update-spacing="setSpacingProperty($event)"
          />
        </div>

        <div class="cursor-pointer relative margin-bottom-controller text-center"
             @click="showingSpacingModal = true; selectedSpacingProperty = 'margin-bottom';">

          <div class="num-unit-display">
            <ControlSpacingNumUnitDisplay css-property="margin-bottom"/>
          </div>

          <ControlSpacingPopout
              class="inset-x-0 select-none"
              :showing="showingSpacingModal && selectedSpacingProperty === 'margin-bottom'"
              :num="computedNum('margin-bottom').value"
              :unit="computedUnit('margin-bottom').value"
              @close-modal="hideSpacingModal"
              @update-spacing="setSpacingProperty($event)"
          />
        </div>

        <div class="cursor-pointer relative row-start-5 col-start-1 text-center"
             @click="showingSpacingModal = true; selectedSpacingProperty = 'margin-left';">

          <div class="num-unit-display horizontal-display">
            <ControlSpacingNumUnitDisplay css-property="margin-left"/>
          </div>

          <ControlSpacingPopout
              class="left-0 inset-y-0 select-none"
              :showing="showingSpacingModal && selectedSpacingProperty === 'margin-left'"
              :num="computedNum('margin-left').value"
              :unit="computedUnit('margin-left').value"
              @close-modal="hideSpacingModal"
              @update-spacing="setSpacingProperty($event)"
          />
        </div>

        <div class="cursor-pointer relative row-start-3 col-start-5 text-center"
             @click="showingSpacingModal = true; selectedSpacingProperty = 'padding-top';">
          <div class="num-unit-display absolute">
            <ControlSpacingNumUnitDisplay css-property="padding-top"/>
          </div>
          <ControlSpacingPopout
              class="inset-0 select-none"
              :showing="showingSpacingModal && selectedSpacingProperty === 'padding-top'"
              :num="computedNum('padding-top').value"
              :unit="computedUnit('padding-top').value"
              @close-modal="hideSpacingModal"
              @update-spacing="setSpacingProperty($event)"
          />
        </div>


        <div class="cursor-pointer relative row-start-5 col-start-7 text-right"
             @click="showingSpacingModal = true; selectedSpacingProperty = 'padding-right';">
          <div class="num-unit-display horizontal-display">
            <ControlSpacingNumUnitDisplay css-property="padding-right"/>
          </div>
          <ControlSpacingPopout
              class="inset-0 select-none"
              :showing="showingSpacingModal && selectedSpacingProperty === 'padding-right'"
              :num="computedNum('padding-right').value"
              :unit="computedUnit('padding-right').value"
              @close-modal="hideSpacingModal"
              @update-spacing="setSpacingProperty($event)"
          />
        </div>



        <div
            class="cursor-pointer relative row-start-7 col-start-5 text-center"
            @click="showingSpacingModal = true; selectedSpacingProperty = 'padding-bottom';"
        >
          <div class="num-unit-display">
            <ControlSpacingNumUnitDisplay css-property="padding-bottom"/>
          </div>
          <ControlSpacingPopout
              class="inset-0 select-none"
              :showing="showingSpacingModal && selectedSpacingProperty === 'padding-bottom'"
              :num="computedNum('padding-bottom').value"
              :unit="computedUnit('padding-bottom').value"
              @close-modal="hideSpacingModal"
              @update-spacing="setSpacingProperty($event)"
          />
        </div>

        <div class="cursor-pointer relative row-start-5 col-start-3 text-left"
             @click="showingSpacingModal = true; selectedSpacingProperty = 'padding-left';"
        >
          <div class="num-unit-display horizontal-display">
            <ControlSpacingNumUnitDisplay css-property="padding-left"/>
          </div>
          <ControlSpacingPopout
              :num="computedNum('padding-left').value"
              :showing="showingSpacingModal && selectedSpacingProperty === 'padding-left'"
              :unit="computedUnit('padding-left').value"
              class="inset-0 select-none"
              @close-modal="hideSpacingModal"
              @update-spacing="setSpacingProperty($event)"
          />
        </div>
      </div>
    </template>
  </ControlGenericWrapper>
</template>

<script>
import GenericInputRange from "@/components/GenericInputRange.vue";
import GenericInputNumUnit from "@/components/GenericInputNumUnit.vue";
import useSelectedElement from "@/composables/useSelectedElement";
import parseCss from "@/utils/parseCss";
import {onClickOutside} from '@vueuse/core'
import {computed, ref, toValue} from "vue";
import ControlSpacingPopout from "@/components/ControlSpacingPopout.vue";
import useUndoRedo from "@/composables/useUndoRedo";
import _ from "lodash";
import {useCounterStore} from "@/stores/counter";
import getCssNumAndUnit from "@/utils/parseCss";
import ControlGenericWrapper from "@/components/ControlGenericWrapper.vue";
import ControlSpacingNumUnitDisplay from "@/components/ControlSpacingNumUnitDisplay.vue";


export default {
  components: {
    ControlSpacingNumUnitDisplay,
    ControlGenericWrapper,
    ControlSpacingPopout,
    GenericInputNumUnit, GenericInputRange
  },
  data() {
    return {
      selectedSpacingProperty: "",
      showingSpacingModal: false,
    }
  },
  setup() {
    const store = useCounterStore();
    const { selectedElement, selectedElementStyle, selectedElementFinalCssBlock} = useSelectedElement();
    const {getCssNumAndUnit} = parseCss()

    const computedNum = (cssProperty, defaultValue='0px') => computed(() => {
      return getCssNumAndUnit(store.selectedElementFinalCssBlock[cssProperty] || defaultValue).num;
    });

    const computedUnit = (cssProperty, defaultValue='0px') => computed(() => {
      return getCssNumAndUnit(store.selectedElementFinalCssBlock[cssProperty] || defaultValue).unit;
    });

    const { doWithUndo } = useUndoRedo();

    const target = ref(null)
    onClickOutside(target, () => {})


    return {
      store, selectedElement, selectedElementStyle, selectedElementFinalCssBlock, getCssNumAndUnit, target, doWithUndo,
      computedNum, computedUnit

    };
  },
  methods: {
    setSpacingProperty(inputEvent) {
      const breakpointCssProperty = `css_${toValue(this.store.currentBreakpoint)}`;
      const alsoModifyTwin = this.store.userActivity.pressingKeys.alt

      const twinProperties = {
        'padding-left': 'padding-right',
        'padding-right': 'padding-left',
        'padding-top': 'padding-bottom',
        'padding-bottom': 'padding-top',
        'margin-left': 'margin-right',
        'margin-right': 'margin-left',
        'margin-top': 'margin-bottom',
        'margin-bottom': 'margin-top',
      };
      const twinSpacingProperty = twinProperties[this.selectedSpacingProperty]


      const forwardFn = () => {
        this.selectedElement[breakpointCssProperty][this.selectedSpacingProperty] = inputEvent.css.val;
        if (alsoModifyTwin) {
          this.selectedElement[breakpointCssProperty][twinSpacingProperty] = inputEvent.css.val;
        }
      }
      const reverseFn = () => {
        this.selectedElement[breakpointCssProperty][this.selectedSpacingProperty] = inputEvent.css.previousVal;
        if (alsoModifyTwin) {
          this.selectedElement[breakpointCssProperty][twinSpacingProperty] = inputEvent.css.previousVal;
        }
      }

      if (inputEvent.isChanging) {
        forwardFn()  // If the user is still changing the value, we don't set an undo step. The user doesn't want to undo by each step, e.g., a pixel.
      }
      else{
        this.doWithUndo(forwardFn, reverseFn);
      }
    },
    hideSpacingModal() {
      this.showingSpacingModal = false;
    },
  },
}
</script>

<style scoped>

.spacing-grid {
  display: grid;
  padding: 0 4px;
  grid-template-rows: repeat(4, 24px 4px) 24px;
}


.margin-bottom-controller {
  /*
  Adding tailing col-start-9 and row-start-5 does not work for this cell for
  an undiscovered reason.
  */
  grid-area: 9 / 5;
}

.num-unit-display {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-size: 11px;
}

.unit {
  font-size: 9px;
  text-transform: uppercase;
}


</style>