<script>
import {defineComponent} from 'vue'

export default defineComponent({
  props: {
    title: {
      type: String,
      required: true
    },
    price: {
      type: String,
      required: true
    },
    stripePriceLookupKey: {
      type: String,
      required: false
    },
    interval: {
      type: String,
      default: 'per month'
    },
    description: {
      type: String,
      required: false
    },
    features: {
      type: Array,
      required: false
    },
    existingSubscription: {
      type: String,
      required: false
    }
  },

  methods: {
    emitBuyEvent() {
      this.$emit('update:buy',
          {
            title: this.title,
            stripePriceLookupKey: this.stripePriceLookupKey,
          }
      )
    }
  }

})
</script>

<template>
  <div class="flex flex-col justify-between rounded-3xl bg-white p-8 shadow-xl ring-1 ring-gray-900/10 sm:p-10">
    <div class="min-h-[35vh]">
      <h3 class="text-2xl font-semibold leading-7 text-indigo-600">
        {{ title }}
      </h3>
      <div class="mt-4 flex items-baseline gap-x-2">
        <span class="text-5xl font-bold tracking-tight text-gray-900">
          {{ price }}
        </span>
        <span class="text-base font-semibold leading-7 text-gray-600">
          {{ interval }}
        </span>
      </div>
      <p class="mt-6 text-base leading-7 text-gray-600">
        {{ description }}
      </p>
      <ul role="list" class="mt-10 space-y-4 text-sm leading-6 text-gray-600">
        <li
            class="flex gap-x-3"
            v-for="(feature, index) in features"
            :key="`${title}-${index}`"
        >
          <svg class="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor"
               aria-hidden="true">
            <path fill-rule="evenodd"
                  d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                  clip-rule="evenodd"/>
          </svg>
          {{ feature }}
        </li>
      </ul>
    </div>

    <button
        v-if="existingSubscription !== title"
        class="btn btn-outline mt-8" @click="this.emitBuyEvent">
      Choose {{ title }}
    </button>
    <button
        v-if="existingSubscription === title"
        disabled
        class="btn btn-primary btn-outline btn-active mt-8">
      You're on this plan ✅
    </button>
  </div>

</template>

<style scoped>

</style>