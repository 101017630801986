<template>
  <div class="field cursor-pointer flex px-1 h-full">
    <input
        v-if="!isAuto"
        v-model="amount"
        :max="maxValue"
        @mousedown="handleChangeStart"
        @input="updateNum"
        @mouseup="handleChangeEnd"
        class="w-full"
        type="range"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent} from 'vue';
import {numObjectPayload} from "@/interfaces";

export default defineComponent({
  name: 'GenericInputRange',
  emits: ['update:num-object'],
  props: {
    num: { type: String, required: true },
    unit: { type: String, default: 'px' },
    maxValue: { type: Number, default: 10 }
  },
  data(props) {
    return {
      amount: Number(props.num),
      previousAmount: 0,
      isChanging: false, // Indicates if the user is currently changing the value.
    };
  },
  computed: {
    isAuto(): boolean {
      return this.unit === 'auto' || this.num === 'auto';
    }
  },
  methods: {
    updateNum() {
      const payload: numObjectPayload = {
        css: {
          val: `${this.amount}${this.unit}`,
          previousVal: `${this.previousAmount}${this.unit}`
        },
        isChanging: this.isChanging
      };
      this.$emit('update:num-object', payload);
    },
    handleChangeStart() {
      this.isChanging = true
      this.previousAmount = this.amount
    },
    handleChangeEnd(){
      this.isChanging = false;
      this.updateNum();  // We send the payload with the isChanging flag set to false.
      this.previousAmount = this.amount
    }
  }
});
</script>

<style scoped>
.field {
  background-color: #333333;
  color: white;
  border-radius: 4px;
}
</style>
