<template>
  <div v-if="selectedElement" class="w-full text-[11px] base">
    <div  class="pl-2 py-1">
      Size
    </div>

    <div class="py-1 px-2 first-layer">
      <div class="pb-1 grid grid-cols-4">
        <CssLabel css-property="width" label="Width"/>
        <GenericInputNumUnit
            :css-value="computedCssValue('width', 'auto').value"
            @update:num-object="setLocalCssProperty('width', $event.css.val, {isChanging: $event.isChanging})"
        />
        <CssLabel class="pl-2" css-property="height" label="Height"/>
        <GenericInputNumUnit
            :css-value="computedCssValue('height', 'auto').value"
            @update:num-object="setLocalCssProperty('height', $event.css.val, $event.isChanging)"
        />
      </div>

      <div class="pb-1 grid grid-cols-4">
        <CssLabel css-property="min-width" label="Min Width"/>
        <GenericInputNumUnit
            :css-value="computedCssValue('min-width', 'auto').value"
            @update:num-object="setLocalCssProperty('min-width', $event.css.val)"
        />
        <CssLabel css-property="min-height" label="Min Height"/>
        <GenericInputNumUnit
            :css-value="computedCssValue('min-height', 'auto').value"
            @update:num-object="setLocalCssProperty('min-height', $event.css.val)"
        />
      </div>

      <div class="pb-1 grid grid-cols-4">
        <CssLabel css-property="max-width" label="Max Width"/>
        <GenericInputNumUnit
            :css-value="computedCssValue('max-width', 'auto').value"
            @update:num-object="setLocalCssProperty('max-width', $event.css.val)"
        />

        <CssLabel css-property="max-height" label="Max Height"/>
        <GenericInputNumUnit
            :css-value="computedCssValue('max-height', 'auto').value"
            @update:num-object="setLocalCssProperty('max-height', $event.css.val)"
        />
      </div>

      <div class="grid grid-cols-4 items-center">
        <CssLabel label="Overflow" css-property="overflow"></CssLabel>
        <GenericInputRadioGroup
            class="col-span-3 grid grid-cols-4"
            name="overflow"
            :current-value="computedCssValue('overflow', 'auto').value"
            :options="[
                                    {value: 'visible', iconComponent: 'overflow-visible'},
                                    {value: 'hidden', iconComponent: 'overflow-hidden'},
                                    {value: 'scroll', iconComponent: 'overflow-scroll'},
                                    {value: 'auto', description: 'Auto'}
                              ]"
            @update:value="setLocalCssProperty('overflow', $event)"
        />
      </div>



      <div class="pb-1 grid grid-cols-4 items-stretch">
        <CssLabel label="Fit" css-property="object-fit"></CssLabel>
        <GenericInputSelect
            class="select w-full input-field col-span-3"
            :css-value="computedCssValue('object-fit', '').value"
            :options="[
                {value: 'fill', description: 'Fill'},
                {value: 'contain', description: 'Contain'},
                {value: 'cover', description: 'Cover'},
                {value: 'none', description: 'None'},
                {value: 'scale-down', description: 'Scale-down'}
            ]"
            @update:css-value="setLocalCssProperty('object-fit', $event)"/>
      </div>
    </div>
  </div>
</template>

<script>
import GenericInputNumUnit from "@/components/GenericInputNumUnit.vue";

import useSelectedElement from "@/composables/useSelectedElement";
import parseCss from "@/utils/parseCss";
import {computed} from "vue";
import _ from "lodash";
import CssLabel from "@/components/CssLabel.vue";
import GenericInputRadioGroup from "@/components/GenericInputRadioGroup.vue";
import GenericInputSelect from "@/components/GenericInputSelect.vue";

export default {
  name: 'ControlSize',
  components: {
    GenericInputSelect,
    GenericInputRadioGroup,
    CssLabel,
    GenericInputNumUnit,

  },
  setup() {
    const { selectedElement, selectedElementStyle, store, computedCssValue, setLocalCssProperty  } = useSelectedElement();
    const {getCssNumAndUnit} = parseCss();


    return {
      selectedElement, selectedElementStyle, getCssNumAndUnit,
      computedCssValue, setLocalCssProperty
    }
  },
};
</script>

<style scoped>
.base {
  background-color: #333333;
}

.first-layer {
  background-color: rgb(94, 89, 89);
  color: white;
}

.input-field {
  background-color: #333333;
  color: white;
  border-radius: 4px;
}
</style>
