<!--
We use this component to dynamically import and display local SVG icons.
-->
<script>

import {defineAsyncComponent} from 'vue';

export default {
  props: {
    name: {
      type: String,
      required: true,
      description: 'The name of the icon to display (without the .svg extension). Must match a .svg file in /assets/icons/.'
    },
    style: {
      type: Object,
      required: false,
      default: () => ({
        width: "100%",
        height: "100%",
        minWidth: "14px",
        minHeight: "14px",
      }),
      description: 'An object of CSS styles to apply to the icon.'
    }
  },

  computed: {
    dynamicComponent() {
      const name = this.name;
      return defineAsyncComponent(() => import(`@/assets/icons/${name}.svg`));
    },
  },
};
</script>

<template>
  <component :is="dynamicComponent" :style="style"/>
</template>


