import {defineStore, storeToRefs} from 'pinia'
import {computed, ComputedRef, ref} from "vue";
import {Completion, Completions} from "@/utils/completionUtils"


export const useCompletionsStore = defineStore('holder', () => {
    const selectedCompletionId = ref<number>(-1)
    const completions = ref<Completions>([])
    const isGenerating = ref<boolean>(false)

    // Setters.
    const setCompletion = (completionId: number, key: string, value: any) => {
        if (typeof completionId !== 'number') {
            throw new Error('Completion id must be a number')
        }
        if (typeof key !== 'string') {
            throw new Error('Completion key must be a string')
        }
        if (typeof value === 'undefined') {
            throw new Error('Completion value must be defined')
        }

        const completion = completions.value.find(c => c.id === completionId)
        if (!completion) {
            throw new Error(`Completion with id ${completionId} not found`)
        }

        if (!completion.hasOwnProperty(key)) {
            console.warn(
                `Completion with id ${completionId} does not have key ${key}.` +
                `Setting key regardless.`
            )
        }
        completion[key] = value
    }

    const setSelectedCompletionId = (id: number) => {
        selectedCompletionId.value = id
    }

    const setIsGenerating = (value: boolean) => {
        isGenerating.value = value
    }

    // Getters.
    const selectedCompletion: ComputedRef<Completion> = computed(() => {
        if (selectedCompletionId.value === -1) {
            const latestCompletion = completions.value[completions.value.length - 1]
            return latestCompletion
        } else {
            return completions.value.find(c => c.id === selectedCompletionId.value)
        }
    })


    return {
        selectedCompletionId,
        completions,
        isGenerating,
        // Setters.
        setCompletion,
        setSelectedCompletionId,
        setIsGenerating,
        // Getters.
        selectedCompletion,


    }
})
