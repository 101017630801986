import {computed} from 'vue';
import Cookies from 'universal-cookie';

export const useAuthUtils = () => {
    const cookies = new Cookies();
    const isSignedIn = computed(
        () => {
            const val = cookies.get('pd_is_signed_in', false)
            if (val === undefined) {
                return false;
            }
            return val;
        }
    );
    return {isSignedIn};
};