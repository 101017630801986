import {useFetchBlob} from "@/composables/useFetchBlob";
import {BACKEND_PATHS} from "@/constants";
import {useFetchJson} from "@/composables/useFetchJson";
import {ComponentName, createPageComponentsFromBackend} from "@/utils/elementCreator";


export async function exportProject(projectId) {
    /*
    Exports a project as a zip file.
     */
    const url = `${import.meta.env.VITE_OX_URL}/${BACKEND_PATHS.PROJECT_EXPORT(projectId)}`
    const options = {method: 'POST'}
    const {data, error, response} = await useFetchJson(url, options)


    if (error.value) {
        throw new Error('Failed to export project');
    } else {
        const downloadUrl = data.value.download_export_url;

        // Create a link element.
        const link = document.createElement('a');
        link.href = downloadUrl;
        const timestamp = new Date().toISOString().replace(/[:.]/g, '-');
        link.setAttribute('download', `pd-export-${timestamp}.zip`);
        document.body.appendChild(link);

        link.click();

        // Remove the link element.
        document.body.removeChild(link);
    }
}

export async function fetchPages(projectId) {
    /*
    Fetches the pages for a project.
     */
    const url = `${import.meta.env.VITE_OX_URL}/${BACKEND_PATHS.PAGES(projectId)}`
    const {data, error} = await useFetchJson(url)

    if (!error.value) {
        return data.value
    } else {
        console.error('Failed to load pages:', error?.value);
        return []
    }
}


export async function postPage(projectId, newPageData) {

    const url = `${import.meta.env.VITE_OX_URL}/${BACKEND_PATHS.PAGE.CREATE(projectId)}`
    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(newPageData)
    }
    const {data, error} = await useFetchJson(url, options)

    if (!error.value) {
        return data.value
    } else {
        console.error('Failed to create page:', error?.value);
        return []
    }
}

export async function putPage(projectId, pageId, pageData) {

    const url = `${import.meta.env.VITE_OX_URL}/${BACKEND_PATHS.PAGE.UPDATE(projectId, pageId)}`
    const options = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(pageData)
    }
    const {data, error} = await useFetchJson(url, options)

    if (!error.value) {
        return data.value
    } else {
        console.error('Failed to update page:', error?.value);
        return []
    }
}


export async function getProjects() {
    const url = `${import.meta.env.VITE_OX_URL}/${BACKEND_PATHS.PROJECTS()}`;
    const {data, error} = await useFetchJson(url, {method: 'GET'});
    if (!error.value) {
        return data.value
    } else {
        console.error('Failed to load projects:', error?.value);
    }
}

export async function getTemplateProjects() {
    const url = `${import.meta.env.VITE_OX_URL}/${BACKEND_PATHS.TEMPLATE_PROJECTS()}`;
    const {data, error} = await useFetchJson(url, {method: 'GET'});
    if (!error.value) {
        return data.value
    } else {
        console.error('Failed to load template projects:', error?.value);
    }
}

export async function createProject(newProjectDetails) {
    const url = `${import.meta.env.VITE_OX_URL}/${BACKEND_PATHS.PROJECTS()}`
    const projectData = JSON.stringify(newProjectDetails);
    const {data, error} = await useFetchJson(url, {
        method: 'POST',
        body: projectData
    });

    if (error.value) {
        console.error('Failed to create project:', error?.value);
    }
    return {data, error}
}


export async function signOut() {
    const url = `${import.meta.env.VITE_OX_URL}/${BACKEND_PATHS.SIGN_OUT()}`
    const options = {method: 'POST'}
    const {data, error} = await useFetchJson(url, options)
    if (error.value) {
        console.error('Failed to sign out:', error?.value);
    }
    return {data, error}
}


export async function generateUI(pageId, userPrompt) {
    const url = `${import.meta.env.VITE_OX_URL}/${BACKEND_PATHS.ELEMENTS_GENERATE(pageId)}`
    const {data, error} = await useFetchJson(url, {
        method: 'POST',
        body: JSON.stringify(
            {
                operation: 'generate',
                user_prompt: userPrompt
            }
        )
    })

    if (!error.value) {
        return data.value
    } else {
        console.error('Failed to load page elements:', error?.value);
        return error.value
    }
}

export async function insertCompletion(pageId, completionId) {
    const url = `${import.meta.env.VITE_OX_URL}/${BACKEND_PATHS.ELEMENTS_GENERATE(pageId)}`
    const {data, error} = await useFetchJson(url, {
        method: 'POST',
        body: JSON.stringify(
            {
                operation: 'insert',
                completion_id: completionId
            }
        )
    })

    if (!error.value) {
        return data.value
    } else {
        console.error('Failed to load page elements:', error?.value);
        return error.value
    }
}

export async function postCompletionSet(payload) {
    if (!payload || typeof payload !== 'object') {
        return {error: 'Invalid data'}
    }

    const url = `${import.meta.env.VITE_OX_URL}/${BACKEND_PATHS.COMPLETION_SET()}`
    const {data, error, response} = await useFetchJson(url, {
        method: 'POST',
        body: JSON.stringify(payload)
    })
    if (!error.value) {
        return {data: data.value, response: response.value}
    } else {
        console.error('Failed to post completion set:', error?.value);
        return error.value
    }
}

export async function getCompletionsPublic(queryParams = {}) {
    return _getCompletions(queryParams, false)
}

export async function getCompletionsPrivate(queryParams = {}) {
    return _getCompletions(queryParams, true)
}

export async function _getCompletions(queryParams = {}, isPrivate) {
    if (typeof isPrivate !== 'boolean') {
        throw 'Invalid isPrivate value'
    }
    let url = `${import.meta.env.VITE_OX_URL}/`
    if (isPrivate) {
        url += BACKEND_PATHS.COMPLETIONS_PRIVATE()
    } else if (!isPrivate) {
        url += BACKEND_PATHS.COMPLETIONS_PUBLIC()
    }

    if (Object.keys(queryParams).length > 0) {
        url += "?" + new URLSearchParams(queryParams)
    }
    const {data, error} = await useFetchJson(url, {method: 'GET'})

    if (!error.value) {
        return data.value
    } else {
        console.error('Failed to load page elements:', error?.value);
        return error.value
    }
}

export async function postCompletion(payload) {
    if (!payload || typeof payload !== 'object') {
        return {error: 'Invalid data'}
    }


    const url = `${import.meta.env.VITE_OX_URL}/${BACKEND_PATHS.COMPLETIONS_PRIVATE()}`
    const {data, error, response} = await useFetchJson(url, {
        method: 'POST',
        body: JSON.stringify(payload)
    })
    if (!error.value) {
        return {data: data.value, response: response.value}
    } else {
        console.error('Failed to post completion:', error?.value);
        return error.value
    }
}

export async function streamCompletion(payload) {
    if (!payload || typeof payload !== 'object' ||
        typeof payload.completion_id !== 'number' || typeof payload.operation !== 'string') {
        throw `Invalid payload in function: ${JSON.stringify(payload)}`;
    }

    const url = `${import.meta.env.VITE_OX_URL}/${BACKEND_PATHS.COMPLETIONS_PRIVATE()}`
    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify(payload)
    });
    return response
}

export async function handleStream(response, callback) {
    /*
    Utility function to handle a stream of data.
    Example usage:

    const response = await handleStream(
        fetch('https://example.com/stream'), chunk => {
            console.log("Received chunk: ", chunk);
        }
    )
     */
    const reader = response.body.getReader();
    while (true) {
        const {done, value} = await reader.read();
        if (done) {
            break
        }
        callback(new TextDecoder("utf-8").decode(value));
    }
}


export async function getProfile() {
    const url = `${import.meta.env.VITE_OX_URL}/${BACKEND_PATHS.PROFILE()}`
    const {data, error, response} = await useFetchJson(url, {method: 'GET'})
    if (!error.value) {
        return data.value
    } else {
        return error.value
    }
}

export async function createCheckoutSession(payload) {
    const url = `${import.meta.env.VITE_OX_URL}/${BACKEND_PATHS.CREATE_CHECKOUT_SESSION()}`
    const {data, error, response} = await useFetchJson(url, {
        method: 'POST',
        body: JSON.stringify(payload)
    })
    if (!error.value) {
        return data.value
    } else {
        return error.value
    }
}

export async function getCustomerPortal() {
    const url = `${import.meta.env.VITE_OX_URL}/${BACKEND_PATHS.CUSTOMER_PORTAL()}`
    const {data, error, response} = await useFetchJson(url, {method: 'GET'})
    if (!error.value) {
        return data.value
    } else {
        return error.value
    }
}

export async function renderDjangoTemplate(payload) {
    /*
    Request to render a Django template.
     */
    if (!payload || typeof payload !== 'object') {
        throw {error: 'Invalid data'}
    }
    if (typeof payload.template_code !== 'string') {
        throw {error: 'Invalid template', payload: payload}
    }

    const url = `${import.meta.env.VITE_OX_URL}/${BACKEND_PATHS.RENDER_DJANGO_TEMPLATE()}`
    const {data, response} = await useFetchJson(
        url, {method: 'POST', body: JSON.stringify(payload)}
    )

    return {
        data: data.value,
        response: response.value,
        error: data.value?.error || null
    }
}


export async function postCompletionScreenshot(payload) {
    const url = `${import.meta.env.VITE_OX_URL}/${BACKEND_PATHS.COMPLETION_SCREENSHOT()}`
    const {data} = await useFetchJson(
        url, {method: 'POST', body: JSON.stringify(payload)}
    )
    return data.value
}