<template>
  <PageDiv :element="this.element" :movable="false" v-show="isActive"/>
</template>

<script>
import PageDiv from "@/components/element_components/PageDiv.vue";
import {inject} from "vue";


export default {
  name: 'PageFormState',
  components: {PageDiv},
  props: {
    element: {
      type: Object,
      required: true,
    },
  },
  setup(props){
    const formElement = inject('formElement', props.element)
    return {
      formElement
    }
  },
  computed: {
    isActive() {
      return this.element.form_state === this.formElement.form_showing_state
    }
  }
}
</script>