<template>
  <input
      :placeholder="placeholder"
      :type="type"
      :name="name"
      :value="value"
      @input="emitCssValue($event.target.value)"
      autocomplete="on"
      style="padding: 0px 4px; border-width: 1px; border-style: solid; border-radius: 4px; line-height: 22px;"
  />
</template>

<script>

export default {
  props: {
    name: {
      type: String,
      default: `input-text-${Math.random().toString(36).substring(4)}`,
    },
    value: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: 'A generic text input',
    },
    type: {
      type: String,
      default: 'text',
    },
  },
  methods: {
    emitCssValue(value) {
      this.$emit('update:value', value);
    },
  },
};
</script>