<template>
  <div>
    <input v-model="searchTerm" class="search-input" name="search-input" placeholder="Search keys..." @input="search">
    <ul v-if="searchResults.length" class="search-results">
      <li v-for="(result, index) in searchResults" :key="index"
          @click="select(result)" class="search-result">
        {{ result }}
      </li>
    </ul>
  </div>
</template>

<script>
import {mapStores} from "pinia";
import {useCounterStore} from "@/stores/counter";

export default {
  name: 'ContextSelector',
  props: {
    modelValue: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      searchTerm: '',
      searchResults: [],
    };
  },
  computed: {
    ...mapStores(useCounterStore)
  },
  methods: {
    search() {
      const keys = Object.keys(this.counterStore.context);
      this.searchResults = keys.filter(key => key.toLowerCase().includes(this.searchTerm.toLowerCase()));
    },
    select(result) {
      this.$emit('update:modelValue', this.counterStore.context[result]);
      this.searchResults = [];
    },
  },
};
</script>

<style scoped></style>
