import {computed, isRef, ref, toRaw, toValue, unref, watchEffect} from 'vue';
import {useMouseInElement} from "@vueuse/core";
import {useCounterStore} from "@/stores/counter";

export default function useRecordMouseover(element, el, contentWindow=null) {
    const store = useCounterStore()
    if (contentWindow === null) {
        contentWindow = store.workFrame.contentWindow
    }

    const {
        x,
        y,
        sourceType,
        elementX,
        elementY,
        elementPositionX,
        elementPositionY,
        elementHeight,
        elementWidth,
        isOutside,
    } = useMouseInElement(el, {window: contentWindow})

    const isInside = computed(() => {return !isOutside.value})

    const mouseProps = useMouseInElement(el, {window: contentWindow});

    const inTopHalf = computed(() => {
        const inArea = elementY.value < elementHeight.value / 2
        return !isOutside.value && inArea
    })

    const inBottomHalf = computed(() => {
        const inArea = elementY.value >= elementHeight.value / 2
        return !isOutside.value && inArea
    })

    const inLeftHalf = computed(() => {
        const inArea = elementX.value < elementWidth.value / 2
        return !isOutside.value && inArea
    })

    const inRightHalf = computed(() => {
        const inArea = elementX.value >= elementWidth.value / 2
        return !isOutside.value && inArea
    })


    const inTopTenth = computed(() => {
        const inArea = elementY.value < elementHeight.value / 10
        return !isOutside.value && inArea
    })

    const inBottomTenth = computed(() => {
        const bottomTenth = elementHeight.value - elementHeight.value / 10
        const inArea = elementY.value >= bottomTenth
        return !isOutside.value && inArea
    })

    watchEffect(() => {
        if (!isOutside.value) {
            store.userActivity.mouseover = {
                pid: element.pid,
                element: element,
                ...mouseProps,
                inTopHalf: inTopHalf.value,
                inBottomHalf: inBottomHalf.value,
                inLeftHalf: inLeftHalf.value,
                inRightHalf: inRightHalf.value,
                inTopTenth: inTopTenth.value,
                inBottomTenth: inBottomTenth.value,
            }
        }
    })


    return {
        ...mouseProps,
        inTopHalf,
        inTopTenth,
        inBottomHalf,
        inBottomTenth,
        isInside
    };
}
