import {useDraggable} from '@vueuse/core';
import {useCounterStore} from "@/stores/counter";
import useUndoRedo from "@/composables/useUndoRedo.ts";
import {updateActiveChildOrder, updateActiveSiblingOrder, sequenceElements } from "@/utils";

export default function useDragAndDrop(element, el, contentWindow=null) {
    /*
    We use this composable to add drag and drop functionality to a page component.

    @args:
      element: the page element we want to drag and drop.
      el: a ref to the DOM element to which we want to add the drag and drop functionality/
    */
    const store = useCounterStore()
    if (contentWindow === null) {
        contentWindow = store.workFrame.contentWindow
    }

    const { style, isDragging } = useDraggable(el, {
        onStart: () => {
            setDraggedElement(store, element);
        },
        onMove: () => {
            if (element.pid === store.userActivity.mouseover.pid) {
                resetGuideElement(store);
            } else {
                setGuideElement(store);
            }
        },
        onEnd: () => {
            if (store.userActivity.drag.guideElement) {

                moveDraggedElement(store);
                store.userActivity.drag = { pid: null };
            }
            resetDraggedElement(store);
        },
        draggingElement: contentWindow
    });


    const setDraggedElement = (store, element) => {
        store.userActivity.drag = {
            pid: element.pid,
            element: element,
            oldDepth: element.depth,
            oldIndex: element.order,
            oldParentPid: element.parentPid,
        };
    };

    const resetDraggedElement = (store) => {
        store.userActivity.drag = {
            pid: null,
            element: null,
            oldDepth: null,
            oldIndex: null,
            oldParentPid: null,
        };
    }


    const setGuideElement = (store) => {
        const { drag, mouseover } = store.userActivity;
        drag.guideElement = mouseover.element;

        drag.insertIntoGuide = shouldInsertIntoGuide(mouseover)
        drag.insertBeforeGuide = !drag.insertIntoGuide && mouseover.inTopHalf
        drag.insertAfterGuide = !drag.insertIntoGuide && mouseover.inBottomHalf;
    };

    const shouldInsertIntoGuide = (mouseover) => {
        /*
        This determines if we will insert the dragged element into its guide element.
         */
        const hasNest = mouseover.element?.hasNest
        const inMiddleEightyPercent = !(mouseover.inTopTenth || mouseover.inBottomTenth);

        return hasNest && inMiddleEightyPercent;
    }

    const resetGuideElement = (store) => {
        if (store.userActivity.drag.guideElement) {
            store.userActivity.drag.guideElement = null;
            store.userActivity.drag.insertIntoGuide = false;
            store.userActivity.drag.insertBeforeGuide = false;
            store.userActivity.drag.insertAfterGuide = false;
        }
    };

    const moveDraggedElement = (store) => {
        const { drag } = store.userActivity;
        if (drag.element === drag.guideElement || !drag.guideElement) {return;} // No need to move.

        const {doWithUndo} = useUndoRedo()

        const revertMove = createRevertMove(drag)

        if (drag.insertIntoGuide) {
            doWithUndo(() => moveInToGuide(drag), revertMove)
        }
        else{
            doWithUndo(() => moveNextToGuide(drag), revertMove)
        }
    };

    const createRevertMove = (drag) => {
        /*
        We define the functions to undo moving an element.

        To reduce confusion:
        - An undo changes elements to the BeforeMove state.
         */
        const beforeMoveState = getBeforeMoveState(drag);

        const revertBeforeMoveSiblings = () => {
            /*
            We revert the siblings that the element had before the user moved it.
             */
            const { beforeMoveOrder, beforeMoveSiblings, beforeMoveElement } = beforeMoveState;

            beforeMoveSiblings.splice(beforeMoveOrder, 0, drag.element);
            beforeMoveSiblings.forEach((sibling, index) => sibling.order = index)

            updateActiveChildOrder(store, beforeMoveElement.parentPid) // We use this to account for any deleted element.
        };

        const revertAfterMoveSiblings = (parentPid) => {
            /*
            We revert the siblings that the element had after the user moved it.
             */
            updateActiveChildOrder(store, parentPid)
        }

        const revertElementDepth = () => {
            const { beforeMoveDepth } = beforeMoveState;
            drag.element.depth = beforeMoveDepth;
            updateChildrenDepth(drag.element);
        };

        const revertElementParentPid = () => {
            /*
            We revert the element's parentPid to the parentPid that the element had before the
            user moved the element.
            */
            const { beforeMoveParentPid } = beforeMoveState;
            drag.element.parentPid = beforeMoveParentPid;
        };

        return () => {
            const afterMoveParentPid = drag.element.parentPid
            revertElementParentPid();
            revertBeforeMoveSiblings();
            revertAfterMoveSiblings(afterMoveParentPid)
            revertElementDepth();
        };
    };

    const getBeforeMoveState = (drag) => {
        return {
            beforeMoveElement: drag.element,
            beforeMoveOrder: drag.element.order,
            beforeMoveParentPid: drag.element.parentPid,
            beforeMoveDepth: drag.element.depth,
            beforeMoveSiblings: getChildren(drag.element.parentPid, drag.element)
        };
    }

    function moveInToGuide(drag) {
        /*
        We move the dragged element into the guide element, making it a child of the guide element.
         */
        updateOrderAfterMoveInTo(drag);
        updateAnyOldSiblingsOrder(drag);
        updateDepth(drag, drag.guideElement.depth + 1)

        drag.element.parentPid = drag.guideElement.pid; // The guide element becomes the parent.
    }

    function moveNextToGuide(drag) {
        /*
        We move the dragged element next to the guide element, making it a sibling of the guide element.
         */

        updateOrderAfterMoveNextTo(drag);
        updateAnyOldSiblingsOrder(drag);
        updateDepth(drag, drag.guideElement.depth);

        drag.element.parentPid = drag.guideElement.parentPid; // The guide element's parent becomes the parent.
    }

    function updateOrderAfterMoveInTo(drag) {
        /*
        We update the order of the dragged element and its siblings after moving
        the dragged element into its guide element.
         */
        let siblings = getChildren(drag.guideElement.pid, drag.element);
        drag.element.order = siblings.length; // We append the dragged element
    }

    function updateOrderAfterMoveNextTo(drag) {
        /*
        We update the order of the dragged element and its siblings after moving
        the dragged element next to its guide element.
         */
        let siblings = getChildren(drag.guideElement.parentPid, drag.element);

        const guideIndex = siblings.findIndex(el => el.pid === drag.guideElement.pid);

        // We insert the dragged element before or after the guide element.
        siblings.splice(
            drag.insertBeforeGuide ? guideIndex : guideIndex + 1,
            0, drag.element
        );

        siblings.forEach((sibling, index) => sibling.order = index);
    }


    function updateAnyOldSiblingsOrder(drag) {
        if (drag.element.parentPid !== drag.guideElement.parentPid) {
            let oldSiblings = getChildren(drag.element.parentPid, drag.element);
            oldSiblings.forEach((sibling, index) => sibling.order = index);
        }
    }

    function updateDepth(drag, newDepth) {
        if (!newDepth) {throw new Error('newDepth is required')}

        drag.element.depth = newDepth
        updateChildrenDepth(drag.element);
    }

    function getChildren(parentPid, excludedElement = null) {
        return store.activeElements
            .filter(el => el.parentPid === parentPid && el !== excludedElement)
            .sort((a, b) => a.order - b.order);
    }

    function updateChildrenDepth(parentElement) {
        const children = store.activeElements.filter(el => el.parentPid === parentElement.pid);
        children.forEach(child => {
            child.depth = parentElement.depth + 1;
            updateChildrenDepth(child);
        });
    }

    return {
        style,
        isDragging,
        moveDraggedElement, // For testing
        shouldInsertIntoGuide // For testing
    };
}
