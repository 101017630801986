import {markRaw, Ref, ComputedRef} from "vue";
import _ from "lodash";
import {PageElement} from "@/interfaces";
import {getOffsetBoundingRect} from "@/utils.js";


export function updateComputedStyle(selectedElement: ComputedRef<PageElement>) {
  /**
   * We use this to update computed style of the selected element in the store
   * as we change the position, padding, margins, and size of the element.
   */
  if (!selectedElement?.value?.pid) {
    return;
  }

  const rect = getOffsetBoundingRect(selectedElement.value.pid);
  if (!_.isEmpty(rect)) {
    _.set(selectedElement.value, ['computedStyle', 'offsetHeight'], rect.offsetHeight);
    _.set(selectedElement.value, ['computedStyle', 'offsetWidth'], rect.offsetWidth);
    _.set(selectedElement.value, ['computedStyle', 'offsetTop'], rect.offsetTop);
    _.set(selectedElement.value, ['computedStyle', 'offsetLeft'], rect.offsetLeft);
  }
}
