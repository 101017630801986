import { computed, Ref } from 'vue';


export default function useLoadingCheck(
    areElementsLoaded: Ref<boolean>, defaultValue: any = null
) {

  return function (computedFunction: () => unknown) {
    /*
    * We use this function to check if we've loaded the elements from the server before computing
    * the value of a computed property.
     */
    return computed(() => {
      if (!areElementsLoaded.value) {
        return defaultValue;
      }
      return computedFunction();
    });
  };
}
