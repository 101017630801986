<template>
  <select
          @change="emitCssValue($event.target.value)"
          :name="name"
          :value="cssValue">
    <option v-for="option in options" :key="option.value" :value="option.value">{{ option.description }}</option>
  </select>
</template>

<script>

export default {
  props: {
    options: {
      type: Array,
      required: true,
    },
    cssValue: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      default: `select-${Math.random().toString(36).substring(4)}`,
    },
  },
  emits: ['update:css-value'],
  methods: {
    emitCssValue(value) {
      this.$emit('update:css-value', value);
    },

  },
};
</script>