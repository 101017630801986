<template>
  <img
      ref="domElement"
      class="hintable"
      :id="pid"
      :class="{
          'top-half-hint': isInsideAndIsGuide && !this.store.userActivity.drag.insertIntoGuide && inTopHalf,
          'bottom-half-hint': isInsideAndIsGuide && !this.store.userActivity.drag.insertIntoGuide && inBottomHalf
        }"
      :style="finalStyle"
      :src="linkedAsset?.url"
      alt="A placeholder image"
      @keydown.enter.prevent
      @click.stop="select()"
      @mouseover.stop="recordStartHover(pid)"
      @mouseleave.stop="recordEndHover()"
      @dblclick.stop="openAssetSelector()"
      draggable="false"
  />
</template>

<script>
import useRecordPageElementHover from "@/composables/useRecordPageElementHover.js";
import useElement from "@/composables/useElement";
import {computed, ref} from "vue";
import useRecordMouseover from "@/composables/useRecordMouseover";
import useDragAndDrop from "@/composables/useDragAndDrop";
import {useAssetsStore} from "@/stores/assets";


export default {
  name: 'PageImage',
  props: {
    element: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const assetsStore = useAssetsStore();
    const domElement = ref(null)

    // Start standard page component setup.
    const { store, finalStyle, pid, select } = useElement(props.element)
    const { recordStartHover, recordEndHover } = useRecordPageElementHover();
    const {isOutside, isInside, inBottomHalf, inTopHalf } = useRecordMouseover(
        props.element, domElement
    )

    const isInsideAndIsGuide = computed(() => {
      return isInside.value && store.userActivity.drag.guideElement?.pid === pid.value;
    })
    const showTopHalfHint = computed(() => {
      return isInsideAndIsGuide.value && !store.userActivity.drag.insertIntoGuide && inTopHalf.value
    })
    const showBottomHalfHint = computed(() => {
      return isInsideAndIsGuide.value && !store.userActivity.drag.insertIntoGuide && inBottomHalf.value
    })
    // End standard page component setup.

    const { isDragging } = useDragAndDrop(props.element, domElement);

    const linkedAsset = computed(() => {
      return assetsStore.getAsset(props.element.asset_key);
    });

    return {
      domElement,
      isInsideAndIsGuide, store,
      pid, finalStyle, select,
      recordStartHover, recordEndHover,
      isOutside, isInside,
      inBottomHalf, inTopHalf,
      isDragging,
      linkedAsset,
    }
  },
  methods: {
    openAssetSelector() {
      this.store.setLeftSidebar({ selectedPane: 'LeftSidebarAssets', isShowing: true })
    },
  }
}
</script>
