import { onMounted, onUnmounted, ref, watch, nextTick } from 'vue';
import { useElementBounding } from "@vueuse/core";
import {useCounterStore} from "@/stores/counter";


export default function useHoveredElementBounding() {
    const el = ref(null);
    const boundingBox = useElementBounding(el, {immediate: true});
    const store = useCounterStore()

    const refreshBoundingBox = () => {
        if (el.value) {
            boundingBox.update();
        }
    };

    const initBoundingBox = () => {
        watch(() => store.userActivity.hover.elementPid, () => {
            const element = store.workFrame?.contentWindow.document.getElementById(store.userActivity.hover.elementPid);
            if (element) {
                el.value = element;
                refreshBoundingBox();
            }
        }, {immediate: true});

        store.workFrame.contentWindow.addEventListener('scroll', refreshBoundingBox, true);
    };

    onMounted(() => {
        initBoundingBox();
    });

    return {  boundingBox, ...boundingBox };
}
