import {ref, watchEffect} from 'vue';

export function useResizeHandleStyle(workFrame, store) {
    const resizeHandleStyle = ref({});

    const updateResizeHandleStyle = () => {
        /*
        We calculate the position of the resize handle to be at the right edge of the workFrame.
        */
        if (workFrame.value) {
            const frameWidth = store.dims.workFrame.width;
            const scalingFactor = store.workFrameScalingFactor;
            const frameWidthScaled = frameWidth * scalingFactor

            resizeHandleStyle.value = { left: `${frameWidthScaled}px` };
        }
    };

    watchEffect(updateResizeHandleStyle);

    return { resizeHandleStyle };
}
