<script>
import {defineComponent} from 'vue'

import {
  signOut,
} from "@/utils/apiRequests";
import IconComponent from "@/components/IconComponent.vue";
import {useAuthUtils} from "@/composables/useAuthUtils";
import {useModal} from "vue-final-modal";
import ModalSupport from "@/components/ModalSupport.vue";
import {getProfile} from "@/utils/apiRequests";

export default defineComponent({
  components: {IconComponent},
  setup() {
    const {isSignedIn} = useAuthUtils()
    const {open} = useModal({component: ModalSupport})
    return {
      isSignedIn,
      openSupportModal: open
    }
  },
  data: () => ({
    profile: {},
  }),
  methods: {
    async handleSignOut() {
      sessionStorage.removeItem("initial_user_prompt")
      await signOut()
      this.$router.push({name: 'signIn'})
    },
  },
  async mounted() {
    this.profile = await getProfile()
  }
})
</script>

<template>
  <nav class="bg-white p-3 w-full border-b items-center flex justify-between px-2 select-none">

    <router-link class="font-bold" :to="{name: 'home'}">
      Photon Designer <span class="font-mono font-extralight">Generate UI</span>
    </router-link>

    <div class="gap-2 hidden sm:flex">
      <slot></slot>

      <router-link
          :to="{name: 'pricing'}" v-show="$route.name !== 'pricing'">
        <button class="border px-2 btn btn-neutral btn-ghost btn-sm font-normal">
          Pricing
        </button>
      </router-link>

      <button class="border px-2 btn btn-neutral btn-ghost btn-sm font-normal"
              @click="openSupportModal">
        Support
        <IconComponent name="support" :style="{'width': '20px'}"></IconComponent>
      </button>

      <router-link :to="{name: 'profile'}" v-show="isSignedIn && $route.name !== 'profile'">
        <button class="border px-2 btn btn-neutral btn-ghost btn-sm font-normal">
          <span>{{ profile.first_name ?? "Profile" }}</span>
          <IconComponent name="user" :style="{'width': '20px'}"></IconComponent>
          <span class="badge badge-outline badge-md">{{ profile?.is_subscribed ? "Pro ⭐️" : "Free user" }}</span>
        </button>
      </router-link>

      <div class="" @click="handleSignOut()" v-show="isSignedIn && $route.name === 'profile'">
        <button class="border px-2 btn btn-neutral btn-ghost btn-sm font-normal">
          Sign out
          <IconComponent name="sign-out" :style="{'width': '20px'}"></IconComponent>
        </button>
      </div>
      <router-link :to="{name: 'signIn'}" v-show="!isSignedIn">
        <button class="border px-2 btn btn-neutral btn-ghost btn-sm font-normal">
          Sign in
          <IconComponent name="sign-in" :style="{'width': '20px'}"></IconComponent>
        </button>
      </router-link>
    </div>
  </nav>
</template>

<style scoped>


</style>