<script>
import {defineComponent} from 'vue'
import IconComponent from "@/components/IconComponent.vue";

export default defineComponent({
  components: {IconComponent},
  props: {
    primary: {
      type: String,
      required: true
    },
    secondary: {
      type: String,
      required: true
    },
    accent: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    emitColorScheme(newPrimary, newSecondary, newAccent) {
      this.$emit('update:colorScheme', {
        primary: newPrimary,
        secondary: newSecondary,
        accent: newAccent
          }
      )
    }
  }
})
</script>

<template>
  <div class="flex items-center relative bg-gray-200 border border-gray-300 rounded-lg p-2">
    <div class="text-xs uppercase px-1">
      Colors
    </div>
    <div class="flex flex-row gap-1 relative">
      <div class="tooltip tooltip-bottom" :data-tip="'Primary color: ' + primary">
        <input class="absolute w-full opacity-0 cursor-pointer" id="primary-color"
               type="color"
               :disabled="disabled"
               :value="primary"
               @input="emitColorScheme($event.target.value, secondary, accent)"
        />
        <label class="flex items-center gap-2 cursor-pointer" for="primary-color">
          <span class="w-6 h-6 rounded-full shadow" :style="{ 'background-color': primary }"></span>
        </label>
      </div>
      <div class="tooltip tooltip-bottom" :data-tip="'Secondary color: ' + secondary">
        <input class="absolute w-full opacity-0 cursor-pointer" id="secondary-color"
               type="color"
               :disabled="disabled"
               :value="secondary"
               @input="emitColorScheme(primary, $event.target.value, accent)"
        />
        <label class="flex items-center gap-2 cursor-pointer" for="secondary-color">
          <span class="w-6 h-6 rounded-full shadow" :style="{ 'background-color': secondary }"></span>
        </label>
      </div>
      <div class="tooltip tooltip-bottom" :data-tip="'Accent color: ' + accent">
        <input class="absolute w-full opacity-0 cursor-pointer" id="accent-color"
               type="color"
               :disabled="disabled"
               :value="accent"
               @input="emitColorScheme(primary, secondary, $event.target.value)"
        />
        <label class="flex items-center gap-2 cursor-pointer" for="accent-color">
          <span class="w-6 h-6 rounded-full shadow" :style="{ 'background-color': accent }"></span>
        </label>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>