<template>
  <main id="whole-page">

    <div class="navbar">
      <section class="navbar-item ">
        <router-link :to="{name: 'dashboard'}" class="flex items-center gap-1 px-2 hover:text-gray-300">
          <IconComponent name="home" style="{'width': '30px', 'height': '30px'}"/>
          <span>Dashboard</span>
        </router-link>
      </section>


      <section class="navbar-item justify-center">

        <div>
          <div class="flex">
            <button :class="[ store.currentBreakpoint === 'desktop' ? 'bg-gray-500': 'transparent' ]" class="p-2 flex items-center gap-1 rounded-md"
                    @click="store.dims.workFrame.width = 1130">
              <IconComponent name="desktop" style="{'width': '30px', 'height': '30px'}" />
              <span>Desktop</span>
            </button>
            <button :class="[store.currentBreakpoint === 'tablet'  ? 'bg-gray-500': 'transparent' ]" class="p-2 flex items-center gap-1 rounded-md"
                    @click="store.dims.workFrame.width = 768">
              <IconComponent name="tablet" style="{'width': '30px', 'height': '30px'}" />
              <span>Tablet</span>
            </button>
            <button :class="[store.currentBreakpoint === 'mobile'  ? 'bg-gray-500': 'transparent' ]" class="p-2 flex items-center gap-1 rounded-md"
                    @click="store.dims.workFrame.width = 320">
              <IconComponent name="mobile" style="{'width': '30px', 'height': '30px'}" />
              <span>Mobile</span>
            </button>
          </div>
        </div>
        <div class="flex flex-row">
          <div class="flex px-2">
            <GenericInputNumUnit
                :input-style="{
                  'max-width': '50px',
                  'border': 'none',
                  'outline': 'none',
                  'cursor': 'pointer',
                  'background-color': '#252525',
                  'text-align': 'right',
                  'padding': '0'
                }"
                :css-value="Math.floor(this.store.dims.workFrame.width) + 'px'"
                @update:num-object="setWorkFrameWidth($event)"
                :show-options="false"
            />
            <span class="pl-0.5">px</span>
          </div>
          <div class="select-none">{{ scale }} %</div>
        </div>
      </section>

      <section class="navbar-item justify-end">
        <div class="border p-1" @click="handleSignOut()">
          <span>Sign out</span>
        </div>

        <div class="flex flex-row gap-3">
          <button :class="[store.canUndo() ? 'text-gray-200': 'text-gray-500']"
                  class="border p-1 flex items-center gap-1"
                  @click="this.store.undo()">
            <span>Undo</span>
            <span><IconComponent name="undo"/></span>
          </button>

          <button :class="[store.canRedo() ? 'text-gray-200': 'text-gray-500']"
                  class="border p-1 flex items-center gap-1"
                  @click="this.store.redo()">
            <span>Redo</span>
            <span><IconComponent name="redo"/></span>
          </button>
        </div>

        <div>
          <IconComponent :class="{ 'text-gray-400': unsavedChanges }" name="saved-elements"/>
        </div>

        <button class="border p-1 flex items-center gap-1 rounded-lg"
                @click="exportCurrentProject">
          <span>Export your code</span>
          <span v-show="!isExportingProject">
            <IconComponent name="export"/>
          </span>
          <span v-show="isExportingProject">
            <IconComponent name="loading-loop"/>
          </span>
        </button>
      </section>

    </div>

    <section id="central-area">

      <div ref="leftSidebar">
        <LeftPanel/>
      </div>

      <WorkEasel ref="workEasel" id="work-easel"></WorkEasel>

      <ElementEditor id="element-editor" ref="rightSidebar"/>
    </section>

  </main>

</template>

<script>
import IconComponent from "@/components/IconComponent.vue"; // A local icon.
import {useCounterStore} from '@/stores/counter'

import draggable from 'vuedraggable'
import ElementEditor from "@/components/ElementEditor.vue";
import {useRoute, useRouter} from 'vue-router';
import PageDiv from "@/components/element_components/PageDiv.vue";
import PageEditor from "@/components/PageEditor.vue";
import LeftPanel from "@/components/LeftPanel.vue";
import WorkEasel from "@/components/WorkEasel.vue";
import {computed, ref} from "vue";
import useKeyboardShortcuts from "@/composables/useKeyboardShortcuts";
import useDimensions from "@/composables/useDimensions";
import useAutoSave from "@/composables/useAutoSave";
import {useProjectStore} from "@/stores/project";
import GenericInputSelect from "@/components/GenericInputSelect.vue";
import {exportProject, signOut} from "@/utils/apiRequests";
import GenericInputNumUnit from "@/components/GenericInputNumUnit.vue";
import sharedConstants from "../../../shared_constants.json";
import {ModalsContainer, useModal, useVfm} from 'vue-final-modal'
import ModalCompletion from './ModalCompletion.vue'
import {useCompletionsStore} from "@/stores/completions";



export default {
  components: {
    ModalsContainer,
    GenericInputNumUnit,
    GenericInputSelect,
    IconComponent,
    WorkEasel,
    LeftPanel,
    PageEditor,
    PageDiv,
    ElementEditor, draggable,
  },
  async beforeRouteEnter(to, from, next) {
    /*
    We ensure that the page elements are loaded before the page is rendered.
     */
    const router = useRouter();
    const projectStore = useProjectStore();
    await projectStore.loadPages(to.params.projectId)

    const store = useCounterStore();
    await store.loadPageElements(to.params.projectId, to.params.pageId)

    if (store.elements.length === 0) { // Redirect to home page if visiting a page without elements.
      await router.push({
        name: 'designer',
        params: {projectId: projectStore.home_page.project, pageId: projectStore.home_page.id},
      })
    }
    await store.loadComponentCssStylesheet(to.params.projectId);
    next()
  },
  setup(){
    const store = useCounterStore();
    const projectStore = useProjectStore();
    const generationsStore = useCompletionsStore()

    const route = useRoute();
    const projectId = route.params.projectId
    const pageId = route.params.pageId
    const navbarHeight = ref(store.dims.navbar.height)
    const leftSidebar = ref(null);
    const rightSidebar = ref(null);

    useKeyboardShortcuts(window);
    useDimensions(leftSidebar, rightSidebar)

    const {unsavedChanges, lastSaved} = useAutoSave(pageId)

    const workEasel = ref(null);

    return {
      store,
      projectStore,
      projectId,
      pageId,
      unsavedChanges,
      lastSaved,
      workEasel,
      leftSidebar,
      rightSidebar,
      navbarHeight,
      navbarHeightPx: `${navbarHeight.value}px`,
      exportProject,
    }
  },
  mounted() {
    document.html.style.overflowY = 'hidden';
  },
  data() {
    return {
      isExportingProject: false,
    }
  },
  computed: {
    scale(){
      return (this.store.workFrameScalingFactor * 100).toFixed(0)
    },
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    },
    centralAreaGridTemplateColumns() {
      return `${sharedConstants.PANEL_DIMENSIONS.left_sidebar.width}px 1fr ${sharedConstants.PANEL_DIMENSIONS.right_sidebar.width}px`
    },
    workEaselMaxWidth() {
      const extraSpace = 40;
      const occupiedWidth = sharedConstants.PANEL_DIMENSIONS.left_sidebar.width + sharedConstants.PANEL_DIMENSIONS.right_sidebar.width + extraSpace;
      return `calc(100vw - ${occupiedWidth}px)`
    }
  },
  methods: {
    async exportCurrentProject() {
      this.isExportingProject = true;
      await this.exportProject(this.projectId)
      this.isExportingProject = false;
    },
    setWorkFrameWidth(numObject) {
      /*
      We set the work frame width within the acceptable range.
       */
      const pixels = parseInt(numObject.css.val);

      const BREAKPOINTS = sharedConstants.BREAKPOINTS;

      if (pixels > BREAKPOINTS.desktop["max-width"]) {
        this.store.dims.workFrame.width = BREAKPOINTS.desktop["max-width"];
      } else if (pixels < BREAKPOINTS.mobile["min-width"]) {
        this.store.dims.workFrame.width = BREAKPOINTS.mobile["min-width"];
      } else {
        this.store.dims.workFrame.width = pixels
      }
    },
    async handleSignOut() {
      await signOut()
      window.location.reload()
    }

  },
};
</script>

<style scoped>
#whole-page {
  overscroll-behavior: none;
}

.navbar {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  background: #252525;
  color: #e1e1e1;
  font-size: 13px;
  border-bottom: 1px solid #6b6b6b;
  height:v-bind(navbarHeightPx);
  padding: 1rem .5rem;
  align-content: center;
}

.navbar-item {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  align-items: center;
}

#central-area {
  height: 100%;
  background: #494949;
  display: grid;
  grid-template-columns: v-bind(centralAreaGridTemplateColumns);


}

#work-easel {
  max-width: v-bind(workEaselMaxWidth);
}

.workspace-container {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0; /* Starts with minimum width and grows */
  display: flex;
  height: 100vh;
  max-height: 100%;
  overflow-x: clip;
}



</style>
