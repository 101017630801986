<template>
  <PageDiv :element="this.element"/>
</template>

<script>
import PageDiv from "@/components/element_components/PageDiv.vue";


export default {
  components: {PageDiv},
  props: {
    element: {
      type: Object,
      required: true,
    },
  },
}
</script>