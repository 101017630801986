<script lang="ts">
import {defineComponent} from 'vue'
import IconComponent from "@/components/IconComponent.vue";

export default defineComponent({
  components: {IconComponent},
  props: {
    isDjangoMode: {
      type: Boolean,
      required: true
    },
    tooltipPositionClass: {
      type: String,
      default: 'tooltip-left'
    }
  },
  data() {
    return {
      wantsInfo: false
    }
  },
  methods: {
    emitDjangoMode(value: boolean) {
      if (typeof value !== 'boolean') {
        throw new Error('Value must be a boolean')
      }
      this.$emit('update:isDjangoMode', value)
    }
  }
})
</script>

<template>


  <div
      class="flex items-center justify-between cursor-pointer border rounded-lg bg-gray-200 p-2 select-none"
      @click="emitDjangoMode(!isDjangoMode)"
      :class="[isDjangoMode ? 'border-green-600 text-green-900' : 'hover:border-gray-300']"
  >
    <div class="flex items-end gap-1" @mouseenter="wantsInfo = true" @mouseleave="wantsInfo = false">
      <IconComponent name="django" :style="{'width':'18px', 'height': '18px'}"></IconComponent>
      <span class="text-gray-700 whitespace-nowrap text-md">mode</span>
    </div>

    <div class="divider divider-horizontal mx-0"></div>

    <div class="flex items-center space-x-2 px-1 h-full">
        <span class="text-xs hover:text-gray-700"
              :class="[!isDjangoMode ? 'text-gray-700': 'text-gray-500']"
              @click.stop="emitDjangoMode(false)">
          Off
        </span>
      <input type="checkbox" class="toggle" :checked="isDjangoMode"/>
      <span class="text-xs hover:text-green-700"
            :class="[isDjangoMode ? 'text-green-600': 'text-gray-500']"
            @click.stop="emitDjangoMode(true)">On</span>
    </div>
    <div class="tooltip"
         :class="tooltipPositionClass"
         data-tip="Activating Django mode means we render Django templates 🐎">
      <div class="">
        <IconComponent name="question" :style="{'width':'18px', 'height': '18px'}"></IconComponent>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>