<template>
  <ControlGenericWrapper v-if="selectedElement">
    <template v-slot:label>
      Background
    </template>
    <template v-slot:content>

      <div class="pb-1 grid grid-cols-4">
        <CssLabel css-property="background-color" label="Bg color"/>
        <GenericInputColor
            :color="computedCssValue('background-color', '#00000000').value"
            @update:color="setLocalCssProperty('background-color', $event)"
            label="Background color"
        />
      </div>
    </template>
  </ControlGenericWrapper>
</template>

<script>
import ContextSelector from "@/components/ContextSelector.vue";
import useSelectedElement from "@/composables/useSelectedElement";
import GenericInputColor from "@/components/GenericInputColor.vue";
import CssLabel from "@/components/CssLabel.vue";
import ControlGenericWrapper from "@/components/ControlGenericWrapper.vue";


export default {
  components: {GenericInputColor, ContextSelector, CssLabel, ControlGenericWrapper},
  setup() {
    const { computedCssValue, selectedElement, setLocalCssProperty } = useSelectedElement();

    return { selectedElement, computedCssValue, setLocalCssProperty};
  },
};
</script>
<style scoped>
.base {
  background-color: #333333;
}

.first-layer {
  background-color: rgb(94, 89, 89);
  color: white;
}

</style>