import {renderDjangoTemplate} from "@/utils/apiRequests";

export interface Completion {
    id: number;
    data: string;
    context: object;
    screenshot_url: string;
}

export type Completions = Completion[];


export const renderHTML = async (completion: Completion, templateEngine: string) => {
    if (typeof templateEngine !== 'string') {
        throw new Error('Template engine must be a string')
    }

    if (templateEngine === 'django') {
        const payload = {
            template_code: completion.data,
            template_context: completion.context
        }
        const {response, data, error} = await renderDjangoTemplate(payload)
        return {
            text: data,
            error: error
        }
    } else {
        return {
            text: completion.data,
            error: ''
        }
    }
}