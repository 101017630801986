<template>
  <ControlGenericWrapper>
    <template v-slot:label>
      Link settings
    </template>
    <template v-slot:content>
      <div class="pb-1 grid grid-cols-4 items-center">
        <label>Url</label>
        <GenericInputText
            :value="selectedElement.link_block_url"
            class="input-field col-span-3 bg-[#252525]"
            name="link-block-url"
            placeholder="https://..."
            @update:value="(newUrl) => selectedElement.link_block_url = newUrl ? newUrl : null"
        />
      </div>
      <div class="grid grid-cols-4 items-center">
        <label>Open link in new tab</label>
        <GenericInputRadioGroup
            :current-value="selectedElement.link_block_open_in_new_tab"
            :options="[
                { description: 'Yes', value: true },
                { description: 'No', value: false },
            ]"
            class="grid col-span-3 grid-cols-2 gap-[1px]"
            name="link-block-open-in-new-tab"
            @update:value="selectedElement.link_block_open_in_new_tab = $event;"
        />
      </div>
    </template>

  </ControlGenericWrapper>
</template>

<script>
import { useCounterStore } from '@/stores/counter';

import Toggle from "@/components/Toggle.vue";
import ControlFormInput from "@/components/ControlFormInput.vue";
import GenericInputRadioGroup from "@/components/GenericInputRadioGroup.vue";
import ControlGenericWrapper from "@/components/ControlGenericWrapper.vue";
import GenericInputSelect from "@/components/GenericInputSelect.vue";
import GenericInputText from "@/components/GenericInputText.vue";

export default {
  components: {
    GenericInputText,
    GenericInputSelect, ControlGenericWrapper, GenericInputRadioGroup, ControlFormInput,
    Toggle
  },
  setup() {
    const store = useCounterStore();
    const selectedElement = store.selectedElement;

    return {
      selectedElement
    };
  },
};
</script>
