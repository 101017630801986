<script>
import {VueFinalModal, useVfm} from 'vue-final-modal'
import IconComponent from "@/components/IconComponent.vue";
import {useClipboard} from "@vueuse/core";
import {useToast} from "vue-toastification";



export default {
  components: {IconComponent, VueFinalModal},
  props: {
    showSavedPrompt: {
      type: Boolean,
      default: true
    },
  },
  setup() {
    return {
      toast: useToast(),
      vfm: useVfm()
    }
  },
  methods: {
    close() {
      this.vfm.close(this.modalId)
    },
    copyEmail() {
      const {copy} = useClipboard({legacy: true});
      copy(this.supportEmail)
      this.toast.success('Copied email to your clipboard 📋');
    }
  },
  computed: {
    userPrompt() {
      return sessionStorage.getItem("initial_user_prompt")
    },
    supportEmail() {
      // We use javascript to minimize bots scraping the email address.
      return ['support', '@', 'photondesigner.com'].join('');
    }
  }
}
</script>


<template>
  <VueFinalModal
      class="flex justify-center items-center"
      content-class="flex flex-col max-w-xl mx-4 p-4 bg-white border dark:border-gray-700 rounded-lg space-y-2 select-none"
  >
    <h2 class="text-lg font-bold text-center">Support / Questions / Suggestions</h2>


    <p class="text-center">

      Email me (Tom 🙂) with any bugs / questions / suggestions at:
      <button
          @click="copyEmail"
          class="tooltip tooltip-right btn btn-link btn-neutral"
          data-tip="Click to copy email to clipboard"
      >
        <span class="text-md">
        {{ supportEmail }}
        </span>
      </button>
    </p>
    <p class="text-center">
      I'll get back to you asap 💨
    </p>
    <div class="flex justify-center">
      <button @click="close" class="btn btn-outline btn-neutral btn-xs">Close</button>
    </div>

  </VueFinalModal>
</template>

<style>
</style>