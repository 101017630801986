<template xmlns="http://www.w3.org/1999/html">
  <div class="sidebar">
    <div class="flex my-2">
      <div class="w-full text-center">Add element</div>
      <button @click="this.store.setLeftSidebarToDefault()">
        <span><IconComponent name="close"></IconComponent></span>
      </button>
    </div>

    <div>

      <section>

        <LeftSidebarAddElementGenerate/>

        <div class="mt-2 py-2 border-t-2 select-none">
          <header class="flex justify-center gap-1 items-center">
            <p class="text-center">Precise way</p>
            <IconComponent class="icon" name="microscope"></IconComponent>
          </header>
          <div class="grid grid-cols-3 gap-1 px-0.5">
            <button v-for="(item, component_name) in elementsToAdd" :key="component_name"
                    class="element"
                    @click="handleAddElement(component_name)">

            <span>
              <IconComponent class="icon" :name="item.icon_name"></IconComponent></span>
              <span>{{ item.label }}</span>
            </button>
          </div>
        </div>

      </section>
    </div>

  </div>

</template>

<script>
import Navigator from "@/components/Navigator.vue";
import LeftSidebarContextEditor from "@/components/LeftSidebarContextEditor.vue";
import {Icon} from "@iconify/vue";
import {useCounterStore} from "@/stores/counter";
import {addPageComponent} from "@/utils/elementCreator";
import {useRoute} from 'vue-router';
import IconComponent from "@/components/IconComponent.vue";
import GenericInputRadioGroup from "@/components/GenericInputRadioGroup.vue";
import {COMPONENTS_DATA} from "../../../shared_constants.json";
import LeftSidebarAddElementGenerate from "@/components/LeftSidebarAddElementGenerate.vue";


export default {
  name: "LeftSidebarAddElement",
  components: {
    LeftSidebarAddElementGenerate,
    GenericInputRadioGroup, IconComponent, Icon, Navigator, LeftSidebarContextEditor
  },

  setup() {
    const route = useRoute();
    const projectId = route.params.projectId
    const pageId = route.params.pageId
    const store = useCounterStore();

    // We filter out the components by key that the user can add directly to the page.
    const elementsToAdd = {};
    for (const key in COMPONENTS_DATA) {
      if (COMPONENTS_DATA[key].user_can_add) {
        elementsToAdd[key] = COMPONENTS_DATA[key];
      }
    }

    return {store, pageId, projectId, addPageComponent, elementsToAdd}
  },
  methods: {
    handleAddElement(component_name) {
      /*
      We show the navigator after the user adds an element.
       */
      addPageComponent(component_name, this.pageId, this.store);
      this.store.setLeftSidebarToDefault()
    },
  }
}
</script>

<style scoped>
.sidebar {
  min-width: 200px;
  background-color: #2d2d2d;
}

.element {
  min-height: 100px;
  text-align: center;
  cursor: pointer;
  font-size: smaller;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: lightgray;
}

.element:hover {
  background-color: #545454;
  color: white;
}

.icon {
  fill: white;
  width: 30px !important;
}


</style>