<template>
  <div>
    <div v-show="!imageLoaded"
         :style="[imageStyle, extraStyle]"
         class="image-skeleton"
    ></div>
    <Transition name="scale-fade">
      <img v-show="imageLoaded" :src="imageUrl"
           :style="[imageStyle, extraStyle]"
           :width="w" :height="h"
           alt="Preview" @load="onImageLoad"
      />
    </Transition>
  </div>
</template>

<script>
export default {
  props: {
    imageUrl: {
      type: String,
      required: true,
    },
    w: {
      type: Number,
      required: true,
    },
    h: {
      type: Number,
      required: true,
    },
    extraStyle: {
      type: Object,
      default: () => ({}),
    },

  },
  data() {
    return {
      imageLoaded: false,
    };
  },
  computed: {
    imageStyle() {
      return {
        width: `${this.w}px`,
        height: `${this.h}px`,
      };
    },
  },
  methods: {
    onImageLoad() {
      this.imageLoaded = true;
    },
  },
};
</script>

<style scoped>
.image-skeleton {
  background-color: #f0f0f0;
  animation: skeleton-loading 1s infinite;
}

@keyframes skeleton-loading {
  0% {
    background-color: #f0f0f0;
  }
  50% {
    background-color: #e0e0e0;
  }
  100% {
    background-color: #f0f0f0;
  }
}

</style>