<template>
  <div>
    <div class="pb-1 grid grid-cols-4">
      <div class="whitespace-nowrap">Row gap</div>
      <GenericInputNumUnit
          :css-value="computedCssValue('row-gap', '0px').value"
          @update:num-object="setLocalCssProperty('row-gap', $event.css.val)"
      />
      <div class="pl-2 whitespace-nowrap">Col gap</div>
      <GenericInputNumUnit
          :css-value="computedCssValue('column-gap', '0px').value"
          @update:num-object="setLocalCssProperty('column-gap', $event.css.val)"
      />
    </div>

    <div class="p-2">
      <div class="flex justify-between py-1 w-full">
        <div>
          Columns <span>({{ columns.length }})</span>
        </div>
        <div class="flex justify-end cursor-pointer">
          <div @click="addGridTemplateProperty('columns')">
            <IconComponent name="add"/>
          </div>
        </div>
        <div>
        </div>
      </div>
      <div class="border border-gray-400 rounded-lg p-1">

        <div class="pb-1 grid grid-cols-8" v-for="(col, index) in columns" :key="index">
          <div class="col-span-7 flex">
            <IconComponent name="column" style="font-size: 22px; color: lightgray"/>
            <GenericInputNumUnit
                class="px-3"
                :css-value="col"
                @update:num-object="updateGridTemplateProperty('columns', index, $event.css.val)"
            />
          </div>
          <div v-if="index !== 0" class="flex justify-center">
            <button class="m-0 items-center cursor-pointer" @click="removeGridTemplateProperty('columns', index)">
              <IconComponent name="bin" style="font-size: 15px; color: lightgray"/>
            </button>
          </div>
        </div>

      </div>
    </div>

    <div class="p-2">
      <div class="flex justify-between py-1 w-full">
        <div>
          Rows <span>({{ rows.length }})</span>
        </div>
        <div class="flex justify-end cursor-pointer">
          <div @click="addGridTemplateProperty('rows')">
            <IconComponent name="add"/>
          </div>
        </div>
        <div>
        </div>
      </div>
      <div class="border border-gray-400 rounded-lg p-1">

        <div class="pb-1 grid grid-cols-8" v-for="(row, index) in rows" :key="index">
          <div class="col-span-7 flex">
            <IconComponent name="row" style="font-size: 22px; color: lightgray"/>
            <GenericInputNumUnit
                class="px-3"
                :css-value="row"
                @update:num-object="updateGridTemplateProperty('rows', index, $event.css.val)"
            />
          </div>
          <div v-if="index !== 0" class="flex justify-center">
            <button class="m-0 items-center cursor-pointer" @click="removeGridTemplateProperty('rows', index)">
              <IconComponent name="bin" style="font-size: 15px; color: lightgray"/>
            </button>
          </div>
        </div>

      </div>

    </div>

  </div>
</template>

<script>
import GenericInputNumUnit from "@/components/GenericInputNumUnit.vue";
import IconComponent from "@/components/IconComponent.vue";

export default {
  components: {
    IconComponent,
    GenericInputNumUnit
  },
  props: {
    computedCssValue: Function,
    setLocalCssProperty: Function,
  },
  data(props) {
    const columns = props.computedCssValue('grid-template-columns', '').value.split(' ').filter(Boolean) || ['1fr'];
    const rows = props.computedCssValue('grid-template-rows', '').value.split(' ').filter(Boolean) || ['1fr'];
    return {
      columns,
      rows
    };
  },
  computed: {
    currentDisplay() {
      return this.computedCssValue("display", "").value;
    }
  },
  methods: {
    updateGridTemplateProperty(property, index, value) {
      this[property][index] = value;
      this.setLocalCssProperty(`grid-template-${property}`, this[property].join(' '));
    },
    removeGridTemplateProperty(property, index) {
      if (this[property].length > 1) {
        this[property].splice(index, 1);
        this.setLocalCssProperty(`grid-template-${property}`, this[property].join(' '));
      }
    },
    addGridTemplateProperty(property) {
      this[property].push('1fr');
      this.setLocalCssProperty(`grid-template-${property}`, this[property].join(' '));
    },
    clearGridProperties() {
      /*
      We call this when the user switches from grid to another display type.
       */
      this.setLocalCssProperty('grid-template-columns', '');
      this.setLocalCssProperty('grid-template-rows', '');
      this.columns = [];
      this.rows = [];
    },
    addDefaultGridProperties() {
      /*
      We call this when the user switches from grid to another display type.
       */
      if (!this.columns.length) {
        this.columns.push('1fr');
      }
      if (!this.rows.length) {
        this.rows.push('1fr');
      }
      // Update CSS properties based on current state
      this.setLocalCssProperty('grid-template-columns', this.columns.join(' '));
      this.setLocalCssProperty('grid-template-rows', this.rows.join(' '));
    }
  },
  watch: {
    currentDisplay(newVal) {
      /*
      We clear or add default grid properties based on the current display type.
       */
      if (newVal !== 'grid') {
        this.clearGridProperties();
      } else {
        this.addDefaultGridProperties();
      }
    }
  },
  mounted() {
    this.currentDisplay === 'grid' ? this.addDefaultGridProperties() : this.clearGridProperties();
  }

}
</script>

<style scoped>

</style>
