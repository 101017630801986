<template>

  <div class="mt-2 py-2 border-t-2 select-none">
    <header class="flex justify-center gap-1">
      <p class="text-center">Fast way</p>
      <IconComponent class="icon" name="hare"></IconComponent>
    </header>

    <div class="text-center m-2">
      <p>Describe the UI element to generate</p>
      <button class="p-2 border rounded" @click="generate()">
        Generate
      </button>
    </div>
  </div>

</template>

<script>
import {useCounterStore} from "@/stores/counter";
import {addPageComponent} from "@/utils/elementCreator";
import {useRoute} from 'vue-router';
import IconComponent from "@/components/IconComponent.vue";
import {useModal, useModalSlot} from 'vue-final-modal'
import ModalCompletion from "@/components/ModalCompletion.vue";

import {useCompletionsStore} from "@/stores/completions";


export default {
  components: {IconComponent},

  setup() {
    const route = useRoute();
    const projectId = route.params.projectId
    const pageId = route.params.pageId
    const store = useCounterStore();
    const generationsStore = useCompletionsStore();
    const {open} = useModal({component: ModalCompletion})

    return {
      store, generationsStore, pageId, projectId, addPageComponent, open
    }
  },
  data() {
    return {
      userPrompt: "",
    }
  },
  methods: {
    async generate() {
      this.open()
    },
  },
}
</script>

<style scoped>
.sidebar {
  min-width: 200px;
  background-color: #2d2d2d;
}

.element {
  min-height: 100px;
  text-align: center;
  cursor: pointer;
  font-size: smaller;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: lightgray;
}

.element:hover {
  background-color: #545454;
  color: white;
}

.icon {
  fill: white;
  width: 30px !important;
}


</style>