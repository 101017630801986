<template>
  <div v-if="onlyRoot" id="add-first-element-container">
    <button @click="openAddElement" id="add-first-element-button">
      <IconComponent name="add" style="{'width': '25px'}"></IconComponent>
      <span class="test">Add your first new element</span>
    </button>
  </div>

  <PageDiv :element="element" :movable="false"/>

</template>

<script>
import PageDiv from "@/components/element_components/PageDiv.vue";
import {useCounterStore} from "@/stores/counter";
import IconComponent from "@/components/IconComponent.vue";


export default {
  name: 'PageRoot',
  components: {IconComponent, PageDiv},
  props: {
    element: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const store = useCounterStore()
    return {
      store
    }
  },
  computed: {
    onlyRoot() {
      return this.store.elements.filter(el => el.is_deleted === false).length === 1
    }
  },
  mounted() {
    this.selectRootElementIfOnlyRoot()
  },
  watch: {
    onlyRoot() {
      this.selectRootElementIfOnlyRoot()
    }
  },
  methods: {
    openAddElement() {
      this.store.setLeftSidebar({selectedPane: 'LeftSidebarAddElement', isShowing: true})
    },
    selectRootElementIfOnlyRoot() {
      /*
      We do this to make the initial blank page easier for the user to understand.
      We want to select the root element if it's the only element on the page.
       */
      if (this.onlyRoot) {
        this.store.selectRootElement()
      }
    }
  }

}
</script>

<style>
.test {
  color: red;
}

</style>


