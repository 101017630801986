<template>
  <div>
    <div class="">
      <nav class=" flex divide-x divide-gray-200 rounded-lg shadow" aria-label="Tabs">
        <button v-for="tab in tabs"
                @click="selectTab(tab.name)"
                class="text-gray-900 rounded-l-lg group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-center text-sm font-medium hover:bg-gray-50"
        >
          <Icon :icon="tab.icon" class="h-4 w-4"></Icon>
          <span aria-hidden="true"
                :class="modelValue === tab.name ? 'bg-indigo-500' : 'bg-transparent'"
                class="absolute inset-x-0 bottom-0 h-0.5"></span>
        </button>
      </nav>
    </div>
  </div>
</template>

<script>
import {Icon} from "@iconify/vue";

export default {
  name: 'ElementEditorTabs',
  components: {Icon},
  props: {
    tabs: {
      type: Array,
      required: true,
    },
    modelValue: {
      type: String,
      required: true,
    },
  },
  methods: {
    selectTab(tabName) {
      this.$emit('update:modelValue', tabName);
    },
  }
};
</script>
