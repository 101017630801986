import {createRouter, createWebHistory} from 'vue-router'
import RouteDesigner from './components/RouteDesigner.vue'
import RouteDashboard from "@/components/RouteDashboard.vue";
import Cookies from "universal-cookie";
import RouteSignUp from "@/components/RouteSignUp.vue";
import RouteSignIn from "@/components/RouteSignIn.vue";
import RouteEdit from "@/components/RouteEdit.vue";
import RouteProfile from "@/components/RouteProfile.vue";
import RoutePricing from "@/components/RoutePricing.vue";
import CompletionStream from "@/components/CompletionStream.vue";


const routes = [
  {path: '/sign-in', name: "signIn", component: RouteSignIn},
  {path: '/sign-up', name: "signUp", component: RouteSignUp},
  {path: '/', name: "home", component: RouteEdit},
  {path: '/profile', name: "profile", component: RouteProfile},
  {path: '/pricing', name: "pricing", component: RoutePricing},
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach(async (to, from) => {
  /*
  We check if the user is marked as signed in.
   */
  const cookies = new Cookies();
  const isSignedIn = cookies.get('pd_is_signed_in');


  const toSignInRoute = to.name === 'signIn'
  const toSignUpRoute = to.name === 'signUp'

  const toSignUpOrSignInRoute = toSignInRoute || toSignUpRoute

  const publicRoutes = ['edit', 'home', 'pricing', 'stream']
  if (publicRoutes.includes(to.name)) {
    return
  }

  if (isSignedIn && (toSignUpOrSignInRoute)) {
    return {name: 'home'}
  }
  if (!isSignedIn && !toSignUpOrSignInRoute) {
    return {name: 'signIn'}
  }
})

export default router
