<template>
  <div class="work-easel">
    <div class="work-frame-wrapper">
      <iframe ref="workFrame" :style="workFrameStyle" class="work-frame" @load="onIframeLoad"></iframe>
      <div
          :style="resizeHandleStyle"
          class="resize-handle-container"
          @mousedown="startResize"
      >
        <div class="handle">
          <IconComponent name="resize-handle" style="transform: rotate(90deg)"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import workFrameCss from '../assets/all_workframe.css?raw'; // We import the css as a string to inject into the iframe.
import {createApp, nextTick, ref} from 'vue';
import PageElementHud from "@/components/PageElementHud.vue";
import PageEditor from "@/components/PageEditor.vue";
import {useCounterStore} from "@/stores/counter";
import IconComponent from "@/components/IconComponent.vue";
import {useResizeHandleStyle} from "@/composables/useResizeHandleStyle";
import sharedConstants from "../../../shared_constants.json";


export default {
  components: {IconComponent, PageElementHud },
  props: ['element'],
  setup() {
    const store = useCounterStore();
    const workFrame = ref(null);
    const { resizeHandleStyle } = useResizeHandleStyle(workFrame, store);
    const navBarHeight = ref(store.dims.navbar.height)


    return {
      workFrame,
      store,
      resizeHandleStyle,
      navBarHeight,
    };
  },
  data() {
    return {
      isResizing: false,
      initialX: 0,
      initialWidth: 0,
      initialHeight: 0,
    }
  },
  computed: {
    workFrameStyle() {
      /*
      We apply this style to the workFrame, which is the iframe that contains the page editor.
      We want to avoid scaling the workFrame's height and width.
       */
      return {
        'height': `${this.store.dims.workFrame.height}px`,
        'max-height': `calc(100% - ${this.navBarHeight}px)`,
        'width': `${this.store.dims.workFrame.width}px`,
        'min-width': `${this.store.currentBreakpointMinWidth}px`,
        'max-width': `${this.store.currentBreakpointMaxWidth}px`,
        'pointer-events': this.isResizing ? 'none' : 'auto',
        'user-select': this.isResizing ? 'none' : 'auto',
        'transform-origin': '0px 0px',
        'transform': `scale(${this.store.workFrameScalingFactor})`,

      }
    },
  },
  methods: {
    onIframeLoad() {
      this.injectContent();
    },

    async injectContent() {
      /*
      Inject the content into the iframe and create the app.
       */
      await nextTick()
      const iframeDoc = this.workFrame?.contentDocument; // The iframe's document object.

      if (!iframeDoc) {
        throw new Error('iframeDoc is null')
      }
      iframeDoc.open();
      iframeDoc.write('' +
          '<!DOCTYPE html>' +
          '<html lang="en" style="scrollbar-width: none;">' +
          '<head>' +
          '<meta charset="UTF-8">' +
          `<style>${workFrameCss}</style>`  +
          '</head>' +
          '<body id="inner-app"></body>' +
          '</html>'
      );
      iframeDoc.close();

      this.workFrame.style.background = 'white'

      this.addIframeToStore(this.workFrame);

      const appTarget = iframeDoc.getElementById('inner-app');
      const app = createApp(PageEditor, {});

      app.mount(appTarget);
    },

    addIframeToStore(iframe) {
      /*
      We add the iframe to the store so that we can access it from the parent window.
      This includes the iframe's contentWindow and contentDocument, which we need to
      access the elements in the correct window.
       */
      this.store.workFrame = iframe;
    },

    startResize(event) {
      this.isResizing = true;
      this.initialX = event.clientX;
      this.initialWidth = this.store.dims.workFrame.width;
      this.initialHeight = this.store.dims.workFrame.height;

      document.addEventListener('mousemove', this.resize);
      document.addEventListener('mouseup', this.stopResize);
    },

    resize(event) {
      /*
      We permit resizing within the min and max width of the current breakpoint.
      */
      const mouseDeltaX = event.clientX - this.initialX;
      const smoothingFactor = 1;
      const deltaX = mouseDeltaX * smoothingFactor  // We add a smoothing factor to make the resize feel smoother.

      const maxWidth = sharedConstants.BREAKPOINTS.desktop["max-width"]
      const minWidth = sharedConstants.BREAKPOINTS.mobile["min-width"]

      const aboveMinWidth = this.initialWidth + deltaX > minWidth
      const belowMaxWidth = this.initialWidth + deltaX < maxWidth


      // Permit user's resize.
      if (aboveMinWidth && belowMaxWidth) {
        this.store.dims.workFrame.width = this.initialWidth + deltaX;
      }
      // If we are above the max width, we set the width to the max width.
      else if (!belowMaxWidth) {
        this.store.dims.workFrame.width = maxWidth;
      }
      // If we are below the min width, we set the width to the min width.
      else if (!aboveMinWidth) {
        this.store.dims.workFrame.width = minWidth
      }
    },

    stopResize() {
      this.isResizing = false;

      document.removeEventListener('mousemove', this.resize);
      document.removeEventListener('mouseup', this.stopResize);
    }
  }
};
</script>

<style>

:root {
  --handle-bg: #575757;
  --handle-bg-hover: deepskyblue;
}

.work-easel {
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  background: #494949;
}

.work-frame-wrapper {
  position: relative;
  display: block;
  max-width: 97%;  /* We set this max-width to prevent the workFrame from growing too large. */
}

.work-frame {
  border: 1px solid #494949;
}

.resize-handle-container {
  position: absolute;
  top: 0;
  width: 6px;
  background: transparent;
  height: 100%;
  cursor: col-resize;
  user-select: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.resize-handle-container:hover {
  background: var(--handle-bg-hover);
}

.handle {
  display: flex;
  justify-content: center;
  width: 16px;
  height: 40px;
  background: var(--handle-bg);
  align-items: center;
  color: lightgrey;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.handle:hover, .resize-handle-container:hover .handle{
  background: var(--handle-bg-hover);
}


iframe {
  border: 1px solid #ccc;
}


</style>
