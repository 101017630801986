<template>
  <section class="main-style" v-show="!sentConfirmEmail">
    <h1 class="h1-style">
      Sign up to Photon Designer
    </h1>

    <p class="signup-text">
      Already have an account?
      <a class="link-sign-in" href="/sign-in">Sign in</a>.
    </p>

    <br>

    <div class="div-google-signin">
      <div id="g_id_onload"
           :data-login_uri="loginUri"
           data-client_id="693882164295-jpdsciu4lmvcpfg15ljmftvtsqn22smb.apps.googleusercontent.com"
           data-context="signin"
           data-itp_support="true"
           data-ux_mode="redirect">
      </div>

      <div class="g_id_signin"
           data-logo_alignment="left"
           data-shape="pill"
           data-size="large"
           data-text="signin_with"
           data-theme="outline"
           data-type="standard">
      </div>
    </div>

    <div class="div-or-separator">
      <div class="separator-line"></div>
      <span class="separator-text">OR</span>
      <div class="separator-line"></div>
    </div>

    <div>
      <div class="div-input">
        <label class="label-email" for="first_name">Name</label>
        <input id="first_name" v-model="first_name" autocomplete="true" class="input-email"
               required
               name="first_name" placeholder="Alan" type="text">
      </div>
      <div class="div-input">
        <label class="label-email" for="email">Email</label>
        <input id="email" v-model="email" autocomplete="true" class="input-email"
               required
               name="email" placeholder="alan.turing@example.com" type="email">
      </div>

      <div class="div-password-input">
        <div class="password-label-wrapper">
          <label class="label-password" for="password">Password</label>
        </div>
        <input id="password" v-model="password" autocomplete="true" class="input-password" name="password"
               placeholder="••••••••••••" type="password">
      </div>

      <button class="continue-button btn" @click="signUp()">
        Create account
        <span v-show="isSubmitting" class="continue-button-icon">
          <IconComponent name="loader"/>
        </span>
      </button>
    </div>

    <p class="terms-text">
      By signing up, you agree to our
      <a class="link-terms-service" href="/legal/terms-of-service">Terms of Service</a>
      and
      <a class="link-privacy-policy" href="/legal/privacy-policy">Privacy Policy</a>.
    </p>

  </section>
  <section class="main-style" v-show="sentConfirmEmail">
    <div class="flex h-screen items-center justify-center">
      <div class="mx-auto w-full max-w-md space-y-2 text-center">
        <div class="flex justify-center h-20">
          <IconComponent name="email"></IconComponent>
        </div>
        <div class="flex flex-col space-y-8 text-center">
          <div class="flex flex-col space-y-2"><h2 class="text-xl tracking-[-0.16px] text-slate-12 font-bold">
            Thanks {{ first_name }} - Check your email</h2><span
              class="text-sm text-slate-11 font-normal">We just sent a verification link to {{ email }}</span></div>
          <a class="button-to-sign-in" href="/sign-in">Go to sign in
            <span class="px-2">
              <IconComponent name="arrow" style="transform: rotate(90deg)"></IconComponent>
            </span>
          </a>
        </div>
      </div>
    </div>

  </section>
</template>


<script>
import {useFetchJson} from "@/composables/useFetchJson";
import {useToast} from "vue-toastification";
import IconComponent from "@/components/IconComponent.vue";
import {BACKEND_PATHS} from "@/constants";

export default {
  components: {IconComponent},
  data() {
    return {
      name: '',
      email: '',
      password: '',
      isSubmitting: false,
      sentConfirmEmail: false,
      loginUri: `${import.meta.env.VITE_OX_URL}/${BACKEND_PATHS.AUTH_GOOGLE()}`
    };
  },
  setup() {
    const toast = useToast()
    return {
      toast
    }
  },
  methods: {
    async signUp() {
      this.isSubmitting = true

      const postData = {
        operation: 'sign_up',
        first_name: this.first_name,
        email: this.email,
        password: this.password,
      };

      const options = {method: 'POST', body: JSON.stringify(postData)}
      const path = BACKEND_PATHS.AUTH_EMAIL_AND_PASSWORD()
      const url = `${import.meta.env.VITE_OX_URL}/${path}`
      const {data, error} = await useFetchJson(url, options)

      if (!error.value) {
        this.sentConfirmEmail = true
        return data.value
      } else {
        const field = Object.keys(data.value.error)[0]
        const error = data.value.error[field][0]

        if (field === "email") {
          this.toast.error("An account with this email already exists.\nPlease sign in instead")
        } else if (field === "first_name") {
          this.toast.error("Please enter your name")
        } else {
          const message = `${field}, ${error}`
          this.toast.error(message)
        }
      }
      this.isSubmitting = false
    },
  },
  created() {
    const script = document.createElement('script')
    script.src = 'https://accounts.google.com/gsi/client'
    script.async = true
    script.defer = true
    document.body.appendChild(script)
  },
};
</script>

<style>
:root {
  --sign-up-color-1st: #180b0b;
  --sign-up-color-2nd: #5d5d5d;
  --sign-up-color-3rd: #f5f5f5;
}
</style>

<style scoped>
.main-style {
  margin: auto;
  width: 100%;
  max-width: 450px;
}

.h1-style {
  margin-top: 2rem;
  text-align: center;
  font-size: 1.25rem;
  letter-spacing: -0.16px;
  color: var(--sign-up-color-1st);
  font-weight: bold;
}

.div-google-signin {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  justify-content: center;
  min-height: 60px;
}

.div-or-separator {
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.separator-line {
  height: 1px;
  width: 100%;
  background-color: var(--sign-up-color-3rd);
}

.separator-text {
  margin: 0 1rem;
  font-size: 0.75rem;
  color: var(--sign-up-color-1st);
  font-weight: normal;
}

.div-input,
.div-password-input {
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.password-label-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.label-email,
.label-password {
  display: block;
  font-size: 0.875rem;
  color: var(--sign-up-color-1st);
  cursor: default;
}

.input-email,
.input-password {
  height: 2.5rem;
  background-color: var(--sign-up-color-3rd);
  color: var(--sign-up-color-1st);
  padding: 0.75rem;
  border-radius: 0.375rem;
  border: 1px solid #f5f5f5;
  width: 100%;
  outline: none;
}


.continue-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  padding: 0 1rem;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  font-weight: 500;
  background-color: black;
  color: white;
  border: 1px solid #f5f5f5;
  width: 100%;
  cursor: pointer;
}


.terms-text,
.signup-text {
  font-size: 0.75rem;
  color: var(--sign-up-color-2nd);
  font-weight: normal;
  text-align: center;
}

.link-terms-service,
.link-privacy-policy,
.link-sign-in {
  color: blue;
  text-decoration: none;
}

.button-to-sign-in {
  cursor: pointer;
  background-color: white;
  color: var(--sign-up-color-1st);
  margin: auto;
  display: flex;
  width: 10rem;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  padding: 0 1rem;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  font-weight: 500;
  border: 1px solid var(--sign-up-color-2nd);
  cursor: pointer;
}

.button-to-sign-in:disabled:hover {
  background-color: black; /* disabled:hover:bg-black */
}

.button-to-sign-in:hover {
  background-color: rgba(255, 255, 255, .9); /* dark:hover:bg-white/90 */
}

.button-to-sign-in:focus-visible {
  background-color: rgba(255, 255, 255, .9); /* dark:focus-visible:bg-white/90 */
}


.button-to-sign-in:disabled:hover {
  background-color: white; /* dark:disabled:hover:bg-white */
}


.continue-button-icon {
  margin-left: 0.5rem;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}



</style>
