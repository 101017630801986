import {ref} from 'vue';
import {signOut} from "@/utils/apiRequests";
import {BACKEND_PATHS} from "@/constants";

export async function useFetchJson(url, options = {}, logErrors = true) {
  /*
  Fetch data from our api and return the data, error, and loading state.
   */
  const data = ref(null);
  const error = ref(null);
  const loading = ref(true);
  const response = ref(null);

  const defaultHeaders = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  };

  const fetchOptions = {
    headers: {...defaultHeaders, ...options.headers},
    credentials: 'include',
    ...options,
  };


  const fetchData = async () => {
    response.value = await fetch(url, fetchOptions);
    if (!response.value.ok) {
      if (logErrors) {
        console.error('useFetchJson error for:' + url + ' status: ' + response.value.status + ' ' + response.value.statusText)
      }
      error.value = response.value.statusText;
      data.value = await response.value.json();

      if (response.value.status === 401) { // Unauthorized. Sign out user and redirect to sign in.
        await signOut()
        window.location.href = `${import.meta.env.VITE_OX_URL}/${BACKEND_PATHS.SIGN_IN()}`
      }

    } else {
      data.value = await response.value.json();
    }
    loading.value = false;
  }

  await fetchData();

  return {data, error, loading, response}
}
