<template>
  <NavBar/>

  <div class="flex flex-col">
    <div class="bg-white p-8">

      <div class="flex flex-col items-center mb-8">
        <h2 class="text-2xl font-semibold mt-4">{{ profile.first_name }}</h2>
        <p class="text-gray-600">{{ profile.email }}</p>
        <p class="text-gray-600">
          Generations used: <span>{{ profile.completions_used }}</span>
        </p>

        <div class="flex flex-col mt-4">
          <router-link v-if="!profile.is_subscribed" :to="{name: 'pricing'}">
            <button class="btn btn-primary btn-outline">
              Upgrade to Pro
            </button>
          </router-link>
          <button
              v-if="profile.is_subscribed"
              class="btn btn-outline btn-primary btn-sm"
              @click="goToCustomerPortal">
            <span v-show="!isLoadingCustomerPortal">Manage your subscription</span>
            <span v-show="isLoadingCustomerPortal" class="loading loading-spinner loading-md"></span>

          </button>
        </div>

      </div>


      <div class="flex flex-col">
        <h2 class="divider text-xl" style="font-weight: 600">Recent generations</h2>
      </div>
      <div class="w-full grid gap-5 place-content-center"
           style="grid-template-columns: repeat(auto-fill, 220px);"
      >
        <div class="completion-preview h-full w-full flex flex-col">
          <router-link :to="{name: 'home', query: {fresh: true}}">
            <div class="version-container">
              <div class="content">
                <div class="frame-container indicator">
                  <button class="badge badge-outline badge-primary indicator-item indicator-center indicator-middle ">
                    + Create a new generation
                  </button>


                </div>
              </div>
            </div>
          </router-link>

        </div>

        <div
            v-for="completion in completions"
            class="completion-preview h-full w-full flex flex-col"
        >
          <router-link
              class="version-wrapper"
              :to="{name: 'home', query: {'set': completion.set}}"
          >
            <div class="version-container">
              <div class="content">
                <div class="frame-container">
                  <div class="frame-border">
                    <div class="frame">
                      <div class="iframe-container">
                        <GradientImage :image-url="completion.screenshot_url"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </router-link>
          <div class="py-2 creator-details">
            <p class="text-xs truncate ...">{{ completion.user_prompt }}</p>
          </div>

        </div>
      </div>
    </div>


  </div>


</template>


<script>

import IconComponent from "@/components/IconComponent.vue";
import ProjectCreator from "@/components/ProjectCreator.vue";
import {
  signOut, getCompletionsPublic, postCompletion, getProfile,
  getCustomerPortal,
  getCompletionsPrivate
} from "@/utils/apiRequests";
import {useToast} from "vue-toastification";
import NavBar from "@/components/NavBar.vue";
import {useModal} from "vue-final-modal";
import ModalBeforeCompletion from "@/components/ModalBeforeCompletion.vue";
import {useAuthUtils} from "@/composables/useAuthUtils";
import GradientImage from "@/components/GradientImage.vue";


export default {
  components: {GradientImage, NavBar, IconComponent, ProjectCreator},
  async mounted() {
    this.profile = await getProfile()
    this.completions = await getCompletionsPrivate({"user-completions": true})

    if (this.$route.query.subscription === 'success') {
      this.toast.success(
          "Congrats! Pro subscription unlocked 🔑", {
            timeout: 5000,
            position: "top-center",
            closeOnClick: true,

          }
      )
      this.$router.replace({query: {}})
    }

  },
  setup() {
    const {open} = useModal({component: ModalBeforeCompletion})
    const {isSignedIn} = useAuthUtils()
    return {
      toast: useToast(),
      openLoginModal: open,
      isSignedIn: isSignedIn
    }
  },
  data() {
    return {
      userPrompt: "",
      completions: [],
      profile: {},
      isLoadingCustomerPortal: false,
    };
  },

  methods: {
    async goToCustomerPortal() {
      this.isLoadingCustomerPortal = true

      const {url} = await getCustomerPortal()
      if (!url.startsWith('http')) {
        this.isLoadingCustomerPortal = false
        this.toast.error(
            "Failed to get customer portal. Please contact support and we'll help you out asap.",
            {
              timeout: 5000,
            }
        )
      } else {
        window.location.href = url
      }
    }
  },
}

</script>

<style scoped>


.version-wrapper {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  display: flex;
  flex: 1;
  flex-direction: column-reverse;
  overflow: auto;
}

.version-wrapper::-webkit-scrollbar {
  display: none;
}


.version-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.version-container .content {
  position: relative;
  display: flex;
  height: 100%;
  min-height: 25px;
  width: 100%;
  min-width: 40px;
  cursor: pointer;
  border-radius: 4px;
  outline: none;
}

.version-container .frame-container {
  position: relative;
  display: flex;
  width: 100%;
  aspect-ratio: 16 / 9;
  overflow: hidden;
  border-radius: 4px;
  border: 1px solid #d2d2d2;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

.version-container .frame-container:hover {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
}

.version-container .frame {
  height: 100%;
  width: 100%;
}

.version-container .iframe-container {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.version-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 1150px;
  height: 576px;
  transform-origin: top left;
  transform: scale(0.2);
  background: white;
  opacity: 1;
  pointer-events: none;
}


#headline-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px;
  font-size: 24px;
  font-weight: bold;
  min-width: 60%;
}

#generation-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}

#prompt-textarea {
  min-width: 500px;
  min-height: 100px;
  margin: 20px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #575757;
  border-radius: 5px;
  resize: none;
  outline: none;
}

#generate-button {
  padding: 10px 20px;
  max-width: fit-content;
  font-size: 16px;
  background-color: #001e00;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

#generate-button:hover {
  background-color: #1f3600;
  transition: background-color 0.2s ease-out;
}


.main-content {
  display: flex;
  padding: 20px;
}

.sidebar {
  width: 200px;
  background-color: #fff;
  padding: 20px;
  margin-right: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.sidebar-link {
  text-decoration: none;
  color: #333;
  display: block;
  padding: 10px 0;
}


.site-card-image {
  cursor: pointer;
  scale: 0.5;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: #e9e9e9;;
  font-weight: bold;

  transition: hover 0.5s ease-in-out;

}


.creator-details {
  display: flex;
  gap: .5rem;
  justify-content: left;
  align-items: center;
  font-size: 14px;
  margin: 0;
  color: #666;
}

.site-view-project {
  cursor: pointer;
  text-decoration: underline;
}

.site-view-project:hover {
  color: #6c6c6c;
}


</style>