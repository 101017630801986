import { computed } from 'vue';
import { useCounterStore } from '@/stores/counter';
import { computeFinalCssBlock } from './styleUtils';


export default function useElement(element) {
  /*
    This composable is used to get information about an element.
    Note: Use useSelectedElement to get information about the currently selected element.
   */
  const store = useCounterStore();

  const pid = computed(() => element.pid);
  const finalStyle = computed(() => computeFinalCssBlock(element, store));
  const select = () => {
    store.setSelectedElementPid(pid.value)

    // Show the navigator when user selects an element.
    if (store.leftSidebar.selectedPane !== 'LeftPanelNavigator') {
      store.setLeftSidebar({'selectedPane': 'LeftPanelNavigator', 'isShowing': true})
    }
  }

  return {
    pid,
    store,
    select,
    finalStyle
  };
}
