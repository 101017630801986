import { Command } from '../stores/undo.js';
import {useCounterStore} from "../stores/counter.js";

export default function useUndoRedo() {
    /*
    We use this composable to provide undo/redo functionality.
    We implement undo/redo with the command pattern.
     */
    const commandInvoker = useCounterStore().commandInvoker

    const doWithUndo = (forward: Function, reverse: Function) => {
        /*
        Add a command to the undo stack and invoke it.
         */
        if (!forward || !reverse) {
            throw new Error("doAction requires both a forward and reverse function.");
        }

        const command: Command = new Command(forward, reverse);
        commandInvoker.do(command);
    };


    const undo = (): void => {
        commandInvoker.undo();
    };

    const redo = (): void => {
        commandInvoker.redo();
    };

    const addToStack = (forward: Function, reverse: Function) => {
        /*
        Add a command to the undo stack without invoking it.
        As an example, we use this to add the ability to undo many previous changes to width
        that the user made in a single drag.
        */
        if (!forward || !reverse) {
            throw new Error("doAction requires both a forward and reverse function.");
        }
        const command: Command = new Command(forward, reverse);
        commandInvoker.addToStack(command);
    }

    return { doWithUndo, undo, redo, addToStack };
}
