<template>
  <div v-if="selectedElement" class="w-full text-[11px] base">
    <div  class="pl-2 py-1">
      Typography
    </div>

    <div class="py-1 px-2 first-layer">

      <div class="flex pb-1">
        <CssLabel class="w-1/3" css-property="font-family" label="Font"/>
        <GenericInputSelect
            class="select w-full input-field"
            :css-value="computedCssValue('font-family', '').value"
            :options="fonts()"
            @update:css-value="setLocalCssProperty('font-family', $event)"
        />
      </div>

      <div class="pb-1 grid grid-cols-4 items-stretch">
        <CssLabel css-property="font-weight" label="Weight"/>
        <GenericInputSelect
            class="select w-full input-field col-span-3"
            :css-value="computedCssValue('font-weight', '400').value"
            :options="weights()"
            @update:css-value="setLocalCssProperty('font-weight', $event)"
        />
      </div>

      <div class="pb-1 grid grid-cols-4">
        <CssLabel css-property="font-size" label="Font size"/>
        <GenericInputNumUnit
            :css-value="computedCssValue('font-size', '10px').value"
            @update:num-object="setLocalCssProperty('font-size', $event.css.val)"
            :exclude-options="['fr']"
        />

        <CssLabel class="pl-2" css-property="line-height" label="Height"/>
        <GenericInputNumUnit
            :css-value="computedCssValue('line-height', '10px').value"
            @update:num-object="setLocalCssProperty('line-height', $event.css.val)"
            :exclude-options="['fr']"
        />
      </div>

      <div class="pb-1 grid grid-cols-4">
        <CssLabel css-property="color" label="Font color"/>
        <GenericInputColor
            class="col-span-3"
            :color="computedCssValue('color', '#00000000').value"
            @update:color="setLocalCssProperty('color', $event)"
            label="Font color"
        />
      </div>


      <div class="flex pb-1 items-center grid grid-cols-4">
        <CssLabel css-property="text-align" label="Align"/>
        <GenericInputRadioGroup class="col-span-3 grid grid-cols-4"
                                name="text-align"
                                :current-value="computedCssValue('text-align', 'left').value"
                                :options="[
                                {value: 'left', icon: 'bi:text-left'},
                                {value: 'center', icon: 'bi:text-center'},
                                {value: 'right', icon: 'bi:text-right'},
                                {value: 'justify', icon: 'clarity:justify-text-line'},
                            ]"
                                @update:value="setLocalCssProperty('text-align', $event)"
        />
      </div>


      <div class="flex pb-1 items-center grid grid-cols-4">
        <CssLabel css-property="font-style" label="Italicise"/>
        <GenericInputRadioGroup
            class="col-span-3 grid grid-cols-2"
            name="font-style"
            :current-value="computedCssValue('font-style', 'normal').value"
            :options="[
                {value: 'normal', icon: 'mdi:letter-i'},
                {value: 'italic', icon: 'uil:italic'},
            ]"
            @update:value="setLocalCssProperty('font-style', $event)"
        />
      </div>

      <div class="flex pb-1 items-center grid grid-cols-4">
        <CssLabel css-property="text-transform" label="Capitalize"/>
        <GenericInputRadioGroup class="col-span-3 grid grid-cols-4"
                                name="text-transform"
                                :current-value="computedCssValue('text-transform', 'none').value"
                                :options="[
                                           {value: 'none', icon: 'basil:cross-outline'},
                                           {value: 'uppercase', icon: 'fluent:text-case-title-16-regular'},
                                           {value: 'lowercase', icon: 'fluent:text-case-lowercase-20-filled'},
                                           {value: 'capitalize', icon: 'radix-icons:letter-case-capitalize'},

                                           ]"
                                @update:value="this.setLocalCssProperty('text-transform', $event)"
        />

      </div>

      <div class="flex pb-1">
        <CssLabel class="w-1/3" css-property="word-break" label="Breaking"/>
        <GenericInputSelect
            class="select w-full input-field"
            :css-value="computedCssValue('word-break', 'normal').value"
            :options="wordBreak()"
            @update:css-value="setLocalCssProperty('word-break', $event)"
        />
      </div>

      <div class="flex pb-1">
        <CssLabel class="w-1/3" css-property="overflow-wrap" label="Wrap"/>
        <GenericInputSelect
            class="select w-full input-field"
            :css-value="computedCssValue('overflow-wrap', 'normal').value"
            :options="overflowWrap()"
            @update:css-value="setLocalCssProperty('overflow-wrap', $event)"
        />
      </div>

      <div class="pb-1 items-center grid grid-cols-4">
        <CssLabel class="w-1/3" css-property="text-decoration" label="Decoration"/>
        <GenericInputRadioGroup
            class="col-span-3 grid grid-cols-4"
            name="text-decoration"
            :current-value="computedCssValue('text-decoration', 'none').value"
            :options="[
                {value: 'none', icon: 'basil:cross-outline'},
                {value: 'underline', icon: 'majesticons:underline-2'},
                {value: 'line-through', icon: 'ooui:strikethrough-a'},
                {value: 'overline', icon: 'mdi:format-overline'},
            ]"
            @update:value="this.setLocalCssProperty('text-decoration', $event)"
        />
      </div>

    </div>
  </div>
</template>

<script>
import {FONT_WEIGHTS, FONTS, OVERFLOW_WRAP, WORD_BREAK} from "@/constants";
import useSelectedElement from "@/composables/useSelectedElement";
import {Icon} from "@iconify/vue";
import ControlSize from "@/components/ControlSize.vue";
import GenericInputColor from "@/components/GenericInputColor.vue";
import CssLabel from "@/components/CssLabel.vue";
import GenericInputNumUnit from "@/components/GenericInputNumUnit.vue";
import GenericInputRadioGroup from "@/components/GenericInputRadioGroup.vue";
import GenericInputSelect from "@/components/GenericInputSelect.vue";

export default {
  name: 'ControlTypography',
  components: {
    GenericInputSelect,
    GenericInputRadioGroup,
    GenericInputNumUnit,
    CssLabel,
    GenericInputColor,
    ControlSize,
    Icon
  },
  setup() {
    const { selectedElement, setLocalCssProperty, computedCssValue  } = useSelectedElement();

    return {
      selectedElement, computedCssValue, setLocalCssProperty
    };
  },
  data() {
    return {
      fonts: () => FONTS,
      weights: () => FONT_WEIGHTS,
      wordBreak: () => WORD_BREAK,
      overflowWrap: () => OVERFLOW_WRAP,
    }
  },

}
</script>

<style scoped>
.input-control {
  margin-bottom: 10px;
}

.base {
  background-color: #333333;
}

.input-field {
  background-color: #333333;
  color: white;
  border-radius: 4px;
}

.first-layer {
  background-color: rgb(94, 89, 89);
  color: white;
}


.no-innerspin::-webkit-inner-spin-button,
.no-innerspin::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.no-innerspin {
  -moz-appearance: textfield;
}



</style>
