import _ from "lodash";
import {nextTick} from "vue";


export function computeFinalCssBlock(element, store) {
  /**
   * Computes the final css for an element. We merge the css blocks in order of precedence.
   */
  const {inheritedCssBlock, componentCssBlock, localCssBlock} = computeConnectedCssBlocks(element, store);

  return {
    ...inheritedCssBlock,
    ...componentCssBlock,
    ...localCssBlock,
  };
}


export function computeConnectedCssBlocks(element, store) {
  /**
   * Finds the css that applies to an element.
   */
  const inheritedCssBlock = _.mapValues(store.getInheritedStyles(element), 'value');
  const componentCssBlock = _.get(store.componentCssStylesheet, [element.component_name], {});
  const localCssBlock = computeLocalCssBlock(element, store);

  return {
    inheritedCssBlock: inheritedCssBlock,
    componentCssBlock: componentCssBlock,
    localCssBlock: localCssBlock
  };
}



export function computeLocalCssBlock(element, store) {
  /**
   * We merge desktop, tablet, and mobile styles. This means that if the user defines css
   * for the desktop breakpoint, it will apply to all breakpoints unless it is overridden
   * by a style defined for a lower breakpoint.
   */
  const breakpoint = store.currentBreakpoint;
  switch (breakpoint) {
    case 'desktop': return element.css_desktop;
    case 'tablet': return { ...element.css_desktop, ...element.css_tablet };
    case 'mobile': return { ...element.css_desktop, ...element.css_tablet, ...element.css_mobile };
    default: return {};
  }
}