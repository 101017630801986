// Undo/redo functionality.
import _ from "lodash";



export class CommandInvoker {
    /*
    We use the command pattern to implement undo/redo functionality.
    The commandInvoker executes commands and keeping track of the undo/redo stack.
     */
    constructor() {
        this.undoStack = [];
        this.redoStack = [];
    }

    do(command) {
        command.do();
        this.addToStack(command);
    }

    undo() {
        const command = this.undoStack.pop();
        if (command) {
            command.undo();
            this.redoStack.push(command);
        }
    }

    redo() {
        const command = this.redoStack.pop();
        if (command) {
            command.do();
            this.undoStack.push(command);
        }
    }

    canUndo(){
        return this.undoStack.length > 0
    }

    canRedo(){
        return this.redoStack.length > 0
    }

    addToStack(command){
        this.undoStack.push(command);
        this.redoStack = []; // Clear the redo stack when we execute a command.
    }
}

class AbstractBaseCommand {
    /*
    Base class for commands.
     */
    do() {
        throw new Error("Method 'do' is not implemented. Every command must implement do.");
    }

    undo() {
        throw new Error("Method 'undo' is not implemented. Every command must implement undo.");
    }
}

export class Command extends AbstractBaseCommand {
    constructor(forwardFn, reverseFn) {
        super();
        this.forwardFn = forwardFn;
        this.reverseFn = reverseFn;
    }

    do() {
        this.forwardFn();
    }

    undo() {
        this.reverseFn();
    }
}



export class SetElementValueCommand extends AbstractBaseCommand {
    constructor(store, pid, keys, value) {
        super();
        this.store = store;
        this.pid = pid;
        this.keys = keys;
        this.value = value;
        this.oldValue = _.get(store.findElementByPid(pid), keys);
    }

    do() {
        const element = this.store.findElementByPid(this.pid);
        if (element) {
            _.set(element, this.keys, this.value);
        }
        else{
            console.error(`Could not find element with pid: ${this.pid}`);
        }
    }

    undo() {
        const element = this.store.findElementByPid(this.pid);
        if (element) {
            _.set(element, this.keys, this.oldValue);
        }
    }
}
